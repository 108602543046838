import * as types from "./types";
import { setUserProfile } from "../user/actions";

export const signIn = (data) => {
  return (dispatch) => {
    try {
      fetch(process.env.REACT_APP_API_URL + "auth/admin/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            dispatch(setAuthErrorMessage(data));
            setTimeout(() => {
              dispatch(setAuthErrorMessage(""));
            }, 2000);
          } else {
            dispatch(setUserInfo(data));
            dispatch(setUserProfile(data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const postLogout = (data, token) => {
  return (dispatch) => {
    try {
      fetch(process.env.REACT_APP_API_URL + "auth/logout", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
          } else {
            localStorage.clear();
          }
        });
    } catch (e) {}
  };
};

export const errorMessage = (error) => {
  return (dispatch) => {
    dispatch(errorMessage(error));
  };
};
export const LogOutExit = (userId) => {
  return (dispatch) => {
    dispatch(logOut(userId));
  };
};
export const numberReload = (value) => {
  return (dispatch) => {
    dispatch(reloadPage(value));
  };
};

export const numberPhone = (numberPhones) => {
  return (dispatch) => {
    try {
      fetch(process.env.REACT_APP_API_URL + "auth/reset-password", {
        method: "POST",
        body: JSON.stringify(numberPhones),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            dispatch(userPhone(data));
          } else {
            dispatch(userPhone(data));
          }
        });
    } catch (e) {}
  };
};

export const codeEnter = (data) => {
  return (dispatch) => {
    try {
      // fetch(`${BASE_URL}auth/reset-password?userId=${userId}`, {
      fetch(
        `${process.env.REACT_APP_API_URL}auth/reset-password`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            dispatch(setResponseVerifyCode(data));
          } else {
            dispatch(setResponseVerifyCode(data));
          }
        });
    } catch (e) {}
  };
};

export const newPasswordRespon = (token, newPass) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}auth/set-new-password/`, {
        method: "PATCH",
        body: JSON.stringify(newPass),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            dispatch(setErrorMessage(data));
            setTimeout(() => {
              dispatch(setErrorMessage(""));
            }, 3000);
          } else {
            dispatch(newPasswordResponse(data));
          }
        });
    } catch (e) {}
  };
};

//--------------Update Profile-----------//

export const updateProfile = (userId, data, token) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/user-profile/${userId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {});
      if (data.httpStatus >= 400) {
      } else {
        // dispatch(setUserInfo(data));
      }
    } catch (e) {}
  };
};

export const resetCodeResponse = () => ({
  type: types.RESET_CODE_RESPONSE,
  payload: "",
});
export const resetPhone = () => ({
  type: types.RESET_PHONE,
  payload: "",
});
export const resetNewPassword = () => ({
  type: types.RESET_NEW_PASSWORD,
  payload: "",
});
export const newPasswordResponse = (newPass) => ({
  type: types.NEW_PASSWORD_RESPONSE,
  payload: newPass,
});

export const reloadPage = (value) => ({
  type: types.NUMBER_PHONE_RELOAD,
  payload: value,
});
export const logOut = (userInfo) => ({
  type: types.LOG_OUT,
  payload: userInfo,
});

export const userPhone = (numberPhone) => ({
  type: types.NUMBER_PHONE,
  payload: numberPhone,
});

export const setUserInfo = (userInfo) => ({
  type: types.SET_USER_INFO,
  payload: userInfo,
});

export const setAuthErrorMessage = (message) => ({
  type: types.SET_AUTH_ERROR_MESSAGE,
  payload: message,
});

export const setResponseVerifyCode = (response) => ({
  type: types.SET_RESPONSE_VERIFY_CODE,
  payload: response,
});
export const setErrorMessage = (error) => ({
  type: types.ERROR_MESSAGE,
  payload: error,
});
