import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import SignIn from "../pages/sign-in";
import ResetPassword from "../pages/reset-password";
import EnterTheCode from "../pages/enter-the-code";
import NewPassword from "../pages/new-password";
import ManageContainer from "../components/manage-container";
import { useSelector } from "react-redux";
import PrivateRoute from "./private-router";
import PrivacyPolicy from "../pages/privacy-Policy";
import TermsOfService from "../pages/Terms-of-service";

function Strip() {
  const { user } = useSelector((state) => state.auth);
  return (
    <Routes>
      <Route
        path="/"
        element={
          user?.accessToken ? (
            <Navigate to="/dashboard" />
          ) : (
            <Navigate to="/sign-in" />
          )
        }
      />
      <Route
        path="/sign-in"
        element={user.accessToken ? <Navigate to="/dashboard" /> : <SignIn />}
      />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/resetPassword/code" element={<EnterTheCode />} />
      <Route path="/resetPassword/newPassword" element={<NewPassword />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/terms_of_service" element={<TermsOfService />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute accessToken={user.accessToken}>
            <ManageContainer />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default Strip;
