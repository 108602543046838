const PrivacyPolicy = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "80%",
        marginLeft: "10%",
        textAlign: "start",
      }}
    >
      <h1> Privacy Policy</h1>
      <p>
        Your privacy is important to us. It is HAV WTR's policy to respect your
        privacy and comply with any applicable law and regulation regarding any
        personal information we may collect about you, including via our app,
        HAV A NICE DAY, and its associated services.
      </p>
      <p>
        Personal information is any information about you which can be used to
        identify you. This includes information about you as a person (such as
        name, address, and date of birth), your devices, payment details, and
        even information about how you use an app or online service.
      </p>
      <p>
        In the event our app contains links to third-party sites and services,
        please be aware that those sites and services have their own privacy
        policies. After following a link to any third-party content, you should
        read their posted privacy policy information about how they collect and
        use personal information. This Privacy Policy does not apply to any of
        your activities after you leave our app.
      </p>
      <p>This policy was last updated and is effective as of June 29, 2022.</p>
      <p>Information We Collect</p>
      <p>
        Information we collect falls into one of two categories: “voluntarily
        provided” information and “automatically collected” information.
      </p>
      <p>
        “Voluntarily provided” information refers to any information you
        knowingly and actively provide us when using our app and its associated
        services.
      </p>
      <p>
        “Automatically collected” information refers to any information
        automatically sent by your device in the course of accessing our app and
        its associated services.
      </p>
      <p>Log Data</p>
      <p>
        When you access our servers via our app, we may automatically log the
        standard data provided by your device. It may include your device's
        Internet Protocol (IP) address, your device type and version, your
        activity within the app, time and date, and other details about your
        usage.
      </p>
      <p>
        Additionally, when you encounter certain errors while using the app, we
        automatically collect data about the error and the circumstances
        surrounding its occurrence. This data may include technical details
        about your device, what you were trying to do when the error happened,
        and other technical information relating to the problem. You may or may
        not receive notice of such errors, even in the moment they occur, that
        they have occurred, or what the nature of the error is. Please be aware
        that while this information may not be personally identifying by itself,
        it may be possible to combine it with other data to personally identify
        individual persons.
      </p>
      <p>Device Data</p>
      <p>
        Our app may access and collect data via your device's in-built tools,
        such as: your identity, location data, camera, microphone,
        accelerometer, body sensor, calendar, contacts, phone/SMS,
        notifications, voice assistance, background data refresh, mobile data,
        device/app history, Bluetooth and other features.
      </p>
      <p>
        When you install the app or use your device’s tools within the app, we
        request permission to access this information. The specific data we
        collect can depend on the individual settings of your device and the
        permissions you grant when you install and use the app.
      </p>
      <p>Personal Information</p>
      <p>
        We may ask for personal information — for example, when you submit
        content to us or when you contact us — which may include one or more of
        the following:
      </p>

      <p>• Name</p>
      <p>• Email</p>
      <p>• Social media profiles</p>
      <p>• Date of birth</p>
      <p>• Phone/mobile number</p>
      <p>• Home/mailing address</p>

      <p>User-Generated Content</p>
      <p>
        We consider “user-generated content” to be materials (text, image and/or
        video content) voluntarily supplied to us by our users for the purpose
        of publication on our platform, website or re-publishing on our social
        media channels. All user- generated content is associated with the
        account or email address used to submit the materials.
      </p>
      <p>
        Please be aware that any content you submit for the purpose of
        publication will be public after posting (and subsequent review or
        vetting process). Once published, it may be accessible to third parties
        not covered under this privacy policy.
      </p>
      <p>Legitimate Reasons for Processing Your Personal Information</p>
      <p>
        We only collect and use your personal information when we have a
        legitimate reason for doing so. In which instance, we only collect
        personal information that is reasonably necessary to provide our
        services to you.
      </p>
      <p>Collection and Use of Information</p>
      <p>
        We may collect personal information from you when you do any of the
        following on our website:
      </p>

      <p> • Register for an account</p>
      <p>• Enter any of our competitions, contests, sweepstakes, and surveys</p>
      <p>
        • Sign up to receive updates from us via email or social media channels
      </p>
      <p>• Use a mobile device or web browser to access our content</p>
      <p>
        • Contact us via email, social media, or on any similar technologies
      </p>
      <p>• When you mention us on social media</p>
      <p>
        We may collect, hold, use, and disclose information for the following
        purposes, and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </p>

      <p>
        • to provide you with our app and platform's core features and services
      </p>
      <p>
        • to enable you to customize or personalize your experience of our
        website
      </p>
      <p>• to deliver products and/or services to you</p>
      <p>• to contact and communicate with you</p>
      <p>
        • for analytics, market research, and business development, including to
        operate and improve our app, associated applications, and associated
        social media platforms
      </p>
      <p>
        • for advertising and marketing, including to send you promotional
        information about our products and services and information about third
        parties that we consider may be of interest to you
      </p>
      <p>
        {" "}
        • to enable you to access and use our app, associated platforms, and
        associated social media channels
      </p>
      <p>• for internal record keeping and administrative purposes</p>
      <p>
        {" "}
        • to run competitions, sweepstakes, and/or offer additional benefits to
        you
      </p>
      <p>
        {" "}
        • to comply with our legal obligations and resolve any disputes that we
        may have
      </p>
      <p>
        • to attribute any content (e.g., posts and comments) you submit that we
        publish on our website
      </p>
      <p>
        {" "}
        • for security and fraud prevention, and to ensure that our sites and
        apps are safe, secure, and used in line with our terms of use
      </p>
      <p>
        {" "}
        • for technical assessment, including to operate and improve our app,
        associated applications, and associated social media platforms
      </p>
      <p>
        We may combine voluntarily provided and automatically collected personal
        information with general information or research data we receive from
        other trusted sources. For example, if you consent to us accessing your
        social media profiles, we may combine information sourced from those
        profiles with information received from you directly to provide you with
        an enhanced experience of our app and services.
      </p>

      <p>Security of Your Personal Information</p>
      <p>
        When we collect and process personal information, and while we retain
        this information, we will protect it within commercially acceptable
        means to prevent loss and theft, as well as unauthorized access,
        disclosure, copying, use, or modification.
      </p>
      <p>
        Although we will do our best to protect the personal information you
        provide to us, we advise that no method of electronic transmission or
        storage is 100% secure, and no one can guarantee absolute data security.
      </p>
      <p>
        You are responsible for selecting any password and its overall security
        strength, ensuring the security of your own information within the
        bounds of our services. For example, ensuring any passwords associated
        with accessing your personal information and accounts are secure and
        confidential.
      </p>
      <p>How Long We Keep Your Personal Information</p>
      <p>
        We keep your personal information only for as long as we need to. This
        time period may depend on what we are using your information for, in
        accordance with this privacy policy. For example, if you have provided
        us with personal information as part of creating an account with us, we
        may retain this information for the duration your account exists on our
        system. If your personal information is no longer required for this
        purpose, we will delete it or make it anonymous by removing all details
        that identify you.
      </p>
      <p>
        However, if necessary, we may retain your personal information for our
        compliance with a legal, accounting, or reporting obligation or for
        archiving purposes in the public interest, scientific, or historical
        research purposes or statistical purposes.
      </p>
      <p>Children’s Privacy</p>
      <p>
        We do not aim any of our products or services directly at children under
        the age of 13, and we do not knowingly collect personal information
        about children under 13.
      </p>
      <p>Disclosure of Personal Information to Third Parties</p>
      <p>We may disclose personal information to:</p>

      <p>• a parent, subsidiary, or affiliate of our company</p>
      <p>
        • third-party service providers for the purpose of enabling them to
        provide their services, including (without limitation) IT service
        providers, data storage, hosting and server providers, ad networks,
        analytics, error loggers, debt collectors, maintenance or
        problem-solving providers, marketing or advertising providers,
        professional
        <p>advisors, and payment systems operators</p>
        <p>• our employees, contractors, and/or related entities</p>
        <p> • our existing or potential agents or business partners</p>
        <p>
          • sponsors or promoters of any competition, sweepstakes, or promotion
          we run
        </p>
        <p>
          {" "}
          • credit reporting agencies, courts, tribunals, and regulatory
          authorities, in the event you fail to pay for goods or services we
          have provided to you
        </p>
        <p>
          • courts, tribunals, regulatory authorities, and law enforcement
          officers, as required by law, in connection with any actual or
          prospective legal proceedings, or in order to establish, exercise, or
          defend our legal rights
        </p>
        <p>
          {" "}
          • third parties, including agents or sub-contractors, who assist us in
          providing information, products, services, or direct marketing to you
        </p>
        <p>• third parties to collect and process data</p>
        <p>
          • an entity that buys, or to which we transfer all or substantially
          all of our assets and business
        </p>
        <p> International Transfers of Personal Information</p>
        <p>
          The personal information we collect is stored and/or processed in
          United States, or where we or our partners, affiliates, and
          third-party providers maintain facilities.
        </p>
        <p>
          The countries to which we store, process, or transfer your personal
          information may not have the same data protection laws as the country
          in which you initially provided the information. If we transfer your
          personal information to third parties in other countries: (i) we will
          perform those transfers in accordance with the requirements of
          applicable law; and (ii) we will protect the transferred personal
          information in accordance with this privacy policy.
        </p>
        <p>Your Rights and Controlling Your Personal Information</p>
        <p>
          {" "}
          Your choice: By providing personal information to us, you understand
          we will collect, hold, use, and disclose your personal information in
          accordance with this privacy policy. You do not have to provide
          personal information to us, however, if you do not, it may affect your
          use of our app or the products and/or services offered on or through
          it.
        </p>
        <p>
          {" "}
          Information from third parties: If we receive personal information
          about you from a third party, we will protect it as set out in this
          privacy policy. If you are a third party providing personal
          information about somebody else, you represent and warrant that you
          have such person’s consent to provide the personal information to us.
        </p>
        <p>
          Marketing permission: If you have previously agreed to us using your
          personal information for direct marketing purposes, you may change
          your mind at any time by contacting us using the details below.
        </p>
        <p>
          {" "}
          Access: You may request details of the personal information that we
          hold about you.
        </p>
        <p>
          {" "}
          Correction: If you believe that any information we hold about you is
          inaccurate, out of date, incomplete, irrelevant, or misleading, please
          contact us using the details provided in this privacy policy. We will
          take reasonable steps to correct any information found to be
          inaccurate, incomplete, misleading, or out of date.
        </p>
        <p>
          Non-discrimination: We will not discriminate against you for
          exercising any of your rights over your personal information. Unless
          your personal information is required to provide you with a particular
          service or offer (for example serving particular content to your
          device), we will not deny you goods or services and/or charge you
          different prices or rates for goods or services, including through
          granting discounts or other benefits, or imposing penalties, or
          provide you with a different level or quality of goods or services.
        </p>
        <p>
          Downloading of Personal Information: We provide a means for you to
          download the personal information you have shared through our app.
          Please contact us for more information.
        </p>
        <p>
          {" "}
          Notification of data breaches: We will comply with laws applicable to
          us in respect of any data breach.
        </p>
        <p>
          {" "}
          Complaints: If you believe that we have breached a relevant data
          protection law and wish to make a complaint, please contact us using
          the details below and provide us with full details of the alleged
          breach. We will promptly investigate your complaint and respond to
          you, in writing, setting out the outcome of our investigation and the
          steps we will take to deal with your complaint. You also have the
          right to contact a regulatory body or data protection authority in
          relation to your complaint.
        </p>
        <p>
          {" "}
          Unsubscribe: To unsubscribe from our email database or opt-out of
          communications (including marketing communications), please contact us
          using the details provided in this privacy policy, or opt-out using
          the opt-out facilities provided in the communication. We may need to
          request specific information from you to help us confirm your
          identity.
        </p>
        <p> Use of Cookies</p>
        <p>
          {" "}
          Our privacy policy covers the use of cookies between your device and
          our servers. A cookie is a small piece of data that an app may store
          on your device, typically containing a unique identifier that allows
          the app servers to recognize your device when you use the app;
          information about your account, session and/or device; additional data
          that serves the purpose of the cookie, and any self-maintenance
          information about the cookie itself.
        </p>
        <p>
          {" "}
          We use cookies to give your device access to core features of our app,
          to track app usage and performance on your device, to tailor your
          experience of our app based on your preferences, and to serve
          advertising to your device. Any communication of cookie data between
          your device and our servers occurs within a secure environment.
        </p>
        <p> Please refer to our Cookie Policy for more information.</p>
        <p> Business Transfers</p>
        <p>
          If we or our assets are acquired, or in the unlikely event that we go
          out of business or enter bankruptcy, we would include data, including
          your personal information, among the assets transferred to any parties
          who acquire us. You acknowledge that such transfers may occur, and
          that any parties who acquire us may, to the extent permitted by
          applicable law, continue to use your personal information according to
          this policy, which they will be required to assume as it is the basis
          for any ownership or use rights we have over such information.
        </p>
        <p>Limits of Our Policy</p>
        <p>
          Our app may link to external sites that are not operated by us. Please
          be aware that we have no control over the content and policies of
          those sites, and cannot accept responsibility or liability for their
          respective privacy practices.
        </p>
        <p> Changes to This Policy</p>
        <p>
          {" "}
          At our discretion, we may change our privacy policy to reflect updates
          to our business processes, current acceptable practices, or
          legislative or regulatory changes. If we decide to change this privacy
          policy, we will post the changes here and on our website.
        </p>
        <p>
          If the changes are significant, or if required by applicable law, we
          will contact you (based on your selected preferences for
          communications from us) and all our registered users with the new
          details and links to the updated or changed policy.
        </p>
        <p>
          If required by law, we will get your permission or give you the
          opportunity to opt in to or opt out of, as applicable, any new uses of
          your personal information.
        </p>
        <p>
          Additional Disclosures for General Data Protection Regulation (GDPR)
          Compliance (EU)
        </p>
        <p>Data Controller / Data Processor</p>
        <p>
          The GDPR distinguishes between organizations that process personal
          information for their own purposes (known as “data controllers”) and
          organizations that process personal information on behalf of other
          organizations (known as “data processors”). We, HAV WTR, located at
          the address provided in our Contact Us section, are a Data Controller
          with respect to the personal information you provide to us.
        </p>
        <p>Legal Bases for Processing Your Personal Information</p>
        <p>
          {" "}
          We will only collect and use your personal information when we have a
          legal right to do so. In which case, we will collect and use your
          personal information lawfully, fairly, and in a transparent manner. If
          we seek your consent to process your personal information, and you are
          under 16 years of age, we will seek your parent or legal guardian’s
          consent to process your personal information for that specific
          purpose.
        </p>
        <p>
          Our lawful bases depend on the services you use and how you use them.
          This means we only collect and use your information on the following
          grounds:
        </p>
        <p> Consent From You</p>
        <p>
          {" "}
          Where you give us consent to collect and use your personal information
          for a specific purpose. You may withdraw your consent at any time
          using the facilities we provide; however this will not affect any use
          of your information that has already taken place. You may consent to
          providing your name and contact details for the purpose of entering a
          giveaway or promotion. While you may withdraw your entry at any time,
          this will not affect any selection or judging that has already taken
          place. If you have any further enquiries about how to withdraw your
          consent, please feel free to enquire using the details provided in the
          Contact Us section of this privacy policy.
        </p>
        <p>Performance of a Contract or Transaction</p>
        <p>
          Where you have entered into a contract or transaction with us, or in
          order to take preparatory steps prior to our entering into a contract
          or transaction with you. For example, we need technical information
          about your device in order to provide the essential features of our
          app.
        </p>
        <p> Our Legitimate Interests</p>
        <p>
          Where we assess it is necessary for our legitimate interests, such as
          for us to provide, operate, improve and communicate our services. For
          example, we collect technical information about your device in order
          to improve and personalize your experience of our app. We consider our
          legitimate interests to include research and development,
          understanding our audience, marketing and promoting our services,
          measures taken to operate our services efficiently, marketing
          analysis, and measures taken to protect our legal rights and
          interests.
        </p>
        <p>Compliance with Law</p>
        <p>
          In some cases, we may have a legal obligation to use or keep your
          personal information. Such cases may include (but are not limited to)
          court orders, criminal investigations, government requests, and
          regulatory obligations. If you have any further enquiries about how we
          retain personal information in order to comply with the law, please
          feel free to enquire using the details provided in the Contact Us
          section of this privacy policy.
        </p>
        <p>
          International Transfers Outside of the European Economic Area (EEA)
        </p>
        <p>
          We will ensure that any transfer of personal information from
          countries in the European Economic Area (EEA) to countries outside the
          EEA will be protected by appropriate safeguards, for example by using
          standard data protection clauses approved by the European Commission,
          or the use of binding corporate rules or other legally accepted means.
        </p>
        <p> Your Rights and Controlling Your Personal Information</p>
        <p>
          Restrict: You have the right to request that we restrict the
          processing of your personal information if (i) you are concerned about
          the accuracy of your personal information; (ii) you believe your
          personal information has been unlawfully processed; (iii) you need us
          to maintain the personal information solely for the purpose of a legal
          claim; or (iv) we are in the process of considering your objection in
          relation to processing on the basis of legitimate interests.
        </p>
        <p>
          {" "}
          Objecting to processing: You have the right to object to processing of
          your personal information that is based on our legitimate interests or
          public interest. If this is done, we must provide compelling
          legitimate grounds for the processing which overrides your interests,
          rights, and freedoms, in order to proceed with the processing of your
          personal
        </p>
        information.
        <p>
          Data portability: You may have the right to request a copy of the
          personal information we hold about you. Where possible, we will
          provide this information in CSV format or other easily readable
          machine format. You may also have the right to request that we
          transfer this personal information to a third party.
        </p>
        <p>
          Deletion: You may have a right to request that we delete the personal
          information we hold about you at any time, and we will take reasonable
          steps to delete your personal information from our current records. If
          you ask us to delete your personal information, we will let you know
          how the deletion affects your use of our app, website or products and
          services. There may be exceptions to this right for specific legal
          reasons which, if applicable, we will set out for you in response to
          your request. Please be aware that search engines and similar third
          parties may still retain copies of your personal information that has
          been made public at least once, like certain profile information and
          public comments, even after you have deleted the information from our
          services or deactivated your account.
        </p>
        <p>Additional Disclosures for California Compliance (US)</p>
        <p>
          Under California Civil Code Section 1798.83, if you live in California
          and your business relationship with us is mainly for personal, family,
          or household purposes, you may ask us about the information we release
          to other organizations for their marketing purposes.
        </p>
        <p>
          To make such a request, please contact us using the details provided
          in this privacy policy with “Request for California privacy
          information” in the subject line. You may make this type of request
          once every calendar year. We will email you a list of categories of
          personal information we revealed to other organizations for their
          marketing purposes in the last calendar year, along with their names
          and addresses. Not all personal information shared in this way is
          covered by Section 1798.83 of the California Civil Code.
        </p>
        <p>Do Not Track</p>
        <p>
          {" "}
          Some browsers have a “Do Not Track” feature that lets you tell
          websites that you do not want to have your online activities tracked.
          At this time, we do not respond to browser “Do Not Track” signals.
        </p>
        <p>
          {" "}
          We adhere to the standards outlined in this privacy policy, ensuring
          we collect and process personal information lawfully, fairly,
          transparently, and with legitimate, legal reasons for doing so.
        </p>
        <p>Cookies and Pixels</p>
        <p>
          At all times, you may decline cookies from our site if your browser
          permits. Most browsers allow you to activate settings on your browser
          to refuse the setting of all or some cookies. Accordingly, your
          ability to limit cookies is based only on your browser’s capabilities.
          Please refer to the Cookies section of this privacy policy for more
          information.
        </p>
        <p>CCPA-permitted financial incentives</p>
        <p>
          In accordance with your right to non-discrimination, we may offer you
          certain financial incentives permitted by the CCPA that can result in
          different prices, rates, or quality levels for the goods or services
          we provide.
        </p>
        <p>
          {" "}
          Any CCPA-permitted financial incentive we offer will reasonably relate
          to the value of your personal information, and we will provide written
          terms that describe clearly the nature of such an offer. Participation
          in a financial incentive program requires your prior opt-in consent,
          which you may revoke at any time.
        </p>
        <p>California Notice of Collection</p>
        <p>
          In the past 12 months, we have collected the following categories of
          personal information enumerated in the California Consumer Privacy
          Act:
        </p>
        <p>
          • Identifiers, such as name, email address, phone number account name,
          IP address, and an ID or number assigned to your account.
        </p>
        <p>
          {" "}
          • Customer records, such as billing and shipping address, and credit
          or debit card data.
        </p>
        <p>
          • Commercial information, such as products or services history and
          purchases.
        </p>
        <p>• Internet activity, such as your interactions with our service.</p>
        <p> • Geolocation data.</p>
        <p>
          • Inferences, such as information about your interests, preferences
          and favorites.
        </p>
        <p>
          For more information on information we collect, including the sources
          we receive information from, review the “Information We Collect”
          section. We collect and use these categories of personal information
          for the business purposes described in the “Collection and Use of
          Information” section, including to provide and manage our Service.
        </p>
        <p> Right to Know and Delete</p>
        <p>
          If you are a California resident, you have rights to delete your
          personal information we collected and know certain information about
          our data practices in the preceding 12 months. In particular, you have
          the right to request the following from us:
        </p>
        <p>
          • The categories of personal information we have collected about you;
        </p>
        <p>
          • The categories of sources from which the personal information was
          collected;
          <p>
            {" "}
            • The categories of personal information about you we disclosed for
            a business purpose or sold;
          </p>
          <p>
            • The categories of third parties to whom the personal information
            was disclosed for a business purpose or sold;
          </p>
          <p>
            • The business or commercial purpose for collecting or selling the
            personal information; and
          </p>
          <p>
            • The specific pieces of personal information we have collected
            about you.
          </p>
          <p>
            To exercise any of these rights, please contact us using the details
            provided in this privacy policy.
          </p>
          Shine the Light
        </p>
        <p>
          If you are a California resident, in addition to the rights discussed
          above, you have the right to request information from us regarding the
          manner in which we share certain personal information as defined by
          California’s “Shine the Light” with third parties and affiliates for
          their own direct marketing purposes.
        </p>
        <p>
          {" "}
          To receive this information, send us a request using the contact
          details provided in this privacy policy. Requests must include
          “California Privacy Rights Request” in the first line of the
          description and include your name, street address, city, state, and
          ZIP code.
        </p>
        <p> Contact Us</p>
        For any questions or concerns regarding your privacy, you may contact us
        using the following details:
      </p>

      <p>Privacy Department</p>
      <p>privacy@havwtr.com</p>
    </div>
  );
};
export default PrivacyPolicy;
