import { useEffect, useState } from "react";
import { newCategories } from "../../redux/products/action";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { schema } from "./validation/validation.constants.js";

import { Box, Modal } from "@mui/material";
import Alert from "@mui/material/Alert";

import Title from "../../components/title";
import Form from "../../components/form";

import "./styles.scss";

import {
  CustomArrowBackIcon,
  CustomTextField,
  SubmitButton,
} from "../../shared/styles.styled";

const AddNewCategories = ({
  setPageAddCategories,
  addCategoriesComplete,
  setDefaultPage,
}) => {
  const dispatch = useDispatch();

  const categoriesResponse = useSelector(
    (state) => state.product.responseCategories
  );
  const User = useSelector((state) => state.profile.userProfiles);
  const isFetchError = useSelector((state) => state.product.isFetchError);
  const isFetchSuccess = useSelector((state) => state.product.isFetchSuccess);
  const [successfully, setSuccessfully] = useState(false);

  const [newTab, setNewTab] = useState(false);
  useEffect(() => {
    setDefaultPage(1);
  }, [newTab]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  const [openModalWindow, setOpenModalWindow] = useState(false);
  const handleCloseModalWindow = () => {
    setPageAddCategories(true);
  };

  const onSubmit = async (value) => {
    let check = categoriesResponse.category.find(
      (item) => item.name === value.nameCategories
    );
    if (check === undefined) {
      setNewTab(true);
      const data = {
        categoryName: value.nameCategories,
      };
      dispatch(newCategories(User.accessToken, User.userId, data));
      setSuccessfully(true);
      addCategoriesComplete();
    } else {
      setSuccessfully(false);
    }
    reset();
  };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      nameCategories: "",
    },
  });
  const isTextFieldEmpty = watch("nameCategories") === "";

  return (
    <>
      {isFetchError && !successfully && (
        <Alert severity="error">
          Oops...Something went wrong! Reload the page.
        </Alert>
      )}
      {isFetchSuccess && successfully && (
        <Alert severity="success">
          The product has been successfully added.
        </Alert>
      )}

      <Modal open={openModalWindow}>
        <Box sx={style}>
          <div>
            <p
              className="exitModalAdd"
              onClick={() => setOpenModalWindow(false)}
            >
              x
            </p>
            <p
              style={{
                fontFamily: "Open Sans",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              Unsaved progress will be lost
            </p>
            <p style={{ fontFamily: "Open Sans", textAlign: "center" }}>
              Leave the page?
            </p>
            <div
              style={{
                fontFamily: "Open Sans",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                fontWeight: 700,
              }}
            >
              <p
                onClick={() => setOpenModalWindow(false)}
                style={{ cursor: "pointer" }}
              >
                Stay
              </p>
              <p
                onClick={() => handleCloseModalWindow()}
                style={{ color: "red", cursor: "pointer" }}
              >
                Leave
              </p>
            </div>
          </div>
        </Box>
      </Modal>

      <div className="headerNewCategories">
        <Form
          sx={{ width: "100%", minWidth: "30vw" }}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
        >
          <div>
            <CustomArrowBackIcon onClick={() => setOpenModalWindow(true)} />
            <Title title="Add new category" sx={{ marginBottom: "51px" }} />
          </div>
          <Box component="div" className="areaChange">
            <Controller
              render={({ field }) => (
                <CustomTextField
                  autoFocus
                  helperText={
                    errors.nameCategories
                      ? `${errors.nameCategories.message}`
                      : ""
                  }
                  autoComplete="off"
                  variant="standard"
                  value={""}
                  errors={!!errors.nameCategories}
                  label={"Category name"}
                  {...field}
                />
              )}
              name="nameCategories"
              control={control}
            />
            <SubmitButton
              disabled={isTextFieldEmpty || !!errors.nameCategories}
              data-testid="submit-btn"
              variant="contained"
              color="primary"
              type="submit"
            >
              Add new category
            </SubmitButton>
          </Box>
        </Form>
      </div>
    </>
  );
};
export default AddNewCategories;
