import React from "react";
import Status from "../../status";
import Detailed from "../../icon/down-icon";
import DetailedArea from "../../detailed-area";
import ReactPaginate from "react-paginate";
import BackPagePagination from "../../../assets/backPagination.png";
import NextPaginationPage from "../../../assets/nextPage.png";
import { OrderStatusEnum } from "../../../shared/enums/order.enum.ts";

const { COMPLETED, CANCELLED, NO_SHOW } = OrderStatusEnum;

const TablePrevious = ({
  amountPrevious,
  titleTable,
  previousOrder,
  showDetailed,
  openDetailed,
  currentPagePrevious,
  pageChangePrevious,
  pageActive,
  pagePrevious,
}) => {
  return (
    <>
      <label className="titleTableActive">
        Previous orders
        <label className="amountStyle">
          ({amountPrevious === null ? "0" : amountPrevious})
        </label>
      </label>
      <table className="tableBlockPrevious">
        <thead>
          <tr>
            {titleTable.map((item, index) => (
              <th key={index} className="orderTitle">
                {item.title}
              </th>
            ))}
          </tr>
        </thead>

        {previousOrder.orders?.map((order) => {
          if ([COMPLETED, CANCELLED, NO_SHOW].includes(order.status)) {
            return (
              <tbody key={order.id}>
                <tr className="infoTableOrder">
                  <td>{order.id}</td>
                  <td>${order.cost}</td>
                  <td>
                    {order.user.fullName} {order.user.phone}
                  </td>
                  <td>
                    <Status
                      customerLocation={order.customer_location}
                    />
                  </td>
                  <td>{order.placed}</td>
                  <td>{order.completed_at}</td>
                  <td>
                    <Status
                      status={order.status}
                      idOrder={order.id}
                      pageActive={pageActive}
                      pagePrevious={pagePrevious}
                    />
                  </td>
                  <td>
                    <Detailed
                      id={order.id}
                      showDetailed={showDetailed[order.id]}
                      openDetailed={openDetailed}
                    />
                  </td>
                </tr>
                <td colSpan={8}>
                  {order.products.map((product, index) => (
                      <DetailedArea
                        key={index}
                        name={product.name}
                        amount={product.amount}
                        packages={product.packing_size}
                        price={product.price}
                        id={order.id}
                        showDetailed={showDetailed[order.id]}
                      />
                  ))}
                </td>
              </tbody>
            );
          }
        })}
      </table>
      <div className="pagination">
        <ReactPaginate
          previousLabel={
            <img src={BackPagePagination} style={{ width: "10px" }} alt="" />
          }
          nextLabel={
            <img src={NextPaginationPage} style={{ width: "10px" }} alt="" />
          }
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          initialPage={0}
          pageCount={currentPagePrevious}
          onPageChange={pageChangePrevious}
          containerClassName={"pagination"}
          pageClassName={"pagination__btn"}
          pageLinkClassName={"pagination__content"}
          previousClassName={"pagination__btn"}
          previousLinkClassName={"pagination__content"}
          nextClassName={"pagination__btn"}
          nextLinkClassName={"pagination__content"}
          breakClassName={"pagination__btn"}
          breakLinkClassName={"pagination__content"}
          activeClassName={"pagination__active"}
          disabledClassName={"pagination__disabled"}
        />
      </div>
    </>
  );
};
export default TablePrevious;
