import React, { useEffect, useState } from "react";
import { AuthContainer } from "../../components/auth-container";
import "./styles.scss";
import Index from "../../components/auth-title";
import InputPassword from "../../components/componentTools/inputPassword";
import LinkButton from "../../components/button-link";
import TextField from "../../components/text-field";
import Button from "../../components/new-button";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../redux/auth/actions";
import ModalWindow from "../../components/modalWindow/modalWindow";
import { Box, Modal } from "@mui/material";
import { isEmail } from "class-validator";

const SignIn = () => {
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [activeButton, setActiveButton] = useState(true);
  const [visible, setVisible] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.authError);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const result = isEmail(emailValue);
    if (result === true) {
      setEmailValid(true);
      setErrorEmail(false);
    } else {
      setEmailValid(false);
      setErrorEmail(true);
    }
  }, [emailValue]);
  useEffect(() => {
    const patternPass = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    const results = patternPass.test(passwordValue);
    if (results === true) {
      setPasswordValid(true);
      setErrorPassword(false);
    } else if (results === false) {
      setPasswordValid(false);
      setErrorPassword(true);
    }
  }, [passwordValue]);

  useEffect(() => {
    setErrorPassword(false);
    setErrorEmail(false);
  }, []);

  const changeEmail = (e) => {
    setEmailValue(e.target.value);
  };
  const changePassword = (e) => {
    setPasswordValue(e.target.value);
  };

  useEffect(() => {
    if (emailValid === true && passwordValid === true) {
      setActiveButton(false);
    } else {
      setActiveButton(true);
    }
  }, [emailValid, passwordValid]);

  const login = () => {
    setVisible(true);
    dispatch(
      signIn({
        email: emailValue,
        password: passwordValue,
      })
    );
  };

  useEffect(() => {
    if (error.errorMessage) {
      setVisible(false);
    }
    if (user) {
      setVisible(false);
    }
  }, [error]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <AuthContainer>
      <Index title={"Log in"} />
      <Modal open={visible}>
        <Box sx={style}>
          <ModalWindow visible={visible} title=" " loadText="Redirecting..." />
        </Box>
      </Modal>
      <TextField
        value={emailValue}
        changeValue={changeEmail}
        label="Email address"
        styleInput={"text-field"}
        labelStyle={"labelLogin"}
        blockStyle={"defaultDiv"}
        disabled={true}
      />
      {errorEmail ? (
        <p style={{ color: "red", fontSize: "12px" }}>Invalid email</p>
      ) : null}
      <InputPassword
        label="Password"
        passwordValue={passwordValue}
        changePassword={changePassword}
      />
      {errorPassword ? (
        <p style={{ color: "red", fontSize: "12px" }}>Invalid password</p>
      ) : null}
      {error.errorMessage ? (
        <p style={{ fontSize: "16px", color: "red" }}>{error.errorMessage}</p>
      ) : (
        ""
      )}
      <LinkButton />
      <Button
        value={"Log In"}
        type={"activeLogin"}
        typeAction={"buttonLogIn"}
        typeNoActive={"activeButtonLogIn"}
        disabled={activeButton}
        click={login}
      />
    </AuthContainer>
  );
};

export default SignIn;
