import Table from "../../components/table";
import AddNewProduct from "../add_new_product/index";
import { useState } from "react";
import AddNewCategories from "../add-new-categories";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./styles.scss";
import ModalDiscard from "../../components/modalWindow/modal-discard";
import { postLogout } from "../../redux/auth/actions";
import {} from "../../redux/user/actions";
import IconTabProduct from "../../assets/ActiveOrdersIcon.png";
import IconTabCategories from "../../assets/package 2.png";
import DeleteIcon from "../../assets/deleteIcon.png";
import CheckQuantity from "../../components/chek-quantity";
import React from "react";
const Products = () => {
  // const [indexActive, setIndexActive] = useState(true);
  const [stateQuantity, setStateQuantity] = useState(false);
  const [pageAddProduct, setPageAddProduct] = useState(false);
  const [pageAddCategories, setPageAddCategories] = useState(true);
  const productArr = useSelector((state) => state.product.responseProduct);
  const productArrDeleted = useSelector(
    (state) => state.product.responseDeprecated
  );

  const categories = useSelector((state) => state.product.responseCategories);
  const [tabIndex, setTabIndex] = useState(0);
  const [defaultPage, setDefaultPage] = useState(0);

  const titleOrdersActive = [
    { title: "Select" },
    { title: "Cover" },
    { title: "Product" },
    { title: "Category" },
    { title: "Number" },
    { title: "Package size" },
    { title: "Package type" },
    { title: "Cost" },
    { title: "Available" },
    { title: "Quantity" },
    { title: "Rating" },
  ];

  const addProduct = () => {
    setPageAddProduct(true);
  };

  const addCategories = () => {
    setPageAddCategories(false);
    // setIndexActive(false);
  };
  const addCategoriesComplete = () => {
    setTabIndex(1);
    setTimeout(() => {
      setPageAddCategories(true);
    }, 1000);
  };
  const addProductComplete = () => {
    setTimeout(() => {
      setPageAddProduct(false);
      // setIndexActive(false);
    }, 1000);
  };

  const dispatch = useDispatch();
  const idUser = useSelector((state) => state.auth.user);

  const [visible, setVisible] = useState(false);

  const logOut = () => {
    setVisible(true);
  };
  const stay = () => {
    setVisible(false);
  };
  const logOutAction = () => {
    // dispatch(LogOutExit(idUser.userId));
    // dispatch(logoutsUser());
    // dispatch(clearProducts());
    dispatch(postLogout(idUser.userId, idUser.accessToken));

    localStorage.clear();
    window.location.reload();
  };
  const tab = [
    {
      title: "Products",
      content: (
        <Table
          addProduct={addProduct}
          titles={"Products"}
          titleTable={titleOrdersActive}
          setStateQuantity={setStateQuantity}
          stateQuantity={stateQuantity}
        />
      ),
      amount: productArr.length,
      icon: IconTabProduct,
    },
    {
      title: "Categories",
      content: <Table titles={"Categories"} addCategories={addCategories} />,
      amount: categories.category.length,
      icon: IconTabCategories,
    },
    {
      title: "Archived Products",
      content: (
        <Table
          titles={"Archived Products"}
          titleTable={titleOrdersActive}
          setStateQuantity={setStateQuantity}
          stateQuantity={stateQuantity}
        />
      ),
      amount: productArrDeleted.length,
      icon: DeleteIcon,
    },
  ];
  return (
    <>
      {visible ? (
        <ModalDiscard
          discard={logOutAction}
          stay={stay}
          title="Confirm the action"
          question="Are you sure you want to log out?"
          answerOne="Stay"
          answerTwo="Log out"
          styleVisible="modalVisibleLogout"
        />
      ) : (
        ""
      )}
      {/*{!pageAddCategories||pageAddProduct?null:*/}

      {/*    <input style={{position:"absolute",right:'34%',top:'12%'}} type="button" className="logOut" onClick={() => logOut()} />*/}
      {/*}*/}

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        {pageAddCategories ? (
          !pageAddProduct ? (
            <div>
              <div style={{ display: "flex", alignItems: "end" }}>
                <TabList
                  className="headerProductsLink"
                  style={{ position: "relative" }}
                >
                  {tab.map((nameTab) => (
                    <React.Fragment key={nameTab.title}>
                      <Tab
                        className="tabLinkProductsCategories"
                        selectedClassName="tabLinkProductsCategoriesFocus"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingRight: "25px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "start",
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                            }}
                          >
                            {nameTab.title}
                            <label>{nameTab.amount}</label>
                          </div>
                          <img src={nameTab.icon} alt="" />
                        </div>
                      </Tab>
                    </React.Fragment>
                  ))}
                </TabList>
                <div style={{ marginBottom: "10px" }}>
                  {!pageAddCategories || pageAddProduct ? null : (
                    <input
                      type="button"
                      className="logOut"
                      onClick={() => logOut()}
                    />
                  )}
                </div>
                {tabIndex === 0 && stateQuantity ? <CheckQuantity /> : null}
              </div>

              <div>
                {tab.map((item, index) => (
                  <TabPanel
                    className="contentProducts"
                    key={item.content + index}
                  >
                    {item.content}
                  </TabPanel>
                ))}
              </div>
            </div>
          ) : (
            <AddNewProduct
              addProductComplete={addProductComplete}
              setPageAddProduct={setPageAddProduct}
            />
          )
        ) : (
          <AddNewCategories
            setPageAddCategories={setPageAddCategories}
            addCategoriesComplete={addCategoriesComplete}
            setDefaultPage={setDefaultPage}
          />
        )}
      </Tabs>
    </>
  );
};
export default Products;
