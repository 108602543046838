import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNameCategories,
  deleteCategories,
  getCategories,
} from "../../redux/products/action";

import InputCategories from "../input-categories";
import Title from "../../components/title";

import { SubMenuButton, ActionButton } from "../../shared/styles.styled";
import { Box, Modal, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";

import "./styles.scss";

const Categories = ({ titles, addCategories }) => {
  const titleTable = [
    { title: "Select", id: 1 },
    { title: "Category name", id: 2 },
    { title: "Products in category", id: 3 },
  ];
  const titleTableDisabledRole = [
    { title: "Category name", id: 1 },
    { title: "Products in category", id: 2 },
  ];

  const dispatch = useDispatch();

  const User = useSelector((state) => state.profile.userProfiles);
  const categoriesResponse = useSelector(
    (state) => state.product.responseCategories
  );

  const [editCategoriesLost, setEditCategoriesLost] = useState(false);
  const [editCategories, setEditCategories] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState([]);
  const [checked, setChecked] = useState([false]);
  const [testCheck, setTestCheck] = useState(false);
  const [nameCategories, setNameCategories] = useState("");
  const [amountProduct, setAmountProduct] = useState();
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [deleteItem, setDeleteCategories] = useState();
  const [changeCategoriesId, setChangeCategoriesId] = useState([]);
  const [changeCategoriesName, setChangeCategoriesName] = useState([]);
  const [testValueCategiry, setTestValueCategory] = useState();
  const [errorDeleteCategory, setErrorDeleteCategory] = useState();
  const [errorDeleteCategoryOne, setErrorDeleteCategoryOne] = useState();
  const [closeModal, setCloseModal] = useState(false);
  const [disabledError, setDisabledError] = useState(false);
  const [errorNewCategory, setErrorNewCategory] = useState(false);

  const handleOpen = () => {
    let newNameCategorY = changeCategoriesName.join(", ");
    setTestValueCategory(newNameCategorY);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  const handleOpenInfo = (i) => {
    const newModalOpen = openInfoModal.slice();
    newModalOpen[i] = !openInfoModal[i];
    setOpenInfoModal(...[newModalOpen]);
  };
  const handleCloseInfo = (categoryName) => {
    if (closeModal === true) {
      setEditCategoriesLost(true);
    } else {
      setOpenInfoModal([]);
      setEditCategories(false);
    }
  };

  const discardSelection = () => {
    setTestCheck(true);
    setChangeCategoriesId([]);
    setTimeout(() => {
      setTestCheck(false);
      setChangeCategoriesName([]);
      dispatch(getCategories(User.accessToken, User.userId));
    }, 500);
  };

  const changeCategories = (event, id, name, amountProduct) => {
    if (event.target.checked === true) {
      setChangeCategoriesId([...changeCategoriesId, id]);
      setChangeCategoriesName([...changeCategoriesName, name]);
    }
    if (event.target.checked === false) {
      let newArrayIds = changeCategoriesId.filter((item) => item !== id);
      let newArrayNames = changeCategoriesName.filter((item) => item !== name);
      setChangeCategoriesId(newArrayIds);
      setChangeCategoriesName(newArrayNames);
    }
  };

  const [errorDelete, setErrorDelete] = useState(false);

  const deleteCategor = () => {
    let error = { name: "all" };
    const data = {
      categoriesIds: changeCategoriesId,
    };
    if (amountProduct >= 1) {
      setErrorDelete(true);
      setTimeout(() => {
        setErrorDelete(false);
      }, 2000);
    } else {
      dispatch(
        deleteCategories(
          User.accessToken,
          User.userId,
          data,
          setErrorDeleteCategory,
          setErrorDeleteCategoryOne,
          error,
        )
      );

      dispatch(getCategories(User.accessToken, User.userId));
      handleClose();
      setChangeCategoriesId([]);
      setChangeCategoriesName([]);
    }
    if (refreshDelete === false) {
      setRefreshDelete(true);
    } else setRefreshDelete(false);
  };

  const newNameCategories = (newName, id) => {
    let check = categoriesResponse.category.find(
      (item) => item.name === newName
    );
    let regex = /^[a-zA-Z][^0-9]{0,28}$/;
    const result = regex.test(newName);
    if (check === undefined && result) {
      let data = {
        categoryName: newName,
      };
      dispatch(changeNameCategories(User.accessToken, User.userId, id, data));
      setEditCategoriesLost(false);
      setOpenInfoModal([]);
      setEditCategories(false);
      setTimeout(() => {
        dispatch(getCategories(User.accessToken, User.userId));
      }, 500);
    } else {
      setErrorNewCategory(true);
      setTimeout(() => {
        setErrorNewCategory(false);
      }, 4000);
    }
  };

  const [openModalQuestion, setOpenModalQuestion] = useState(false);

  const deleteCategory = () => {
    dispatch(deleteCategories(User.accessToken, User.userId, deleteItem, setDeleteCategories));
    dispatch(getCategories(User.accessToken, User.userId));
    setNameCategories("");
    setOpenModalQuestion(false);
    setDeleteCategories();
    setTimeout(() => {
      dispatch(getCategories(User.accessToken, User.userId));
    }, 100);
  };
  const [openModalQuestionDeleteOneItem, setOpenModalQuestionDeleteOneItem] =
    useState(false);
  const [nameCategoriesOne, setNameCategoriesOne] = useState("");
  const [deleteCategoriesOne, setDeleteCategoriesOne] = useState("");
  const handleOpenModalDeleteOneItem = (name, id) => {
    setOpenModalQuestionDeleteOneItem(true);
    setNameCategoriesOne(name);
    setDeleteCategoriesOne(id);
  };

  const deleteOneCategories = (itemName, id) => {
    setDisabledError(true);
    setCloseModal(true);
    let error = { name: "one" };
    dispatch(
      deleteCategories(
        User.accessToken,
        User.userId,
        { categoriesIds: [deleteCategoriesOne] },
        setErrorDeleteCategory,
        setErrorDeleteCategoryOne,
        error
      )
    );

    dispatch(getCategories(User.accessToken, User.userId));
    setNameCategories("");
    setOpenModalQuestionDeleteOneItem(false);
    setDeleteCategories();

    setTimeout(() => {
      dispatch(getCategories(User.accessToken, User.userId));
    }, 100);
    setTimeout(() => {
      setDisabledError(false);
    }, 3000);
  };

  const leave = () => {
    setEditCategoriesLost(false);
    setOpenInfoModal([]);
    setEditCategories(false);
  };

  useEffect(() => {
    if (errorDeleteCategory !== undefined) {
      setTestCheck(true);
      setTimeout(() => {
        setChecked(checked);
        setTestCheck(false);
      }, 500);
      setChangeCategoriesId([]);
      setChangeCategoriesName([]);
    }
  }, [errorDeleteCategory, checked]);

  useEffect(() => {
    dispatch(getCategories(User.accessToken, User.userId));
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    background: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: 24,
    padding: "20px",
  };

  return (
    <>
      <Modal open={openModalQuestionDeleteOneItem}>
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h5"
            component="h1"
          >
            <p style={{ fontFamily: "Open Sans", fontWeight: "700" }}>
              Delete category?
            </p>
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {nameCategoriesOne} category will be deleted
            {errorDelete ? (
              <p style={{ color: "red" }}>
                delete any category if the category does not contain any product{" "}
              </p>
            ) : null}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "40px 0px 20px 0px",
            }}
          >
            <SubMenuButton
              underline="none"
              sx={{ color: "#25282A" }}
              onClick={() => setOpenModalQuestionDeleteOneItem(false)}
            >
              Keep
            </SubMenuButton>
            <SubMenuButton
              underline="none"
              sx={{ color: "#E9424D" }}
              onClick={() => deleteOneCategories()}
            >
              Delete
            </SubMenuButton>
          </div>
        </Box>
      </Modal>
      <Modal open={editCategoriesLost}>
        <Box sx={style}>
          <div>
            <div
              style={{
                position: "absolute",
                width: "93%",
                display: "flex",
                flexDirection: "row-reverse",
                top: "10px",
                right: "10px",
                justifyContent: "end",
              }}
            >
              <ActionButton
                sx={{ color: "#ACACAC" }}
                onClick={() => setEditCategoriesLost(false)}
              >
                <CloseIcon />
              </ActionButton>
            </div>
            <p
              style={{
                fontFamily: "Open Sans",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              Unsaved progress will be lost
            </p>
            <p style={{ fontFamily: "Open Sans", textAlign: "center" }}>
              Leave the page?
            </p>
            <div
              style={{
                fontFamily: "Open Sans",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                fontWeight: 700,
                margin: "40px 0px 20px 0px",
              }}
            >
              <SubMenuButton
                underline="none"
                onClick={() => setEditCategoriesLost(false)}
              >
                Stay
              </SubMenuButton>
              <SubMenuButton
                underline="none"
                sx={{ color: "#E9424D" }}
                onClick={() => leave()}
              >
                Leave
              </SubMenuButton>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={openModalQuestion}>
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h5"
            component="h1"
          >
            <p style={{ fontFamily: "Open Sans", fontWeight: "700" }}>
              Delete category?
            </p>
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {nameCategories} category will be deleted
            {errorDelete ? (
              <p style={{ color: "red" }}>
                delete any category if the category does not contain any product{" "}
              </p>
            ) : null}
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <SubMenuButton
              underline="none"
              sx={{ color: "#25282A" }}
              onClick={() => setOpenModalQuestion(false)}
            >
              Keep
            </SubMenuButton>
            <SubMenuButton
              underline="none"
              sx={{ color: "#E9424D" }}
              onClick={() => deleteCategory()}
            >
              Delete
            </SubMenuButton>
          </div>
        </Box>
      </Modal>
      <Modal open={openModal} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h5"
            component="h1"
          >
            <p style={{ fontFamily: "Open Sans", fontWeight: "700" }}>
              Delete category?
            </p>
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {testValueCategiry} category will be deleted
            {errorDelete ? (
              <p style={{ color: "red" }}>
                delete any category if the category does not contain any product{" "}
              </p>
            ) : null}
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <SubMenuButton
              underline="none"
              sx={{ color: "#25282A" }}
              onClick={() => handleClose(false)}
            >
              Keep
            </SubMenuButton>
            <SubMenuButton
              underline="none"
              sx={{ color: "#E9424D" }}
              onClick={() => deleteCategor()}
            >
              Delete
            </SubMenuButton>
          </div>
        </Box>
      </Modal>
      <div className="titleCategoriesTable">
        <label className="titleTable">
          <div style={{ display: "flex", gap: "3px" }}>
            <Title title={titles} sx={{ marginBottom: "51px" }} />
            <label className="amountStyle">
              ({categoriesResponse.category.length})
            </label>
          </div>

          <div className="subMenuButtonsGroup">
            {changeCategoriesName.length > 0 ? (
              <>
                <>
                  <SubMenuButton
                    underline="none"
                    sx={{ color: "#8E8E8E" }}
                    endIcon={<ClearIcon />}
                    onClick={() => discardSelection()}
                  >
                    Discard selection
                  </SubMenuButton>
                  <SubMenuButton
                    underline="none"
                    sx={{ color: "#E9424D" }}
                    endIcon={<DeleteOutlineIcon />}
                    onClick={() => handleOpen()}
                  >
                    Delete selected ({changeCategoriesName.length})
                  </SubMenuButton>
                </>
              </>
            ) : null}

            {User.position === "Associate" ? null : (
              <SubMenuButton
                underline="none"
                sx={{ color: "#25282A" }}
                endIcon={<AddIcon />}
                onClick={() => addCategories()}
              >
                Add new category
              </SubMenuButton>
            )}
          </div>
        </label>
      </div>
      <table className="tableBlockCategories">
        <thead>
          {User.position === "Associate" ? (
            <tr>
              {titleTableDisabledRole.map((item) => (
                <th key={item.id} className="titleCategories">
                  {item.title}
                </th>
              ))}
            </tr>
          ) : (
            <tr>
              {titleTable.map((item) => (
                <th key={item.id} className="titleCategories">
                  {item.title}
                </th>
              ))}
            </tr>
          )}
        </thead>
        {errorDeleteCategory?.errorMessage ? (
          <Alert
            severity="error"
            sx={{ marginTop: "20px", position: "fixed", left: "50%" }}
          >
            {errorDeleteCategory?.errorMessage}
          </Alert>
        ) : null}

        {categoriesResponse.category.map((item) => (
          <tbody key={item.id}>
            <tr key={item.id} className="tableBody">
              {User.position === "Associate" ? null : (
                <td style={{ display: "flex", justifyItems: "center" }}>
                  <input
                    disabled={User.position === "Associate" ? true : false}
                    className="custom-checkbox"
                    type="checkbox"
                    checked={testCheck ? false : checked[item.id]}
                    onChange={(event) =>
                      changeCategories(
                        event,
                        item.id,
                        item.name,
                        item.product
                      )
                    }
                  />
                </td>
              )}
              <td
                style={{ cursor: "pointer" }}
                className="tableBodyText"
                onClick={() => handleOpenInfo(item.id)}
              >
                {item.name}
              </td>

              {openInfoModal[item.id] ? (
                <Modal
                  open={true}
                  disableEnforceFocus
                  disableAutoFocus={true}
                  disableRestoreFocus={true}
                >
                  <Box sx={style}>
                    <div
                      style={{
                        position: "absolute",
                        width: "93%",
                        display: "flex",
                        flexDirection: "row-reverse",
                        top: "10px",
                        right: "10px",
                        justifyContent: "end",
                      }}
                    >
                      <ActionButton
                        sx={{ color: "#ACACAC" }}
                        onClick={() => handleCloseInfo(item.name)}
                      >
                        <CloseIcon />
                      </ActionButton>
                      <div className="actionButtonsGroup">
                        {!editCategories ? (
                          <>
                            {User.position === "Associate" ? null : (
                              <ActionButton
                                sx={{ color: "#ACACAC" }}
                                onClick={() => setEditCategories(true)}
                              >
                                <EditIcon />
                              </ActionButton>
                            )}
                            {User.position === "Associate" ? null : (
                              <ActionButton
                                sx={{ color: "#E9424D" }}
                                onClick={() =>
                                  handleOpenModalDeleteOneItem(
                                    item.name,
                                    item.id
                                  )
                                }
                              >
                                <DeleteOutlineIcon />
                              </ActionButton>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>

                    <InputCategories
                      value={item.name}
                      newNameCategories={newNameCategories}
                      edit={editCategories}
                      id={item.id}
                      costProduct={item.product}
                      setCloseModal={setCloseModal}
                      errorDeleteCategory={errorDeleteCategory}
                    />

                    {errorNewCategory ? (
                      <Alert severity="error" sx={{ marginTop: "20px" }}>
                        A category with the same name already exists or the name
                        contains numbers or symbols
                      </Alert>
                    ) : null}
                    {disabledError ? (
                      <>
                        {errorDeleteCategoryOne ? (
                          <Alert severity="error" sx={{ marginTop: "20px" }}>
                            {errorDeleteCategoryOne?.errorMessage}
                          </Alert>
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                </Modal>
              ) : null}
              <td style={{ paddingBottom: "20px" }}>{item.product}</td>
            </tr>
          </tbody>
        ))}
      </table>
    </>
  );
};
export default Categories;
