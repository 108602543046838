import "./styles.css";

const Index = ({ backPage }) => {
  return (
    <label onClick={() => backPage()} className="linkStyle">
      Back to log in
    </label>
  );
};
export default Index;
