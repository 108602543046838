import { combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import { userReducer } from "./user/reducer";
import { ordersReducer } from "./orders/reducer";
import {productsReducer} from "./products/reducer";
import {associationReducer} from "./associates/reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  profile: userReducer,
  rootOrders: ordersReducer,
  product:productsReducer,
  userAssociations:associationReducer
});
