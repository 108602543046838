import * as types from "./types";

const initialState = {
  pageDefIndex: 2,
  stateBack: 1,
  responseProduct: [],
  responseUpdateProducts: [],
  responseCreateProduct: [],
  responseCategories: [],
  responseNewCategories: ["category"],
  responseDeprecated: [],
  isFetching: true,
  isFetchError: false,
  isFetchSuccess: false,
};
export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload };
    case types.SET_FETCH_ERROR:
      return { ...state, isFetchError: action.payload };
    case types.SET_FETCH_SUCCESS:
      return { ...state, isFetchSuccess: action.payload };
    case types.GET_DEPRECATED:
      return { ...state, responseDeprecated: action.payload };
    case types.BACKGROUND_STATE:
      return { ...state, stateBack: action.payload };
    case types.GET_PRODUCTS:
      return { ...state, responseProduct: action.payload };
    case types.CLEAR_PRODUCTS:
      return { ...state, responseProduct: [] };
    case types.CLEAR_PRODUCTS_CATEGORIES:
      return { ...state, responseCategories: [] };
    case types.UP_DATE_PRODUCTS:
      return { ...state, responseUpdateProducts: action.payload };
    case types.CREATE_NEW_PRODUCTS:
      return { ...state, responseCreateProduct: action.payload };
    case types.GET_PRODUCTS_CATEGORIES:
      return { ...state, responseCategories: action.payload };
    case types.CREATE_NEW_CATEGORIES:
      return { ...state, responseNewCategories: action.payload };
    // case types.ACTIVE_PAGE:
    //   return { ...state, pageDefIndex: action.payload };

    default:
      return state;
  }
};
