import "./styles.scss";

const ModalDiscard = ({
  visible,
  title,
  question,
  answerOne,
  answerTwo,
  discard,
  stay,
  styleVisible,
  stayProfile,
}) => {
  return (
    // <div style={{width:'344px'}} className={visible?`${styleVisible}`:'modalHideDiscord'} >
    // <div style={{width:'344px'}} className={'modalVisibleDiscord'} >
    <div style={{ width: "344px" }} className={styleVisible}>
      <span>{title}</span>
      <span style={{ fontSize: "14px", fontWeight: "400" }}>{question}</span>
      <div style={{ marginTop: "40px" }}>
        <button
          onClick={() => stay()}
          className="linkStylesDiscord"
          style={{ cursor: "pointer" }}
        >
          {answerOne}
        </button>

        <button
          onClick={() => discard()}
          className="linkStylesDiscord"
          style={{ cursor: "pointer" }}
        >
          {answerTwo}
        </button>
      </div>
    </div>
  );
};
export default ModalDiscard;
