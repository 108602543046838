import * as types from "./types";

//PRODUCTS
export const getDeprecated = (token, userId) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/deprecated-product-list`, {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(getProductDeprecated(data));
          }
        });
    } catch (e) {}
  };
};
export const restoreProducts = (token, data, userId) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/restore-products`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(getAllProducts(token));
          }
        });
    } catch (e) {}
  };
};

export const getAllProducts = (token, userId) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_URL}catalog/product-list/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        dispatch(getProducts(data));
        dispatch(setFetchError(false));
        dispatch(setFetchSuccess(true));
      })
      .catch((error) => {
        dispatch(setFetchError(true));
        dispatch(setFetchSuccess(false));
        console.error(error);
        if (error.message === "Unauthorized") {
          localStorage.clear();
          window.location.reload();
        }
      });
  };
};

export const updateProducts = (
  token,
  userId,
  productId,
  data,
  setErrorMessageProduct,
  setQuantityProduct,
  quantityProduct
) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/update-product/${userId}/${productId}`, {
        method: "PUT",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            setErrorMessageProduct(data);
            getAllProducts(token, userId);
            setTimeout(() => {
              setErrorMessageProduct();
            }, 2000);
            setQuantityProduct(quantityProduct);
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(upDateProductsResponse(data));
            dispatch(getAllProducts(token));
          }
        });
    } catch (e) {}
  };
};

export const createNewProducts = (token, userId, data) => {
  return async (dispatch) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}catalog/create-product/${userId}`,
      {
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseData = await response.json();

    if (response.ok) {
      dispatch(setFetchError(false));
      dispatch(newProductResponse(responseData));
    } else {
      dispatch(setFetchError(true));
      dispatch(setFetchSuccess(false));
      if (response.status === 401) {
        localStorage.clear();
      } else {
        dispatch(newProductResponse(responseData));
      }
    }
  };
};

export const deleteProduct = (token, userId, data) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/delete-product/${userId}`, {
        method: "DELETE",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
          }
        });
    } catch (e) {}
  };
};

export const deleteProductSome = (token, userId, productId) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/delete-product/${userId}/${productId}`, {
        method: "DELETE",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
          }
        });
    } catch (e) {}
  };
};

//CATEGORIES
export const getCategories = (token, userId) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/category/${userId}`, {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(getCategoriesResponse(data));
          }
        });
    } catch (e) {}
  };
};

export const newCategories = (token, userId, data) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/create-category/${userId}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            // dispatch(getCategoriesResponse(data))
          }
        });
    } catch (e) {}
  };
};

export const changeNameCategories = (token, userId, catalogId, data) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/update-category/${userId}/${catalogId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            // dispatch(getCategoriesResponse(data))
          }
        });
    } catch (e) {}
  };
};
export const deleteCategories = (
  token,
  userId,
  categoryId,
  setErrorDeleteCategory,
  setErrorDeleteCategoryOne,
  error
) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}catalog/delete-category/${userId}`, {
        method: "DELETE",
        body: JSON.stringify(categoryId),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
            if (error.name === "one") {
              setErrorDeleteCategoryOne(data);
            } else {
              setErrorDeleteCategory(data);
            }

            setTimeout(() => {
              setErrorDeleteCategory();
              setErrorDeleteCategoryOne();
            }, 3000);
          } else {
            getCategories(token, userId);
          }
        });
    } catch (e) {}
  };
};

//-------
export const changeStateBack = (boolean) => ({
  type: types.BACKGROUND_STATE,
  payload: boolean,
});

export const getProducts = (products) => ({
  type: types.GET_PRODUCTS,
  payload: products,
});
export const clearProducts = (products) => ({
  type: types.CLEAR_PRODUCTS,
  payload: products,
});
export const upDateProductsResponse = (response) => ({
  type: types.UP_DATE_PRODUCTS,
  payload: response,
});
export const newProductResponse = (response) => ({
  type: types.CREATE_NEW_PRODUCTS,
  payload: response,
});
export const getCategoriesResponse = (response) => ({
  type: types.GET_PRODUCTS_CATEGORIES,
  payload: response,
});
export const clearCategories = (response) => ({
  type: types.CLEAR_PRODUCTS_CATEGORIES,
  payload: response,
});
export const getProductDeprecated = (response) => ({
  type: types.GET_DEPRECATED,
  payload: response,
});

export const setIsFetching = (bool) => ({
  type: types.SET_IS_FETCHING,
  payload: bool,
});

export const setFetchError = (bool) => ({
  type: types.SET_FETCH_ERROR,
  payload: bool,
});

export const setFetchSuccess = (bool) => ({
  type: types.SET_FETCH_SUCCESS,
  payload: bool,
});

// export const activePageDefault = (page) => ({
//   type: types.ACTIVE_PAGE,
//   payload: page,
// });
