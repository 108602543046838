import "./styles.scss";
import { ban, getAssociation } from "../../../redux/associates/action";
import { useDispatch } from "react-redux";

const ModalBanSelected = ({
  setAllBan,
  status,
  token,
  blockSelect,
  discard,
}) => {
  const dispatch = useDispatch();
  const banAll = () => {
    if (blockSelect[0]) {
      const urlBan = "user/unban-associate-user";
      const idUser = status.filter((item) => item.id).map((item) => item.id);

      let data = {
        usersIds: idUser,
      };
      setAllBan(false);
      dispatch(ban(token, data, urlBan));
      discard();
      setTimeout(() => {
        dispatch(getAssociation(token));
      }, 2000);
    } else {
      const urlBan = "user/ban-associate-user";
      const idUser = status.filter((item) => item.id).map((item) => item.id);

      let data = {
        usersIds: idUser,
      };
      setAllBan(false);
      dispatch(ban(token, data, urlBan));

      discard();
      setTimeout(() => {
        dispatch(getAssociation(token));
      }, 2000);
    }
  };
  return (
    <div className="modal_ban">
      <div className="title_ban_unban">
        <p>This user will be {!blockSelect[0] ? "banned" : "unBan"}</p>
      </div>
      <p>Proceed?</p>
      <div>
        <button className="discard_style_ban" onClick={() => setAllBan(false)}>
          Discard
        </button>
        <button
          className={!blockSelect[0] ? "style_ban" : "style_ban_green"}
          onClick={() => banAll()}
        >
          {!blockSelect[0] ? "Ban user" : "unBan user"}
        </button>
      </div>
    </div>
  );
};
export default ModalBanSelected;
