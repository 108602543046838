import "./styles.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActive, getPrevious } from "../../redux/orders/actions";
import * as React from "react";
import {
  getAllProducts,
  // getCategories,
  getDeprecated,
} from "../../redux/products/action";
import Categories from "../categories";
import TableProduct from "./table-product";
import TablePrevious from "./table-previous";
import TableActiveOrders from "./table-active-orders";

const Table = ({
  itemNumberPrevious,
  titleTable,
  titles,
  amountActive,
  amountPrevious,
  addProduct,
  activeOrders,
  previousOrder,
  setPageActive,
  setPagePrevious,
  pageActive,
  pagePrevious,
  addCategories,
  setStateQuantity,
  stateQuantity,
}) => {
  const dispatch = useDispatch();
  const UserProfile = useSelector((state) => state.profile.userProfiles);
  const [showDetailed, setShowDetailed] = useState([false, false]);
  // const [showMinute, setShowMinute] = useState([false, false]);
  const [defaultPage, setDefaultPage] = useState(0);
  const [currentPageActive, setCurrentPageActive] = useState();
  const [currentPagePrevious, setCurrentPagePrevious] = useState();
  const activeOrdersLength = useSelector(
    (state) => state.rootOrders.activeOrders
  );
  // const previousOrdersLength = useSelector(
  //   (state) => state.rootOrders.previousOrder
  // );
  const productArr = useSelector((state) => state.product.responseProduct);
  const productDeletedArr = useSelector(
    (state) => state.product.responseDeprecated
  );

  // const stateModal = useSelector((state) => state.product.stateBack);
  const [lengthOrderActive, setActive] = useState("");
  // const [lengthOrderPrevious, setPrevious] = useState("");
  useEffect(() => {
    dispatch(getAllProducts(UserProfile.accessToken, UserProfile.userId));
    dispatch(getDeprecated(UserProfile.accessToken));
  }, []);
  useEffect(() => {
    setActive(activeOrdersLength.activeOrdersCount);
    // setPrevious(previousOrdersLength.previousOrdersCount);
    let activeOrder = amountActive;

    if (activeOrder < 15) {
      setCurrentPageActive(1);
    }
    if (activeOrder > 15) {
      let page = Math.ceil(activeOrder / 15);

      setCurrentPageActive(page);
    }

    let previousOrder = amountPrevious;

    if (previousOrder < 15) {
      setCurrentPagePrevious(1);
    }
    if (previousOrder > 15) {
      let pagePrev = Math.ceil(previousOrder / 15);

      setCurrentPagePrevious(pagePrev);
    }
  }, [amountActive]);

  const pageChange = (data) => {
    // let page=e.target.value
    const page = data.selected + 1;
    setPageActive(page);
    dispatch(getActive(UserProfile.userId, UserProfile.accessToken, page, 15));
  };
  const pageChangePrevious = (data) => {
    // let page=e.target.value
    const page = data.selected + 1;
    setPagePrevious(page);
    dispatch(
      getPrevious(UserProfile.userId, UserProfile.accessToken, page, 15)
    );
  };
  const openDetailed = (i) => {
    const newShowDetailed = showDetailed.slice();
    newShowDetailed[i] = !showDetailed[i];
    setShowDetailed(...[newShowDetailed]);
  };

  const [selectChoice, setSelectChoice] = useState([]);
  const [checked, setChecked] = useState([false, false]);
  const changeCheck = (i, arr) => {
    const check = checked.slice();
    check[i] = !checked[i];
    setChecked(...[check]);
  };

  const [showDeteiled, setShowDeteiled] = useState([false, false]);

  const activeModal = (i) => {
    const newShowDeteiled = showDeteiled.slice();
    newShowDeteiled[i] = !showDeteiled[i];
    setShowDeteiled(...[newShowDeteiled]);
  };

  const disableModal = (i) => {
    const newShowDeteiled = showDeteiled.slice();
    newShowDeteiled[i] = !showDeteiled[i];
    setShowDeteiled(...[newShowDeteiled]);
  };

  if (titles === "Products") {
    return (
      <>
        <TableProduct
          titles={titles}
          productArrLength={productArr.length}
          selectChoice={selectChoice}
          addProduct={addProduct}
          titleTable={titleTable}
          productArr={productArr}
          showDeteiled={showDeteiled}
          disableModal={disableModal}
          checked={checked}
          changeCheck={changeCheck}
          activeModal={activeModal}
          userId={UserProfile.userId}
          token={UserProfile.accessToken}
          setStateQuantity={setStateQuantity}
          stateQuantity={stateQuantity}
        />
      </>
    );
  }
  if (titles === "Archived Products") {
    return (
      <>
        <TableProduct
          titles={titles}
          productArrLength={productDeletedArr.length}
          selectChoice={selectChoice}
          setStateQuantity={setStateQuantity}
          stateQuantity={stateQuantity}
          titleTable={titleTable}
          productArr={productDeletedArr}
          showDeteiled={showDeteiled}
          disableModal={disableModal}
          checked={checked}
          changeCheck={changeCheck}
          activeModal={activeModal}
          userId={UserProfile.userId}
          token={UserProfile.accessToken}
        />
      </>
    );
  }

  if (titles === "Categories") {
    return <Categories titles={titles} addCategories={addCategories} />;
  }

  if (titles === "Previous") {
    return (
      <TablePrevious
        amountPrevious={amountPrevious}
        openDetailed={openDetailed}
        currentPagePrevious={currentPagePrevious}
        pageChangePrevious={pageChangePrevious}
        showDetailed={showDetailed}
        previousOrder={previousOrder}
        titleTable={titleTable}
        pageActive={pageActive}
        pagePrevious={pagePrevious}
      />
    );
  }

  if (titles === "Active Orders") {
    return (
      <TableActiveOrders
        titles={titles}
        titleTable={titleTable}
        showDetailed={showDetailed}
        setDefaultPage={setDefaultPage}
        activeOrders={activeOrders}
        openDetailed={openDetailed}
        itemNumberPrevious={itemNumberPrevious}
        lengthOrderActive={lengthOrderActive}
        defaultPage={defaultPage}
        currentPageActive={currentPageActive}
        pageChange={pageChange}
        pageActive={pageActive}
        pagePrevious={pagePrevious}
      />
    );
  }
};
export default Table;
