export const SIGNIN = "SIGNIN";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_AUTH_ERROR_MESSAGE = "SET_AUTH_ERROR_MESSAGE";
export const LOG_OUT = "LOG_OUT";
export const NUMBER_PHONE = "NUMBER_PHONE";
export const NUMBER_PHONE_RELOAD = "NUMBER_PHONE_RELOAD";
export const SET_RESPONSE_VERIFY_CODE = "SET_RESPONSE_VERIFY_CODE";
export const NEW_PASSWORD_RESPONSE = "NEW_PASSWORD_RESPONSE";
export const RESET_CODE_RESPONSE = "RESET_CODE_RESPONSE";
export const RESET_PHONE = "RESET_PHONE";
export const RESET_NEW_PASSWORD = "RESET_NEW_PASSWORD";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
