import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAssociation, updateUser } from "../../redux/associates/action";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./validation/validation.constants.js";
import Form from "../../components/form";
import { TextField, InputAdornment, Stack } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { ActionButton, SubmitButton } from "../../shared/styles.styled";
import "./styles.scss";

const ModalEditAssociate = ({
  profileAssociates,
  token,
  setEditAssociateModal,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {}, [profileAssociates]);

  const cancelEditingAssociate = () => {
    reset({
      associateUserId: profileAssociates?.userId,
      phone: profileAssociates?.phone,
      fullName: profileAssociates?.fullName,
      email: profileAssociates?.email,
    });
  };

  const saveEditingAssociate = (data) => {
    dispatch(updateUser(token, data));
    setEditAssociateModal(false);
    setTimeout(() => {
      dispatch(getAssociation(token));
    }, 1000);
  };

  const onSubmit = async (value) => {
    saveEditingAssociate(value);
  };

  const {
    handleSubmit,
    control,
    // watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      associateUserId: profileAssociates?.userId,
      phone: profileAssociates?.phone,
      fullName: profileAssociates?.fullName,
      email: profileAssociates?.email,
    },
  });

  return (
    <div className="modal_edit">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p className="title_edit_user">Edit user info</p>
        <ActionButton
          sx={{ color: "#ACACAC", position: "absolute", right: "25px" }}
          onClick={() => setEditAssociateModal(false)}
        >
          <CloseIcon />
        </ActionButton>
      </div>
      <Form class="form" onSubmit={onSubmit} handleSubmit={handleSubmit}>
        <Controller
          render={({ field }) => (
            <TextField
              autoFocus
              helperText={errors.phone ? `${errors.phone.message}` : ""}
              autoComplete="off"
              variant="standard"
              defaultValue={profileAssociates?.phone}
              errors={!!errors.phone}
              label={"Phone number"}
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          )}
          name="phone"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField
              helperText={errors.email ? `${errors.email.message}` : ""}
              autoComplete="off"
              variant="standard"
              defaultValue={profileAssociates?.email}
              errors={!!errors.email}
              label={"Email"}
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          )}
          name="email"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField
              helperText={errors.fullName ? `${errors.fullName.message}` : ""}
              autoComplete="off"
              variant="standard"
              defaultValue={profileAssociates?.fullName}
              errors={!!errors.fullName}
              label={"Name"}
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          )}
          name="fullName"
          control={control}
        />
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "31px",
          }}
          direction="row"
        >
          <ActionButton
            underline="none"
            sx={{ color: "#25282A", width: "163px", height: "37px" }}
            onClick={() => cancelEditingAssociate()}
          >
            Cancel
          </ActionButton>
          <SubmitButton
            sx={{ width: "163px", height: "37px", marginTop: "0px" }}
            data-testid="submit-btn"
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </SubmitButton>
        </Stack>
      </Form>
    </div>
  );
};

export default ModalEditAssociate;
