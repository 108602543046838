import * as types from "./types";

const initialState = {
  orders: [],
  activeOrders: [],
  previousOrder: [],
  uptDateStatus: "",
  responseUpDateStatus: {},
  changePreparation: "",
  pageIndex:0
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDERS:
      return { ...state, orders: action.payload };
    case types.GET_ACTIVE_ORDERS:
      return { ...state, activeOrders: action.payload };
    case types.GET_PREVIOUS_ORDERS:
      return { ...state, previousOrder: action.payload };
    case types.CLEAR_ORDERS:
      return { ...state, activeOrders: [] };
    case types.UP_DATE_STATUS_ORDER:
      return { ...state, uptDateStatus: action.payload };
    case types.RESPONSE_UP_DATE_STATUS_ORDER:
      return { ...state, responseUpDateStatus: action.payload };
    case types.changePreparation:
      return { ...state, changePreparation: action.payload };
    default:
      return state;
  }
};
