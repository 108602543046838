import "./styles.scss";


const DetailedArea = ({
  showDetailed,
  name,
  amount,
  price,
  packages,
  size,
}) => {
  const titleDetailed = [
    { title: "Name" },
    { title: "Amount" },
    { title: "Package" },
    { title: "Price" },
  ];

  return (
    <table className={showDetailed ? "tableDetailed" : "hideTableDetailed"}>
      <thead>
      <tr className="titleDetailed">
        {titleDetailed.map((name) => (
          <th key={name.title}>{name.title}</th>
        ))}
      </tr>
      </thead>
      <tbody>
      <tr className="infoDetailed">
        <td>{name}</td>

        <td>{amount}</td>
        <td>
          {size} {packages}
        </td>
        <td>${price}</td>
      </tr>
      </tbody>
    </table>
  );
};
export default DetailedArea;
