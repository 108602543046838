import * as types from "./type";
// import {ACTIVE_TAB, GET_PROFILE_ASSOCIATES} from "./type";

const initialState = {
    users:[],
    profile:[],
    errors:[],
    tabActive:1
};
export const associationReducer = (state = initialState, action) => {
        switch (action.type) {
            case types.GET_ASSOCIATES:
                return { ...state, users: action.payload };
            case types.GET_PROFILE_ASSOCIATES:
                return { ...state, profile: action.payload };
            case types.ERRORS:
                return { ...state, errors: action.payload };
            case types.ACTIVE_TAB:
                return { ...state, tabActive: action.payload };
            default:
                return state;
        }
}

