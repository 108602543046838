import Index from "../../components/auth-title";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { VerifyCodeFields } from "../../components/verify-code-fields/VerifyCodeFields";
import { AuthContainer } from "../../components/auth-container";
import BackIcon from "../../components/icon/back-icon";
import { useNavigate } from "react-router-dom";
import ModalQuestion from "../../components/modalWindow/modal-question";
import Button from "../../components/new-button";
import { useDispatch, useSelector } from "react-redux";
import {
  codeEnter,
  numberPhone,
  resetCodeResponse,
  resetPhone,
} from "../../redux/auth/actions";
import ModalWindow from "../../components/modalWindow/modalWindow";
import { Box, Modal } from "@mui/material";

const EnterTheCode = () => {
  const [codeVerify, setCodeVerify] = useState("");
  const [code, setCode] = useState([...Array(4)].map(() => ""));
  const [activeButton, setActiveButton] = useState(true);
  const [visible, setVisible] = useState(false);
  const { phone, codeResponse } = useSelector((state) => state.auth);
  const [visibl, setVisibl] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (code[0] !== "" && code[1] !== "" && code[2] !== "" && code[3] !== "") {
      setActiveButton(false);
      setCodeVerify(codeVerify.join(""));
    }
  }, [code]);

  useEffect(() => {
    if (codeResponse.accessToken) {
      setVisibl(false);
      navigate("/resetPassword/newPassword");
    }
    if (codeResponse.httpStatus >= 400) {
      setVisibl(false);
      setTimeout(() => {
        dispatch(resetCodeResponse());
      }, 3000);
    }
  }, [codeResponse]);

  const resetPassword = () => {
    setVisibl(true);
    dispatch(
      codeEnter({
        phone: phone.phone,
        passCode: codeVerify,
      })
    );
  };
  const backPage = () => {
    if (visible === false) {
      setVisible(true);
    }
  };
  const stay = () => {
    if (visible === true) {
      setVisible(false);
    }
  };
  const backLogin = () => {
    dispatch(resetPhone());
    navigate("/");
  };

  const sendCode = () => {
    let newPhone = phone.phone;
    dispatch(resetPhone());
    dispatch(numberPhone({ phone: newPhone }));
    setResendDisabled(true);

    setTimeout(() => {
      setResendDisabled(false);
    }, 20000);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <AuthContainer>
      <Modal open={visibl}>
        <Box sx={style}>
          <ModalWindow visibl={visibl} title=" " loadText="Redirecting..." />
        </Box>
      </Modal>{" "}
      <ModalQuestion
        visible={visible}
        setVisible={setVisible}
        stay={stay}
        backLogin={backLogin}
        backPage={backPage}
        title="Go back to log in?"
        question="Unsaved progress will be lost"
        answerOne="Stay"
        answerTwo="Back to log in"
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <BackIcon backPage={backPage} />
        <Index title="Enter the code" />
      </div>
      <p>Verification code to reset your password was sent to {"number"}</p>
      <VerifyCodeFields
        code={code}
        setCode={setCode}
        onClick={(e) => setCodeVerify(e)}
      />
      {codeResponse.errorMessage ? (
        <label style={{ color: "red" }}>{codeResponse.errorMessage}</label>
      ) : (
        ""
      )}
      <button
        disabled={resendDisabled}
        className={resendDisabled ? "resentCodeDisabled" : "resentCode"}
        onClick={() => sendCode()}
      >
        resend code?
      </button>
      <Button
        click={resetPassword}
        disabled={activeButton}
        value={"Reset password"}
        typeAction={"buttonLogIn"}
        typeNoActive={"activeButtonLogIn"}
      />
    </AuthContainer>
  );
};
export default EnterTheCode;
