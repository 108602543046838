import * as types from "./types";

const initialState = {
  user: {},
  authError: "",
  phone: {},
  codeResponse: {},
  newPasswordResponse: {},
  errorMessage: "",
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_INFO:
      return { ...state, user: action.payload };
    case types.SET_AUTH_ERROR_MESSAGE:
      return { ...state, authError: action.payload };
    case types.LOG_OUT:
      return { ...state, user: {} };
    case types.RESET_CODE_RESPONSE:
      return { ...state, codeResponse: {} };
    case types.RESET_PHONE:
      return { ...state, phone: {} };
    case types.RESET_NEW_PASSWORD:
      return { ...state, newPasswordResponse: {} };
    case types.NUMBER_PHONE:
      return { ...state, phone: action.payload };
    case types.NEW_PASSWORD_RESPONSE:
      return { ...state, newPasswordResponse: action.payload };
    case types.SET_RESPONSE_VERIFY_CODE:
      return { ...state, codeResponse: action.payload };
    case types.ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };

    default:
      return state;
  }
};
