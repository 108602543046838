import { useEffect, useState } from "react";
import "./styles.scss";

const InputCategories = ({
  value,
  newNameCategories,
  edit,
  id,
  costProduct,
  setCloseModal,
}) => {
  const [disabledButton, setDisabledButton] = useState(true);
  const [editCategories, setEditCategories] = useState(value);
  useEffect(() => {
    if (editCategories !== value && editCategories.length !== 0) {
      setDisabledButton(false);
      setCloseModal(true);
    } else {
      setDisabledButton(true);
      setCloseModal(false);
    }
  }, [editCategories]);

  return (
    <div>
      <div>
        <div className={edit ? "edit-categories" : "disabled-categories"}>
          <label>
            <b>{edit ? "Category name" : "Category: "}</b>
          </label>
          <input
            ref={function (input) {
              if (input != null) {
                input.focus();
              }
            }}
            disabled={!edit ? true : false}
            className={edit ? "nameCategoriesEdit" : "nameCategories"}
            type="text"
            value={editCategories}
            onChange={(e) => setEditCategories(e.target.value)}
          />
        </div>
        {edit ? (
          <div className="block-button">
            <button
              style={{ cursor: "pointer" }}
              onClick={() => setEditCategories(value)}
              className="discard-changes"
            >
              Discard changes
            </button>
            <button
              style={{ cursor: "pointer" }}
              disabled={disabledButton ? true : false}
              className={disabledButton ? "save-changes" : "save-changesEdit"}
              onClick={() => newNameCategories(editCategories, id)}
            >
              Save changes
            </button>
          </div>
        ) : null}
      </div>
      <>
        {edit ? null : (
          <label>
            <b>Products in category: </b>
            {costProduct}
          </label>
        )}
      </>
    </div>
  );
};
export default InputCategories;
