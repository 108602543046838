import "./styles.scss";
import Ratings from "../ratings";
import PhotoUser from "../../assets/products/userPhoto.png";

const CommentUser = ({ phone, fullName, rates }) => {

  return (
    <div className="blockComment">
      <div className="blockCommentFlex">
        <div className="userInfoRating">
          <img src={PhotoUser} style={{ width: 60, height: 60 }} alt="" />
          <div className="userNamePhone">
            {/*<label>Name User phone user</label>*/}
            <label
              style={{
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: "Open Sans",
              }}
            >
              {rates.rate_by_user.fullName}{" "}
              <label>{rates.rate_by_user.phone}</label>
            </label>

            <label>{rates.created_at}</label>
          </div>
        </div>
        <div className="ratingPosition">
          <Ratings styleRating="rating-result" rate={rates.rate} />
        </div>
      </div>

      <p className="commentUser">{rates.comment}</p>
    </div>
  );
};
export default CommentUser;
