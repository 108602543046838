import * as yup from "yup";

export const schema = yup.object().shape({
  phone: yup
    .string()
    .min(10, "Minimum 10 symbols")
    .max(30, "Maximum 18 symbols"),
  fullName: yup.string(),
  email: yup.string().email(),
  password: yup.string(),
});
