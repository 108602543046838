import CloseIcon from "../../../assets/products/close.png";
import GarbageIcon from "../../../assets/products/garbage.png";
import PlusIcon from "../../../assets/products/plus.png";
import RestoreIcon from "../../../assets/Vector.png";

import ModalInfoProduct from "../../modalWindow/modal-info-product";
import { useEffect, useState } from "react";
import "./styles.scss";
import { Box, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getAllProducts,
  getDeprecated,
  restoreProducts,
} from "../../../redux/products/action";
import React from "react";
const TableProduct = ({
  titles,
  productArr,
  addProduct,
  titleTable,
  productArrLength,
  userId,
  token,
  setStateQuantity,
}) => {
  const titleTableDisabledRole = [
    { title: "Cover", id: 1 },
    { title: "Product", id: 2 },
    { title: "Category", id: 3 },
    { title: "Number", id: 4 },
    { title: "Package size", id: 5 },
    { title: "Package type", id: 6 },
    { title: "Cost", id: 7 },
    { title: "Available", id: 8 },
    { title: "Quantity", id: 9 },
    { title: "Rating", id: 10 },
  ];
  const [modal, setModal] = useState([]);
  const [clearCheck, setClearCheck] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [reloadChecked, setReloadChecked] = useState(false);
  const [arrDeleteProduct, setArrDeleteProduct] = useState([]);
  const [arrDeleteProductName, setArrDeleteProductName] = useState([]);
  // const [disabled, setDisabled] = useState(true);
  const [arrayQuantity, setArrayQuantity] = useState([]);
  const [quantityState, setQuantityState] = useState(false);
  const dispatch = useDispatch();
  const UserProfile = useSelector((state) => state.profile.userProfiles);

  const changeCheck = (i, arr, event, quantity) => {
    if (event.target.checked === true) {
      setQuantityState(true);
      // setDisabled(false);
      setArrDeleteProduct([...arrDeleteProduct, i]);
      setArrDeleteProductName([...arrDeleteProductName, event.target.value]);
      setArrayQuantity((prev) => [...prev, quantity]);
    }
    if (event.target.checked === false) {
      if (quantityState === true) {
        setQuantityState(false);
      } else {
        setQuantityState(true);
      }

      let quantityDelete = arrayQuantity.indexOf(quantity);
      arrayQuantity.splice(quantityDelete, 1);
      let newArry = arrDeleteProduct.filter((item) => item !== i);
      let newArryName = arrDeleteProductName.filter(
        (item) => item !== event.target.value
      );
      setArrDeleteProductName(newArryName);
      setArrDeleteProduct(newArry);
      setArrayQuantity(arrayQuantity);
    }
  };

  useEffect(() => {
    let quantity = productArr.filter((item) => item.quantity <= 10);
    if (quantity.length > 0) {
      setStateQuantity(true);
    } else setStateQuantity(false);
  }, [productArr]);

  // useEffect(() => {
  //   if (arrDeleteProductName.length < 1) {
  //     setDisabled(true);
  //   }
  // }, [arrDeleteProductName]);

  const openModal = (i) => {
    const newModalOpen = modal.slice();
    newModalOpen[i] = !modal[i];
    setModal(...[newModalOpen]);
  };
  const [modalQuestion, setModalQuestion] = useState(false);
  const exit = () => {
    setModalQuestion(false);
    setArrDeleteProductName([]);
    setArrDeleteProduct([]);
    // setReloadChecked(true);
    // setTimeout(() => {
    //   setReloadChecked(false);
    // }, 500);
  };
  const deleteSelected = () => {
    let data = {
      productIds: arrDeleteProduct,
    };
    dispatch(deleteProduct(token, userId, data));
    exit();
    setClearCheck(true);

    setTimeout(() => {
      setClearCheck(false);
      dispatch(getAllProducts(token));
      dispatch(getDeprecated(token));
    }, 500);
  };
  const [productNameTest, setProductNameTest] = useState([]);
  const openModalQuestion = () => {
    setModalQuestion(true);
    setProductNameTest(arrDeleteProductName);
    let testArr = arrDeleteProductName.join(", ");
    setArrDeleteProductName(testArr);
  };
  const keep = () => {
    setModalQuestion(false);

    setArrDeleteProductName(productNameTest);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  const discardSelection = () => {
    setClearCheck(true);
    setArrDeleteProduct([]);
    setArrDeleteProductName([]);
    setTimeout(() => {
      setClearCheck(false);
    }, 500);
  };
  const [testState, setTestState] = useState([false]);
  const allCategory = (id) => {
    const newModalOpen = testState.slice();
    newModalOpen[id] = !testState[id];
    setTestState(...[newModalOpen]);
  };
  const restoreProduct = () => {
    let data = {
      products: arrDeleteProduct,
    };
    exit();
    dispatch(restoreProducts(token, data, userId));
    setClearCheck(true);

    setTimeout(() => {
      setClearCheck(false);
      dispatch(getDeprecated(token));
    }, 500);
  };
  if (titles === "Products") {
    return (
      <>
        <Modal open={modalQuestion}>
          <Box sx={style}>
            <Typography
              align="center"
              id="modal-modal-title"
              variant="h5"
              component="h1"
            >
              <p style={{ fontFamily: "Open Sans", fontWeight: "700" }}>
                Archive product?
              </p>
            </Typography>
            <Typography
              align="center"
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              {arrDeleteProductName} product will be archived
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p
                onClick={() => keep()}
                style={{
                  cursor: "pointer",
                  color: "#25282A",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Keep
              </p>
              <p
                style={{
                  cursor: "pointer",
                  color: "#E9424D",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
                onClick={() => deleteSelected()}
              >
                Archive
              </p>
            </div>
          </Box>
        </Modal>
        <div>
          {productArr.map((item) => (
            <React.Fragment key={item.id}>
              {modal[item.id] ? (
                <Modal
                  open={true}
                  disableEnforceFocus
                  disableAutoFocus={true}
                  disableRestoreFocus={true}
                >
                  <Box>
                    <ModalInfoProduct
                      info={item}
                      id={item.id}
                      activeModal={openModal}
                      modal={modal}
                      token={token}
                      userId={userId}
                      rates={item.rates}
                      categoriesItem={item.categories}
                    />
                  </Box>
                </Modal>
              ) : null}
            </React.Fragment>
          ))}
        </div>
        <div className="titleTableProduct" style={{ width: "100%" }}>
          <div style={{ fontFamily: "Norwester" }}>
            {titles}
            <label className="amoutStyle">({productArrLength})</label>
          </div>
          <div className="buttonNewProductEtc">
            {arrDeleteProduct.length > 0 ? (
              <>
                <button
                  style={{ cursor: "pointer" }}
                  className={"buttonDisacrad"}
                  onClick={() => discardSelection()}
                >
                  Discard selection
                  <img src={CloseIcon} alt="" />
                </button>
                <button
                  className={"buttonDelete"}
                  onClick={() => openModalQuestion()}
                >
                  Archive selected ({arrDeleteProduct.length})
                  <img src={GarbageIcon} alt="" />
                </button>
              </>
            ) : null}
            {UserProfile.position === "Associate" ? null : (
              <label
                onClick={() => addProduct()}
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Add new product
                <img src={PlusIcon} alt="" />
              </label>
            )}
          </div>
        </div>
        <table className="tableBlock" style={{ marginLeft: "60px" }}>
          <thead>
            {UserProfile.position === "Associate" ? (
              <tr>
                {titleTableDisabledRole.map((item) => (
                  <th
                    key={item.id}
                    className="orderTitle"
                    style={{ fontFamily: "Open Sans" }}
                  >
                    {item.title}
                  </th>
                ))}
              </tr>
            ) : (
              <tr>
                {titleTable.map((item, index) => (
                  <th
                    key={index}
                    className="orderTitle"
                    style={{ fontFamily: "Open Sans" }}
                  >
                    {item.title}
                  </th>
                ))}
              </tr>
            )}
          </thead>

          {productArr.map((item) => (
            <tbody key={item.id}>
              <tr className="infoTable">
                {UserProfile.position === "Associate" ? null : (
                  <td>
                    <input
                      type="checkbox"
                      // value={item.select}
                      value={item.name}
                      // checked={reloadChecked ? checked : null}
                      checked={clearCheck ? false : checked[item.id]}
                      onChange={(event) =>
                        changeCheck(item.id, item, event, item.quantity)
                      }
                    />
                  </td>
                )}
                <td>
                  <img
                    src={item.photo}
                    alt=""
                    className="phontoProduct"
                  />
                </td>

                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => openModal(item.id)}
                  className="buttonModal"
                >
                  {item.name}
                </td>

                {/*<td   className={testState[item.id]?'tolTip':'defultStylyTd'}>*/}
                <td className="defultStylyTd">
                  {testState[item.id] ? (
                    <>
                      {productArr.map((item, index) => (
                        <div
                          key={index}
                          className={testState[item.id] ? "tolTip" : "none"}
                          onMouseLeave={() => allCategory(item.id)}
                        >
                          {item.categories.map((multiCategory, index) => {
                            if (testState[item.id]) {
                              return (
                                <p key={index}>{multiCategory.name}</p>
                              );
                            }
                          })}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {item.categories.map((multiCategory, i) => {
                        if (i + 1 === 3) {
                          return (
                            <p
                              key={i}
                              onMouseEnter={() => allCategory(item.id)}
                              style={{ cursor: "pointer" }}
                            >
                              ...
                            </p>
                          );
                        } else if (i + 1 > 3) {
                          return null;
                        }
                        return <p key={i}>{multiCategory.name}</p>;
                      })}
                    </>
                  )}
                </td>
                <td className="defultStylyTd">{item.serial_number}</td>
                <td className="defultStylyTd">{item.packing_size}</td>
                <td className="defultStylyTd">{item.packing_type}</td>
                <td className="defultStylyTd">${item.price}</td>
                <td className="defultStylyTd">
                  {item.quantity === 0 ? "no" : item.status ? "yes" : "no"}
                </td>
                <td
                  className={
                    item.quantity === 0
                      ? "color-red"
                      : item.quantity <= 10
                      ? "color-orange"
                      : ""
                  }
                >
                  {item.quantity}
                </td>
                <td className="defultStylyTd">
                  {/*{item.rates.map((item) => (*/}
                  {/*    <>{item.rates}</>*/}
                  {/*))}*/}
                  {item.rate_of_product}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </>
    );
  }
  if (titles === "Archived Products") {
    return (
      <>
        <Modal open={modalQuestion}>
          <Box sx={style}>
            <Typography
              align="center"
              id="modal-modal-title"
              variant="h5"
              component="h1"
            >
              <p style={{ fontFamily: "Open Sans", fontWeight: "700" }}>
                Restore product?
              </p>
            </Typography>
            <Typography
              align="center"
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              {arrDeleteProductName} will be restored
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p
                onClick={() => keep()}
                style={{
                  cursor: "pointer",
                  color: "#25282A",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Cancel
              </p>
              <p
                style={{
                  cursor: "pointer",
                  color: "#000000",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
                onClick={() => restoreProduct()}
              >
                Restore
              </p>
            </div>
          </Box>
        </Modal>
        <div className="titleTableProduct" style={{ width: "100%" }}>
          <div style={{ fontFamily: "Norwester" }}>
            {titles}
            <label className="amoutStyle">({productArrLength})</label>
          </div>
          <div style={{ display: "flex", marginRight: "13.5%" }}>
            {arrDeleteProduct.length > 0 ? (
              <>
                <button
                  style={{ cursor: "pointer" }}
                  className={"buttonDisacrad"}
                  onClick={() => discardSelection()}
                >
                  Discard selection
                  <img src={CloseIcon} alt="" />
                </button>
                <label
                  // onClick={() => addProduct()}
                  onClick={() => openModalQuestion()}
                  style={{
                    cursor: "pointer",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Restore Products
                  <img src={RestoreIcon} alt="" />
                </label>
              </>
            ) : null}
          </div>
        </div>
        <table className="tableBlock" style={{ marginLeft: "60px" }}>
          <thead>
            {UserProfile.position === "Associate" ? (
              <tr>
                {titleTableDisabledRole.map((item) => (
                  <th
                    key={item.id}
                    className="orderTitle"
                    style={{ fontFamily: "Open Sans" }}
                  >
                    {item.title}
                  </th>
                ))}
              </tr>
            ) : (
              <tr>
                {titleTable.map((item, index) => (
                  <th
                    key={index}
                    className="orderTitleArchived"
                    style={{ fontFamily: "Open Sans" }}
                  >
                    {item.title}
                  </th>
                ))}
              </tr>
            )}
          </thead>

          {productArr.map((item) => (
            <tbody key={item.id}>
              <tr className="infoTable">
                {UserProfile.position === "Associate" ? null : (
                  <td>
                    <input
                      disabled={
                        UserProfile.position === "Associate" ? true : false
                      }
                      type="checkbox"
                      // value={item.select}
                      value={item.name}
                      // checked={reloadChecked ? checked : null}
                      checked={clearCheck ? false : checked[item.id]}
                      onChange={(event) =>
                        changeCheck(item.id, item, event, item.quantity)
                      }
                    />
                  </td>
                )}
                <td>
                  <img
                    src={item.photo}
                    alt=""
                    className="phontoProduct"
                  />
                </td>
                <td className="buttonModal">{item.name}</td>
                <td className="defultStylyTd">
                  {testState[item.id] ? (
                    <>
                      {productArr.map((item) => (
                        <React.Fragment key={item.id}>
                          <div
                            className={testState[item.id] ? "tolTip" : "none"}
                            onMouseLeave={() => allCategory(item.id)}
                          >
                            {item.categories.map((multiCategory, i) => {
                              if (testState[item.id]) {
                                return <p>{multiCategory.name}</p>;
                              }
                            })}
                          </div>
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <>
                      {item.categories.map((multiCategory, i) => {
                        if (i + 1 === 3) {
                          return (
                            <p
                              key={i}
                              onMouseEnter={() => allCategory(item.id)}
                              style={{ cursor: "pointer" }}
                            >
                              ...
                            </p>
                          );
                        } else if (i + 1 > 3) {
                          return null;
                        }
                        return <p key={i}>{multiCategory.name}</p>;
                      })}
                    </>
                  )}
                </td>
                <td className="defultStylyTd">{item.serial_number}</td>
                <td className="defultStylyTd">{item.packing_size}</td>
                <td className="defultStylyTd">{item.packing_type}</td>
                <td className="defultStylyTd">${item.price}</td>
                <td className="defultStylyTd">
                  {item.quantity === 0 ? "no" : item.status ? "yes" : "no"}
                </td>
                <td
                  className={
                    item.quantity === 0
                      ? "color-red"
                      : item.quantity <= 10
                      ? "color-orange"
                      : ""
                  }
                >
                  {item.quantity}
                </td>
                <td className="defultStylyTd">{item.rate_of_product}</td>
              </tr>
            </tbody>
          ))}
        </table>
      </>
    );
  }
};
export default TableProduct;
