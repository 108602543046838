import { Link } from "react-router-dom";
import "./styles.scss";

const LinkButton = ({ title, href }) => {
  return (
    <Link className="linkStyle" to="/resetPassword">
      Forgot password?
    </Link>
  );
};
export default LinkButton;
