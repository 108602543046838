import "./styles.scss";

const ModalDeleteUser = ({ discard, deleteUsers, users }) => {
  return (
    <div className="modal_ban">
      <div>
        <p className="title_delete">Selected users ({users}) will be deleted</p>
      </div>
      <p>Proceed?</p>
      <div>
        <button className="delete_discard" onClick={() => discard()}>
          Discard
        </button>

        <button className="delete_Delete" onClick={() => deleteUsers()}>
          Delete users
        </button>
      </div>
    </div>
  );
};
export default ModalDeleteUser;
