import { styled } from "@mui/system";

import { Button, IconButton, TextField, Box, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const CustomArrowBackIcon = styled(ArrowBackIosNewIcon)(() => ({
  color: "#acacac",
  paddingRight: "31px",
  margin: "auto 0",
  "&:hover": {
    color: "#000000",
    cursor: "pointer",
  },
}));

export const SubmitButton = styled(Button)(() => ({
  textTransform: "unset !important",
  color: "#FFFFFF",
  backgroundColor: "#000000",
  padding: "0px",
  gap: "10px",
  width: "351px",
  height: "48px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "56px",

  "&:hover": {
    backgroundColor: "#000000",
  },

  "&.Mui-disabled": {
    backgroundColor: "#CACACA",
    color: "#FFFFFF",
    cursor: "not-allowed",
  },
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  ".MuiInput-input": {
    border: "transparent",
    fontSize: "16px",
    fontWeight: 500,
    outline: "none",
    fontFamily: "Open Sans",
    paddingBottom: "20px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000000",
  },
  "& label": {
    alignSelf: "start",
    color: "#CACACA",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  "& label.Mui-focused": {
    color: "#25282A",
  },
}));

export const SubMenuButton = styled(Button)(() => ({
  textTransform: "unset !important",
  fontWeight: "700",
  fontFamily: "Open Sans",
  fontSize: "16px",
  color: "#8E8E8E",
  backgroundColor: "none",
  padding: "0px",
  height: "37px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    background: "none",
  },
}));

export const ActionButton = styled(IconButton)(() => ({
  textTransform: "unset !important",
  fontWeight: "700",
  fontFamily: "Open Sans",
  fontSize: "16px",
  color: "#8E8E8E",
  backgroundColor: "none",
  padding: "0px",
  display: "flex",
  justifyContent: "center",
  width: "33px",
  height: "33px",
  alignItems: "center",

  "&:hover": {
    background: "none",
  },
}));

export const AssociateBanButton = styled(Button)(() => ({
  textTransform: "unset !important",
  fontWeight: "700",
  fontFamily: "Open Sans",
  fontSize: "12px",
  color: "#E9424D",
  backgroundColor: "rgba(255, 103, 113, 0.15)",
  padding: "0px",
  height: "24px",
  width: "90px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",

  "&:hover": {
    backgroundColor: "rgba(255, 103, 113, 0.15)",
  },
}));
export const AssociateUnbanButton = styled(Button)(() => ({
  textTransform: "unset !important",
  fontWeight: "700",
  fontFamily: "Open Sans",
  fontSize: "12px",
  color: "#0EB115",
  backgroundColor: "rgba(16, 200, 23, 0.15)",
  padding: "0px",
  height: "24px",
  width: "90px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",

  "&:hover": {
    backgroundColor: "rgba(16, 200, 23, 0.15)",
  },
}));

export const SortLabelTypography = styled(Typography)(() => ({
  width: "90px",
  height: "24px",
  borderRadius: "4px",
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "16px",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));

export const SortModalBox = styled(Box)(() => ({
  position: "fixed",
  right: "25%",
  top: "15%",
  padding: "10px",
  boxShadow: "0px 3px 15px rgba(186, 186, 186, 0.5)",
  borderRadius: "6px",
  background: "#ffffff",
}));
