import "./styles.scss";
import TableAssociates from "../../components/table/table-associates";
import SortImg from "../../assets/sort.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getAssociation } from "../../redux/associates/action";
import { useEffect, useState } from "react";
import AddNewAssociate from "../../components/add-new-associate";
import { Box, Modal } from "@mui/material";
import ModalBanSort from "../../components/modalWindow/modal-ban-sort";
import CloseIcon from "@mui/icons-material/Close";

import ModalBan from "../../components/modalWindow/modal-ban";
import ModalDeleteUser from "../../components/modalWindow/modal-delete-user";
import ModalEditAssociate from "../../components/modal-edit-associate";
import ModalBanSelected from "../../components/modalWindow/modal-ban-selected";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SubMenuButton } from "../../shared/styles.styled";
import Title from "../../components/title";

const Associates = () => {
  const UserProfile = useSelector((state) => state.profile.userProfiles);
  const Associations = useSelector((state) => state.userAssociations.users);
  const dispatch = useDispatch();
  const [newAssociates, setNewAssociates] = useState(false);
  const [sortAssociate, setSortAssociate] = useState(false);
  const [clearCheck, setClearCheck] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedUser, setSelectedUser] = useState([{ selectNum: 0 }]);
  const [modalBan, setModalBan] = useState(false);
  const [statusBan, setStatusBan] = useState([]);
  const [blockSelect, setBlockSelect] = useState([]);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [profileAssociates, setProfileAssociates] = useState();
  const [editAssociateModal, setEditAssociateModal] = useState(false);
  const [disabledBan, setDisabledBan] = useState(true);
  const [allBan, setAllBan] = useState(false);

  useEffect(() => {
    dispatch(getAssociation(UserProfile.accessToken));
  }, []);
  const changeAssociate = (name, id, event, status) => {
    if (event.target.checked === true) {
      let data = {
        name: name,
        id: id,
      };
      setSelectedUser([...selectedUser, selectedUser[0].selectNum++]);
      setSelectedUser([...selectedUser, data]);
      setBlockSelect([...blockSelect, status]);
    }
    if (event.target.checked === false) {
      setSelectedUser([...selectedUser, selectedUser[0].selectNum--]);
      let newArry = selectedUser.filter((item) => item.id !== id);
      setSelectedUser(newArry);

      let indexStatus = blockSelect.indexOf(status);
      blockSelect.splice(indexStatus, 1);
    }
  };
  useEffect(() => {
    const block = blockSelect.every((elem) => elem === blockSelect[0]);
    if (!block) {
      setDisabledBan(false);
    } else {
      setDisabledBan(true);
    }
  }, [selectedUser]);

  const deleteUsers = () => {
    const idUser = selectedUser
      .filter((item) => item.id)
      .map((item) => item.id);
    const data = {
      usersIds: idUser,
    };
    dispatch(deleteUser(UserProfile.accessToken, data));

    setDeleteUserModal(false);
    discard();
    setTimeout(() => {
      dispatch(getAssociation(UserProfile.accessToken));
    }, 2000);
  };
  const discard = () => {
    setSelectedUser([{ selectNum: 0 }]);
    setClearCheck(true);
    setDeleteUserModal(false);
    setBlockSelect([]);
    setTimeout(() => {
      setClearCheck(false);
    }, 500);
  };
  const changeAssociateStatus = (status, userId) => {
    let data = {
      status: status,
      id: userId,
    };
    setStatusBan(data);
    setModalBan(true);
  };
  const editProfile = (idUser) => {
    const changeAssociate = Associations.users.filter(
      (item) => item.userId === idUser
    );
    setProfileAssociates(...changeAssociate);
    setEditAssociateModal(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  const styleEdit = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal open={newAssociates}>
        <Box sx={style}>
          <AddNewAssociate
            setNewAssociates={setNewAssociates}
            newAssociates={newAssociates}
            token={UserProfile.accessToken}
          />
        </Box>
      </Modal>
      {sortAssociate ? (
        <ModalBanSort
          disabled={sortAssociate}
          setSortAssociate={setSortAssociate}
          token={UserProfile.accessToken}
        />
      ) : null}
      {modalBan ? (
        <ModalBan
          setModalBan={setModalBan}
          status={statusBan}
          token={UserProfile.accessToken}
        />
      ) : null}
      {deleteUserModal ? (
        <ModalDeleteUser
          deleteUsers={deleteUsers}
          discard={discard}
          users={selectedUser[0].selectNum}
        />
      ) : null}
      {allBan ? (
        <ModalBanSelected
          discard={discard}
          blockSelect={blockSelect}
          setAllBan={setAllBan}
          status={selectedUser}
          token={UserProfile.accessToken}
        />
      ) : null}
      <Modal open={editAssociateModal}>
        <Box sx={styleEdit}>
          <ModalEditAssociate
            profileAssociates={profileAssociates}
            token={UserProfile.accessToken}
            setEditAssociateModal={setEditAssociateModal}
          />
        </Box>
      </Modal>

      <div className="associates_body">
        <div className="associates_head">
          <Title title={"Associates"} number={Associations.users?.length} />
          {selectedUser[0].selectNum === 0 ? (
            <div className="associates_head_right_block">
              <SubMenuButton
                underline="none"
                sx={{ color: "#25282A" }}
                endIcon={
                  <img
                    src={SortImg}
                    alt=""
                    style={{ height: "15px", marginLeft: "5px" }}
                  />
                }
                onClick={() => setSortAssociate(true)}
              >
                Sort
              </SubMenuButton>
              <SubMenuButton
                underline="none"
                sx={{ color: "#25282A" }}
                endIcon={<AddIcon />}
                onClick={() => setNewAssociates(!newAssociates)}
              >
                Add new associate
              </SubMenuButton>
            </div>
          ) : (
            <div className="associates_head_right_block">
              <SubMenuButton
                underline="none"
                sx={{ color: "#8E8E8E" }}
                endIcon={<CloseIcon />}
                onClick={() => discard()}
              >
                Discard selection
              </SubMenuButton>
              <SubMenuButton
                underline="none"
                sx={{ color: "#E9424D" }}
                endIcon={<DeleteOutlineIcon />}
                onClick={() => setDeleteUserModal(true)}
              >
                Delete selected ({selectedUser[0].selectNum})
              </SubMenuButton>
              {disabledBan ? (
                <div
                  className={
                    !blockSelect[0]
                      ? "selected_ban_style"
                      : "selected_ban_style_green"
                  }
                  onClick={() => setAllBan(true)}
                >
                  {!blockSelect[0] ? "Ban selected" : "Unban selected"}
                </div>
              ) : null}
            </div>
          )}
        </div>

        <TableAssociates
          clearCheck={clearCheck}
          checked={checked}
          users={Associations.users}
          changeAssociate={changeAssociate}
          changeAssociateStatus={changeAssociateStatus}
          editProfile={editProfile}
          selectedUser={selectedUser}
        />
      </div>
    </div>
  );
};
export default Associates;
