import * as types from "./types";

const initialState = {
  userProfiles: {},
  updatePhone: {},
  error:{}
};
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_INFO:
      return { ...state, userProfiles: action.payload };
    case types.DELETE_USER_INFO:
      return { ...state, userProfiles: {} };
    case types.CLEAR_UPDATE_PHONE:
      return { ...state, updatePhone: {} };
    case types.UPDATE_USER_INFO:
      return { ...state, userProfiles: action.payload };
    case types.UPDATE_PHONE_USER:
      return { ...state, updatePhone: action.payload };
    case types.ERROR_PHONE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
