import "./styles.scss";
import { useDispatch } from "react-redux";
import { ban, getAssociation } from "../../../redux/associates/action";

const ModalBan = ({ status, setModalBan, token }) => {
  const dispatch = useDispatch();
  const changeBan = () => {
    if (status.status) {
      const urlBan = "user/unban-associate-user";

      let data = {
        usersIds: [status.id],
      };
      dispatch(ban(token, data, urlBan));
      setModalBan(false);
      setTimeout(() => {
        dispatch(getAssociation(token));
      }, 2000);
    } else {
      const urlBan = "user/ban-associate-user";

      let data = {
        usersIds: [status.id],
      };
      setModalBan(false);
      dispatch(ban(token, data, urlBan));
      setTimeout(() => {
        dispatch(getAssociation(token));
      }, 2000);
    }
  };
  return (
    <div className="modal_ban">
      <div className="title_ban_unban">
        {status.status ? (
          <p>This user will be unbanned</p>
        ) : (
          <p>This user will be banned</p>
        )}
      </div>
      <p>Proceed?</p>
      <div>
        <button
          className="discard_style_ban"
          onClick={() => setModalBan(false)}
        >
          Discard
        </button>
        {status.status ? (
          <button className="style_Unban" onClick={() => changeBan()}>
            Unban user
          </button>
        ) : (
          <button className="style_ban" onClick={() => changeBan()}>
            Ban user
          </button>
        )}
      </div>
    </div>
  );
};
export default ModalBan;
