import styled from "@emotion/styled";
import { Box } from "@mui/system";

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const Form = ({ handleSubmit, children, onSubmit, sx }) => {
  return (
    <FormBox sx={sx} component="main">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormBox>
  );
};

export default Form;
