import * as types from "../user/types";

export const getUser = (token,userId) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/user-profile?${userId}`, {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
          .then((res) => res.json())
          .then((data) => {
            if (data.httpStatus >= 400) {
            } else {
              dispatch(setUserProfile(data));

            }
          });
    } catch (e) {}

  };
};
export const updateProfileUser = (
  data,
  token,
  setCorrectCode,
  setDisabledCode,
  setEditProfile,
  setCode,
) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/user-profile`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            dispatch(error_phone(data));
            setCorrectCode(true);
            setTimeout(() => {
              setCorrectCode(false);
            }, 2000);
            return data;
          } else {
            dispatch(error_phone({}));
            dispatch(setUserProfile(data));
            dispatch(upDateUserProfile(data));
            setDisabledCode(false);
            setEditProfile(false);
            setCode("");
          }
        });
    } catch (e) {}
  };
};
export const updatePhoneUser = (data, token) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/is-phone-valid`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
          } else {
            dispatch(upDatePhone(data));

            setTimeout(()=>{
              dispatch(upDateUserProfile(data));

            },2000)
          }
        });
    } catch (e) {}
  };
};
export const updatePhoneUserNewPhone = (data, token) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/is-phone-valid`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
          .then((res) => res.json())
          .then((data) => {
            if (data.httpStatus >= 400) {
            } else {
              dispatch(upDatePhone(data));

            }
          });
    } catch (e) {}
  };
};
export const setUserProfile = (user) => ({
  type: types.GET_USER_INFO,
  payload: user,
});
export const upDateUserProfile = (user) => ({
  type: types.UPDATE_USER_INFO,
  payload: user,
});
export const logoutsUser = (user) => ({
  type: types.DELETE_USER_INFO,
  payload: user,
});
export const upDatePhone = (phone) => ({
  type: types.UPDATE_PHONE_USER,
  payload: phone,
});
export const clearPhone = (phone) => ({
  type: types.CLEAR_UPDATE_PHONE,
  payload: phone,
});
export const error_phone = (error) => ({
  type: types.ERROR_PHONE,
  payload: error,
});
