import "./App.css";

import Strip from "./router/strip";

function App() {
  console.log(process.env.REACT_APP_API_URL);
  return (
    <div className="App">
      <Strip />
    </div>
  );
}

export default App;
