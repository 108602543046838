import iconBack from "../../assets/imageLoginPage/backIcon.png";
import "./styles.scss";
import React from "react";
import TextField from "../../components/text-field";
import Alert from "@mui/material/Alert";
import { useEffect, useState } from "react";
import PhotoProduct from "../../components/photo-product";
import Button from "../../components/new-button";
import { useDispatch, useSelector } from "react-redux";
import { createNewProducts, getCategories } from "../../redux/products/action";
import SelectTypeOption from "../../components/select-type-option";
import { Box, Modal } from "@mui/material";
import MultiSelect from "../../components/multi-select";
import { serialize } from 'object-to-formdata';

const AddNewProduct = ({ setPageAddProduct, addProductComplete }) => {
  const [imageFile, setImageFile] = useState(null);
  const [productName, setProductName] = useState("");
  const [serialNum, setSerialNum] = useState("");
  const [packageType, setPackageType] = useState("Bottled");
  const [costPer, setCostPer] = useState("");
  const [arrayCategories, setArrayCategories] = useState([]);
  const [validDescription, setValidDescription] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [validCost, setValidCost] = useState(false);
  const [validQuantity, setValidQuantity] = useState(false);
  const [isAvailableForSale, setIsAvailableForSale] = useState(true);
  // const [allValid, setAllValid] = useState(true);
  const [sale, setSale] = useState(false);
  const dispatch = useDispatch();
  const User = useSelector((state) => state.profile.userProfiles);
  const [newImageDisabled, setNewImageDisabled] = useState(true);
  const statusSale = (e) => {
    setSale(e.target.value);
  };
  const changeProduct = (e) => {
    setProductName(e.target.value);
  };

  useEffect(() => {
    let regex = /^[a-zA-Z][^0-9]{1,28}$/;
    const result = regex.test(productName);
    if (result === true && productName.length !== 0) {
      setValidName(false);
    } else setValidName(true);
    if (productName.length === 0) {
      setValidName(false);
    }
  }, [productName]);
  const changeSerialNum = (e) => {
    setSerialNum(e.target.value);
  };
  const changePackageType = (e) => {
    setPackageType(e.target.value);
  };

  const changeCostPer = (e) => {
    setCostPer(e.target.value);
  };

  useEffect(() => {
    if (costPer.length !== 0) {
      const regex = /^\d+(\.\d{1,2})?$/;
      const isValid = regex.test(costPer);

      setValidCost(!isValid);
    }
  }, [costPer]);

  // const [category, setCategory] = useState("");
  const [packSize, setPackSize] = useState("16 oz");
  const [description, setDescription] = useState("");
  const [quantityAvailable, setQuantityAvailable] = useState("");
  const [changeActive, setChangeActive] = useState(true);
  const categoriesResponse = useSelector(
    (state) => state.product.responseCategories
  );
  const isFetchError = useSelector((state) => state.product.isFetchError);
  const isFetchSuccess = useSelector((state) => state.product.isFetchSuccess);
  const [successfully, setSuccessfully] = useState(false);

  const back = () => {
    setDisabled(true);
    if (
      productName.length > 0 ||
      serialNum.length > 0 ||
      description.length > 0 ||
      quantityAvailable.length > 0 ||
      costPer.length > 0 ||
      packageType !== "Bottled" ||
      packSize !== "16 oz"
    ) {
      setOpenModalQuestion(true);
    } else setPageAddProduct(false);
  };
 
  const changePackSize = (e) => {
    setPackSize(e.target.value);
  };
  const changeDescription = (e) => {
    setDescription(e.target.value);
    if (description.length > 100) {
      setValidDescription(true);
    } else setValidDescription(false);
  };
  const changeQuantityAvailable = (e) => {
    setQuantityAvailable(e.target.value);
    const regex = /^\d+$/;
    const isValid = regex.test(e.target.value);
    setIsAvailableForSale(isValid && Number(e.target.value) !== 0);
  };

  useEffect(() => {
    if (quantityAvailable.length !== 0) {
      const regex = /^\d+$/;
      const isValid = regex.test(quantityAvailable);

      setValidQuantity(!isValid);
    }
  }, [quantityAvailable]);

  const textFieldLeft = [
    { name: "Product name", changeValue: changeProduct, value: productName },
    { name: "Serial number", changeValue: changeSerialNum, value: serialNum },
    // {
    //   name: "Package type",
    //   changeValue: changePackageType,
    //   value: packageType,
    //
    // },
    { name: "Cost per item", changeValue: changeCostPer, value: costPer },
  ];

  const textFieldRight = [
    // { name: "Category", changeValue: changeCategory, value: category },
    { name: "Package size", changeValue: changePackSize, value: packSize },
    { name: "Description", changeValue: changeDescription, value: description },
    {
      name: "Quantity available",
      changeValue: changeQuantityAvailable,
      value: quantityAvailable,
    },
  ];
  // useEffect(() => {
  //   setCategory(categoriesResponse.category[0]?.id);
  // }, []);

  const addNewProduct = async (event) => {
    const data = {
      photo: imageFile,
      productName: productName,
      serialNumber: serialNum,
      packingType: packageType,
      price: Number(costPer),
      packingSize: packSize,
      description: description,
      quantity: Number(quantityAvailable),
      status: Boolean(!sale),
      categoryIds: arrayCategories.map((category) => category.id),
    };

    setChangeActive(true);

    try {
      await dispatch(createNewProducts(User.accessToken, User.userId, serialize(data)));

      setSuccessfully(true);
      addProductComplete();
    } catch (error) {
      console.error(error);
      setSuccessfully(false);
    }
  };

  const [image64Valid, setImage64Valid] = useState(true);
  const onChangeImage = (e) => {
    const files = e.target.files;
    const file = files[0];

    let image = document.getElementById("output");
    image.src = URL.createObjectURL(e.target.files[0]);
    if (image !== undefined) {
      setNewImageDisabled(false);
    } else setNewImageDisabled(true);

    if (file.size > 5 * 1024 * 1024) {
      setImage64Valid(false);
      setImageFile(null);
    } else {
      setImage64Valid(true);
      setImageFile(file);
    }
  };

  useEffect(() => {
    dispatch(getCategories(User.accessToken, User.userId));
    // setTimeout(() => {
    //   setAllValid(false);
    // }, 2000);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  const [disabled, setDisabled] = useState(true);
  const [openModalQuestion, setOpenModalQuestion] = useState(false);
  const leave = () => {
    setPageAddProduct(false);
    setImageFile(null);
  };

  useEffect(() => {
    if (
      arrayCategories.length !== 0 &&
      productName.length !== 0 &&
      costPer.length !== 0 &&
      description.length !== 0 &&
      quantityAvailable.length !== 0 &&
      imageFile !== null &&
      !validCost &&
      !validName &&
      !validDescription &&
      !validQuantity
    ) {
      setChangeActive(false);
    } else setChangeActive(true);
  }, [
    productName,
    imageFile,
    costPer,
    serialNum,
    description,
    quantityAvailable,
    validCost,
    validName,
    validDescription,
    validQuantity,
    arrayCategories,
  ]);

  useEffect(() => {
    if (validCost) {
      setChangeActive(true);
    }
    if (validQuantity) {
      setChangeActive(true);
    }
  }, [validCost, validQuantity]);

  const stay = () => {
    setOpenModalQuestion(false);
    setDisabled(false);
  };
  const changeCategories = (nameCategories) => {
    let item = {
      id: nameCategories,
    };
    setArrayCategories((prev) => [...prev, item]);

    const names = arrayCategories.map((el) => el.id);

    let deleteItem = names.indexOf(nameCategories);
    if (deleteItem !== -1) {
      let newArray = arrayCategories.filter(
        (item) => item.id !== nameCategories
      );
      setArrayCategories(newArray);
    }
  };
  return (
    <>
      {isFetchError && !successfully && (
        <Alert severity="error">
          Oops...Something went wrong! Reload the page.
        </Alert>
      )}
      {isFetchSuccess && successfully && (
        <Alert severity="success">
          The product has been successfully added.
        </Alert>
      )}
      <>
        <Modal open={openModalQuestion}>
          <Box sx={style}>
            <div>
              <p
                style={{ cursor: "pointer" }}
                className="exitModalAdd"
                onClick={stay}
              >
                x
              </p>
              <p
                style={{
                  fontFamily: "Open Sans",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Unsaved progress will be lost
              </p>
              <p style={{ fontFamily: "Open Sans", textAlign: "center" }}>
                Leave the page?
              </p>
              <div
                style={{
                  fontFamily: "Open Sans",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  fontWeight: 700,
                }}
              >
                <p onClick={() => stay()} style={{ cursor: "pointer" }}>
                  Stay
                </p>
                <p
                  onClick={() => leave()}
                  style={{ color: "red", cursor: "pointer" }}
                >
                  Leave
                </p>
              </div>
            </div>
          </Box>
        </Modal>
        <div className="header">
          <img onClick={() => back()} src={iconBack} alt="" />
          <p>Add new product</p>
        </div>
        <div className="body">
          <PhotoProduct
            setDisabled={setDisabled}
            disabled={disabled}
            onChangeImage={onChangeImage}
            title="Product Photo"
            state={newImageDisabled}
          />

          <div className="textField">
            <div>
              {textFieldLeft.map((item, index) => (
                <React.Fragment key={index}>
                  <TextField
                    // key={item}
                    changeValue={item.changeValue}
                    disabled={true}
                    styleInput={"inputEdit"}
                    labelStyle={"labelEdit"}
                    blockStyle={"inputLabelEdit"}
                    label={item.name}
                    value={item.value}
                    validate={item.validate}
                  />
                </React.Fragment>
              ))}
              <SelectTypeOption
                changePackageType={changePackageType}
                title={"addProduct"}
              />
            </div>

            <div className="rightBlock">
              <div style={{ marginTop: "11%", paddingLeft: "40px" }}>
                <MultiSelect changeCategories={changeCategories} />
              </div>

              {textFieldRight.map((item, index) => (
                <React.Fragment key={index}>
                  <TextField
                    changeValue={item.changeValue}
                    disabled={true}
                    styleInput={"inputEdit"}
                    labelStyle={"labelEdit"}
                    blockStyle={"inputLabelEdit"}
                    label={item.name}
                    value={item.value}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="checkBox">
          <input
            type="checkbox"
            onChange={(e) => statusSale(e)}
            disabled={!isAvailableForSale}
            style={{
              opacity: isAvailableForSale ? 1 : 0.5,
              cursor: isAvailableForSale ? "pointer" : "not-allowed",
            }}
          />
          <p
            style={{
              opacity: isAvailableForSale ? 1 : 0.5,
            }}
          >
            Product is not available for sale
          </p>
        </div>

        <div className="validStyle">
          {validName ? (
            <p>
              Letters, spaces, apostrophes, and dashes are allowed or product
              name more than 30 symbols
            </p>
          ) : null}
          {validNumber ? <p>Only digits are allowed</p> : null}
          {validCost ? <p>Only digits are allowed.</p> : null}
          {validDescription ? (
            <p>Description must be shorter than or equal to 100 characters</p>
          ) : null}
          {validQuantity ? <p>Only digits are allowed.</p> : null}
          {!image64Valid ? (
            <p style={{ color: "red" }}>
              Too big file size, image size should be less than 5MB
            </p>
          ) : null}
        </div>

        <div style={{ marginLeft: "290px" }}>
          <Button
            disabled={changeActive}
            typeAction={"profileButtonVisible"}
            typeNoActive={"profileActive"}
            type={
              changeActive ? "saveChangeProduct" : "saveChangeActiveProduct"
            }
            value={"Add new product"}
            click={addNewProduct}
          />
        </div>
      </>
    </>
  );
};
export default AddNewProduct;
