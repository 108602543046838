import { AuthContainer } from "../../components/auth-container";
import AuthTitle from "../../components/auth-title";
import InputPassword from "../../components/componentTools/inputPassword";
import { useEffect, useState } from "react";
import ModalWindow from "../../components/modalWindow/modalWindow";
import Button from "../../components/new-button";
import { useDispatch, useSelector } from "react-redux";
import {
  newPasswordRespon,
  resetCodeResponse,
  resetNewPassword,
  resetPhone,
} from "../../redux/auth/actions";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../components/icon/back-icon";
import ModalQuestion from "../../components/modalWindow/modal-question";
import { Box, Modal } from "@mui/material";

const NewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibl, setVisibl] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { codeResponse, newPasswordResponse, phone, errorMessage } =
    useSelector((state) => state.auth);

  const changeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  const changeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  const finishClick = () => {
    if (newPassword === confirmPassword) {
      setError(false);
      setVisibl(true);
      dispatch(
        newPasswordRespon(codeResponse.accessToken, {
          newPassword: newPassword,
          userId: phone.userId,
        })
      );
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (visibl === true) {
      if (newPasswordResponse.isPasswordReset === true) {
        setVisibl(false);
        dispatch(resetNewPassword());
        dispatch(resetCodeResponse());
        dispatch(resetPhone());
        navigate("/");
      } else {
        setVisibl(false);
      }
    }
    if (errorMessage) {
      setVisibl(false);
    }
  }, [newPasswordResponse, errorMessage]);
  const stay = () => {
    if (visible === true) {
      setVisible(false);
    }
  };
  const backLogin = () => {
    dispatch(resetNewPassword());
    dispatch(resetCodeResponse());
    dispatch(resetPhone());
    navigate("/");
  };
  const backPage = () => {
    if (visible === false) {
      setVisible(true);
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <AuthContainer>
      <Modal open={visibl}>
        <Box sx={style}>
          <ModalWindow
            visibl={visibl}
            title="Success!"
            loadText="Redirecting..."
          />
        </Box>
      </Modal>
      <ModalQuestion
        visible={visible}
        setVisible={setVisible}
        stay={stay}
        backLogin={backLogin}
        title="Go back to log in?"
        question="Unsaved progress will be lost"
        answerOne="Stay"
        answerTwo="Back to log in"
      />
      <AuthTitle title={"New password "} />
      <BackIcon backPage={backPage} />
      <InputPassword
        label="New password"
        passwordValue={newPassword}
        changePassword={changeNewPassword}
      />

      <InputPassword
        passwordValue={confirmPassword}
        changePassword={changeConfirmPassword}
        label="Confirm new password"
      />
      {error ? (
        <p style={{ color: "red", fontSize: "14px" }}>password don't match</p>
      ) : null}
      {errorMessage ? (
        <p style={{ color: "red", fontSize: "14px" }}>
          {errorMessage.errorMessage}
        </p>
      ) : null}

      <Button
        value={"Finish"}
        click={finishClick}
        typeAction={"buttonLogIn"}
        typeNoActive={"activeButtonLogIn"}
      />
    </AuthContainer>
  );
};
export default NewPassword;
