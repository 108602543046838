import "./styles.scss";

const Button = ({ value, type, click, disabled, typeAction, typeNoActive }) => {
  return (
    <button
      disabled={disabled}
      className={`${type} ${disabled ? `${typeAction}` : `${typeNoActive}`}`}
      onClick={() => click()}
      style={{ cursor: !disabled ? "pointer" : "default" }}
    >
      <span>{value}</span>
    </button>
  );
};
export default Button;
