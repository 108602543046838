import "./styles.scss";
import { useEffect, useState } from "react";
import TextFieldProduct from "../../text-field-product";
import Button from "../../new-button";
import DeleteIcon from "../../../assets/products/garbage.png";
import EditIcon from "../../../assets/profile/edit.png";
import CommentUser from "../../comment-user";
import Ratings from "../../ratings";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getAllProducts,
  updateProducts,
} from "../../../redux/products/action";
import { Box, Modal, Typography } from "@mui/material";
import React from "react";

import SelectTypeOption from "../../select-type-option";
import MultiSelect from "../../multi-select";
import Upload from "../../../assets/products/upload.png";
import { serialize } from "object-to-formdata";

const ModalInfoProduct = ({
  id,
  info,
  activeModal,
  token,
  userId,
  rates,
  categoriesItem,
}) => {
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState(null);
  const [validCategories, setValidCategories] = useState(false);
  const User = useSelector((state) => state.profile.userProfiles);
  const [editProduct, setEditProduct] = useState(false);
  const [nameProduct, setNameProduct] = useState(info.name);
  const [priceProduct, setPriceProduct] = useState(info.price);

  const [multiCategories, setMultiCategories] = useState(info.categories);

  const [arrayCategories, setArrayCategories] = useState([]);

  const [errorMessageProduct, setErrorMessageProduct] = useState();
  const [numberProduct, setNumberProduct] = useState(info.serial_number);
  const [quantityProduct, setQuantityProduct] = useState(info.quantity);
  const [sizeProduct, setSizeProduct] = useState(info.packing_size);
  const [typeProduct, setTypeProduct] = useState(info.packing_type);
  // const [statusSale, setStatusSale] = useState(info.status ? true : false);

  const [validateSize, setValidateSize] = useState(false);
  const [validateType, setValidateType] = useState(false);
  const [descriptionProduct, setDescriptionProduct] = useState(
    info.description
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [oldStatusSale, setOldStatusSale] = useState(
    info.status ? true : false
  );
  // const [infoRate, setInfoRate] = useState(info.rates);
  const [validDescription, setValidDescription] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [validCost, setValidCost] = useState(false);
  const [validQuantity, setValidQuantity] = useState(false);
  const [stateDiscard, setStateDiscard] = useState(false);
  useEffect(() => {
    setArrayCategories(categoriesItem);
  }, []);

  const changeName = (e) => {
    setNameProduct(e.target.value);
  };

  const changePrice = (e) => {
    setPriceProduct(e.target.value);
  };
  const changeNumber = (e) => {
    setNumberProduct(e.target.value);
  };
  const changeQuantity = (e) => {
    setQuantityProduct(e.target.value);
  };
  const changeSize = (e) => {
    setSizeProduct(e.target.value);
  };
  const changeType = (e) => {
    setTypeProduct(e.target.value);
  };
  const changeDescription = (e) => {
    setDescriptionProduct(e.target.value);
  };

  const saveChanges = () => {
    if (imageFile === null) {
      const dataNoPhoto = {
        productName: nameProduct,
        photo: imageFile,
        packingSize: sizeProduct,
        serialNumber: numberProduct,
        packingType: typeProduct,
        description: descriptionProduct,
        price: Number(priceProduct),
        quantity: Number(quantityProduct),
        status: Boolean(oldStatusSale),
        categoryIds: arrayCategories.map((category) => category.id),
      };
      setEditProduct(false);
      activeModal();

      dispatch(
        updateProducts(User.accessToken, User.userId, info.id, serialize(dataNoPhoto))
      );

      setOldStatusSale(info.status ? true : false);
    } else {
      const data = {
        productName: nameProduct,
        photo: imageFile,
        packingSize: sizeProduct,
        serialNumber: numberProduct,
        packingType: typeProduct,
        description: descriptionProduct,
        price: Number(priceProduct),
        quantity: quantityProduct,
        status: Boolean(oldStatusSale),
        categoryIds: arrayCategories.map((category) => category.id),
      };
      setEditProduct(false);
      activeModal();

      dispatch(updateProducts(User.accessToken, User.userId, info.id, serialize(data)));
    }
  };
  const [imgHidden, setImgHidden] = useState(false);
  const [image64Valid, setImage64Valid] = useState(false);

  const onChangeImage = (e) => {
    const files = e.target.files;
    const file = files[0];
    let image = document.getElementById("output");
    image.src = URL.createObjectURL(e.target.files[0]);

    if (file.size > 5 * 1024 * 1024) {
      setImage64Valid(true);
      setImageFile(null);
    } else {
      setImage64Valid(false);
      setImageFile(file);
    }

    setImgHidden(true);
  };

  const addNewBatchModal = () => {
    setNewAmount();
    setOpenModal(true);
    if (openModal === true) {
      setOpenModal(false);
    }
  };

  const DeleteBatchModal = () => {
    setModalDeleteBatch(true);
  };

  const deleteProductInfo = (id, name) => {
    setNameProductDelete(name);
    setModalQuestionDelete(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  const [openModal, setOpenModal] = useState(false);
  const [newAmount, setNewAmount] = useState();
  const discard = () => {
    setNewAmount("");
  };

  const saleChange = (e) => {
    if (oldStatusSale === false) {
      setOldStatusSale(true);
    } else {
      setOldStatusSale(false);
    }
  };

  const discardEditProduct = () => {
    if (stateDiscard) {
      setStateDiscard(false);
    } else {
      setStateDiscard(true);
    }
    setNameProduct(info.name);
    setNumberProduct(info.serial_number);
    setQuantityProduct(info.quantity);
    setSizeProduct(info.packing_size);
    setTypeProduct(info.packing_type);
    setDescriptionProduct(info.description);
    setOldStatusSale(info.status);
    setPriceProduct(info.price);
    // setCategoriesProduct(info.categories.name)
    // setCategoriesNew(info.categories.name);
    setImgHidden(false);
    setValidCategories(false);
    setImage64Valid(false);
    setImageFile(null);    
    // setCategory(info.categories.name);
    setTimeout(() => {
      setDisabledSave(true);
    }, 500);
  };
  const leave = () => {
    setNameProduct(info.name);
    setNumberProduct(info.serial_number);
    setQuantityProduct(info.quantity);
    setSizeProduct(info.packing_size);
    setTypeProduct(info.packing_type);
    setDescriptionProduct(info.description);
    setPriceProduct(info.price);
    setOldStatusSale(info.status ? true : false);
    setModalStay(false);
    setEditProduct(false);
    setImgHidden(false);
    setValidCategories(false);
    setTimeout(() => {
      setDisabledSave(true);
    }, 500);
  };
  const [modalStay, setModalStay] = useState(false);

  const closeEditProduct = () => {
    if (
      // arrayCategories.length!==categoriesItem.length||
      imageFile !== null ||
      nameProduct !== info.name ||
      sizeProduct !== info.packing_size ||
      numberProduct !== info.serial_number ||
      typeProduct !== info.packing_type ||
      descriptionProduct !== info.description ||
      oldStatusSale !== info.status ||
      validCategories === true
    ) {
      setModalStay(true);
    } else {
      setEditProduct(false);
    }
  };
  // const responseUpdateProducts = useSelector(
  //   (state) => state.product.responseUpdateProducts
  // );
  const addQuantity = () => {
    let newQuantityProduct = Number(
      Number(quantityProduct) + Number(newAmount)
    );
    const data = {
      productName: nameProduct,
      photo: imageFile,
      packingSize: sizeProduct,
      serialNumber: numberProduct,
      packingType: typeProduct,
      description: descriptionProduct,
      price: priceProduct,
      quantity: newQuantityProduct,
      categoryIds: arrayCategories.map((category) => category.id),
    };
    dispatch(updateProducts(User.accessToken, User.userId, info.id, serialize(data)));
    setQuantityProduct(newQuantityProduct);

    setTimeout(() => {
      setNewAmount();
    }, 900);

    addNewBatchModal();
  };
  const [modalDeleteBatch, setModalDeleteBatch] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);

  const deleteBatch = () => {
    if (quantityProduct < newAmount) {
      let newQuantityProduct = Number(
        Number(quantityProduct) - Number(quantityProduct)
      );
      const data = {
        productName: nameProduct,
        photo: imageFile,
        packingSize: sizeProduct,
        serialNumber: numberProduct,
        packingType: typeProduct,
        description: descriptionProduct,
        price: priceProduct,
        quantity: newQuantityProduct,
        categoryIds: arrayCategories.map((category) => category.id),
      };
      dispatch(
        updateProducts(
          User.accessToken,
          User.userId,
          info.id,
          serialize(data),
          setErrorMessageProduct,
          setQuantityProduct,
          quantityProduct
        )
      );
      setQuantityProduct(newQuantityProduct);
      setModalDeleteBatch(false);
      setNewAmount();
      // setStatusSale(info.status);
    } else {
      let newQuantityProduct = Number(
        Number(quantityProduct) - Number(newAmount)
      );
      const data = {
        productName: nameProduct,
        photo: imageFile,
        packingSize: sizeProduct,
        serialNumber: numberProduct,
        packingType: typeProduct,
        description: descriptionProduct,
        price: priceProduct,
        quantity: newQuantityProduct,
        // status: oldStatusSale,
        categoryIds: arrayCategories.map((category) => category.id),
      };
      dispatch(
        updateProducts(
          User.accessToken,
          User.userId,
          info.id,
          serialize(data),
          setErrorMessageProduct,
          setQuantityProduct,
          quantityProduct
        )
      );
      setQuantityProduct(newQuantityProduct);
      setModalDeleteBatch(false);
      setNewAmount();
      // setStatusSale(info.status);
    }
  };

  useEffect(() => {
    dispatch(getAllProducts(User.accessToken, User.userId));
  }, [quantityProduct]);

  // useEffect(() => {
  //   if (info.rates.length === 0) {
  //     // setInfoRate([{ rate: 0 }]);
  //   } else {
  //     // setInfoRate(info.rates);
  //   }
  // }, []);

  const [disabledAdd, setDisabledAdd] = useState(false);
  const handleNewAmount = (e) => {
    setNewAmount(e.target.value);
    // if(newAmount!==undefined){
    //
    // let regex =  /^\d+$/;
    // const result = regex.test(newAmount);
    // if (result) {
    //
    //   setDisabledAdd(false);
    // } else setDisabledAdd(true);
    // }
  };

  useEffect(() => {
    if (newAmount !== undefined) {
      let regex = /^\d+$/;
      const result = regex.test(newAmount);
      if (result) {
        setDisabledAdd(false);
      } else setDisabledAdd(true);
    }
  }, [newAmount]);

  const [modalQuestionDelete, setModalQuestionDelete] = useState(false);
  const [nameProductDelete, setNameProductDelete] = useState("");
  
  const deleteSelected = () => {
    let data = {
      productIds: [id],
    };
    dispatch(deleteProduct(token, userId, data));
    activeModal();

    if (refreshPage === false) {
      setRefreshPage(true);
    } else setRefreshPage(false);

    setTimeout(() => {
      dispatch(getAllProducts(User.accessToken, User.userId));
    }, 500);
  };

  useEffect(() => {
    let regexName = /^[a-zA-Z0-9 \s -_]{1,28}$/;
    const resultName = regexName.test(nameProduct);
    if (resultName === true && nameProduct.length > 1) {
      setValidName(false);
    } else setValidName(true);

    let regexPrice = /^\d?.+$/;
    const resultPrice = regexPrice.test(priceProduct);
    if (resultPrice === true) {
      setValidCost(false);
    } else setValidCost(true);

    if (descriptionProduct.length > 100) {
      setValidDescription(true);
    } else setValidDescription(false);
    // let regex = /^\d+$/ ;
    // const result = regex.test(numberProduct);
    // if (result === true) {
    //   setValidNumber(false);
    // } else setValidNumber(true);
    let regex = /^\d+$/;
    const result = regex.test(numberProduct);
    
    if (numberProduct?.length > 0) {
      setValidNumber(false);
    } else setValidNumber(true);

    let regexSize = /^[a-zA-Z0-9 \s -_]{1,28}$/;
    const resultSize = regexSize.test(sizeProduct);
    if (resultSize === true && sizeProduct.length >= 0) {
      setValidateSize(false);
    } else setValidateSize(true);
  }, [
    nameProduct,
    priceProduct,
    descriptionProduct,
    numberProduct,
    sizeProduct,
  ]);

  useEffect(() => {
    if (
      nameProduct.length !== 0 ||
      priceProduct.length !== 0 ||
      descriptionProduct.length !== 0 ||
      validCategories === true ||
      // numberProduct!==info.serial_number||
      oldStatusSale !== info.status ||
      sizeProduct !== info.packing_size ||
      typeProduct !== info.packing_type
    ) {
      if (
        !validName &&
        !validDescription &&
        !validCost &&
        !validQuantity &&
        !image64Valid
      ) {
        setDisabledSave(false);
      }
    } else setDisabledSave(true);
    if (nameProduct.length === 0) {
      setDisabledSave(true);
    }
  }, [
    image64Valid,
    typeProduct,
    sizeProduct,
    validName,
    nameProduct,
    priceProduct,
    validCost,
    descriptionProduct,
    validCategories,
    numberProduct,
    oldStatusSale,
    imageFile,
  ]);

  useEffect(() => {
    if (validCost) {
      setDisabledSave(true);
    }
    // if(validNumber){
    //   setDisabledSave(true)
    // }
    if (validDescription) {
      setDisabledSave(true);
    }
    if (validName) {
      setDisabledSave(true);
    }
    if (validateSize) {
      setDisabledSave(true);
    }
    if (validateType) {
      setDisabledSave(true);
    }
    if (image64Valid) {
      setDisabledSave(true);
    }
  }, [
    image64Valid,
    validCost,
    validNumber,
    validName,
    validDescription,
    validateSize,
    validateType,
  ]);

  useEffect(() => {
    setDisabledSave(true);
  }, []);
  const changeCategoriesMulti = (nameCategories) => {
    let item = {
      id: nameCategories,
    };
    setArrayCategories((prev) => [...prev, item]);

    const names = arrayCategories.map((el) => el.id);

    let deleteItem = names.indexOf(nameCategories);
    if (deleteItem !== -1) {
      let newArray = arrayCategories.filter(
        (item) => item.id !== nameCategories
      );
      setArrayCategories(newArray);
    }
  };

  return (
    <div className="modal" style={{ overflowX: "hidden" }}>
      <Modal open={modalQuestionDelete}>
        <Box sx={style}>
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h5"
            component="h1"
          >
            <p style={{ fontFamily: "Open Sans", fontWeight: "700" }}>
              Archive product?
            </p>
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {nameProductDelete} product will be archived
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p
              onClick={() => setModalQuestionDelete(false)}
              style={{
                cursor: "pointer",
                color: "#25282A",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Keep
            </p>
            <p
              style={{
                cursor: "pointer",
                color: "#E9424D",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: "700",
              }}
              onClick={() => deleteSelected()}
            >
              Archive
            </p>
          </div>
        </Box>
      </Modal>
      <Modal open={modalStay}>
        <Box sx={style}>
          <div>
            <p
              className="exitModalAdd"
              style={{ cursor: "pointer" }}
              onClick={() => setModalStay(false)}
            >
              x
            </p>
            <p
              style={{
                fontFamily: "Open Sans",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              Unsaved progress will be lost
            </p>
            <p style={{ fontFamily: "Open Sans", textAlign: "center" }}>
              Leave the page?
            </p>
            <div
              style={{
                fontFamily: "Open Sans",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                fontWeight: 700,
              }}
            >
              <p
                style={{ cursor: "pointer" }}
                onClick={() => setModalStay(false)}
              >
                Stay
              </p>
              <p
                onClick={() => leave()}
                style={{ color: "red", cursor: "pointer" }}
              >
                Leave
              </p>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={modalDeleteBatch} onClose={addNewBatchModal}>
        <Box sx={style}>
          <div className="modalAddBatch">
            <p
              style={{ cursor: "pointer" }}
              className="exitModalAdd"
              onClick={() => setModalDeleteBatch(false)}
            >
              x
            </p>

            <p>Amount of items to delete</p>
            <input
              style={{ outline: "none" }}
              className="inputAddBatch"
              type="text"
              value={newAmount}
              onChange={(e) => handleNewAmount(e)}
            />
            {!disabledAdd ? null : <p style={{ color: "red" }}>only numbers</p>}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "30px",
              }}
            >
              <button onClick={() => discard()} className="discardAmount">
                Discard changes{" "}
              </button>
              <button
                disabled={disabledAdd}
                onClick={() => deleteBatch()}
                className={!disabledAdd ? "addBatch" : "addBatchDisabled"}
              >
                Delete
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={openModal} onClose={addNewBatchModal}>
        <Box sx={style}>
          <div className="modalAddBatch">
            <p className="exitModalAdd" onClick={() => addNewBatchModal()}>
              x
            </p>
            <p>Batch items amount</p>
            <input
              style={{ outline: "none" }}
              className="inputAddBatch"
              type="text"
              value={newAmount}
              onChange={(e) => handleNewAmount(e)}
            />
            {!disabledAdd ? null : <p style={{ color: "red" }}>only numbers</p>}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "30px",
              }}
            >
              <button onClick={() => discard()} className="discardAmount">
                Discard changes{" "}
              </button>
              <button
                disabled={disabledAdd}
                onClick={() => addQuantity()}
                className={!disabledAdd ? "addBatch" : "addBatchDisabled"}
              >
                Add batch
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {editProduct ? (
        <label className="custom-file">
          <img src={Upload} alt="" />
          <input
            style={{ zIndex: 1, position: "absolute", right: 10, opacity: 0 }}
            type="file"
            onChange={onChangeImage}
            className="custom-file-inputNew"
            accept=".jpg,.jpeg,.png"
          />
        </label>
      ) : null}

      {editProduct ? (
        <p className="exit" onClick={() => closeEditProduct()}>
          X
        </p>
      ) : (
        <p className="exit" onClick={() => activeModal(id)}>
          X
        </p>
      )}
      {/*{editProduct ? null : (*/}
      {/*  <p className="exit" onClick={() => activeModal(id)}>*/}
      {/*    X*/}
      {/*  </p>*/}
      {/*)}*/}
      <div className="headers">
        {/*<img src={info.photo}  className="photo" alt="" />*/}
        <img
          src={info.photo}
          className={imgHidden ? "hidden" : "photo"}
          alt=""
          style={{ objectFit: "contain" }}
        />

        <img
          id="output"
          className={imgHidden ? "photo" : "hidden"}
          alt=""
          style={{ objectFit: "contain" }}
        />

        {!editProduct ? (
          <div>
            <div className="nameCost">
              <div>
                <p>{nameProduct}</p>
                <p style={{ fontWeight: "700" }}>${priceProduct}</p>
              </div>
              <div>
                {User.position === "Associate" ? null : (
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => setEditProduct(true)}
                    src={EditIcon}
                    alt=""
                  />
                )}
                {User.position === "Associate" ? null : (
                  <img
                    style={{ cursor: "pointer" }}
                    src={DeleteIcon}
                    onClick={() => deleteProductInfo(id, nameProduct)}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="productEdit">
              <div>
                <TextFieldProduct
                  styleComponent="editProduct"
                  // value={multiCategories}
                  multiCategories={multiCategories}
                  label="Category"
                  disabled={true}
                />
                <TextFieldProduct
                  styleComponent="editProduct"
                  value={numberProduct}
                  label="Number"
                  disabled={true}
                />
                <TextFieldProduct
                  styleComponent="editProduct"
                  value={quantityProduct}
                  label="Quantity available"
                  disabled={true}
                />
              </div>
              <div>
                <TextFieldProduct
                  styleComponent="editProduct"
                  value={sizeProduct}
                  label="Package size"
                  disabled={true}
                />
                <TextFieldProduct
                  styleComponent="editProduct"
                  value={typeProduct}
                  label="Package type"
                  disabled={true}
                />
                {/*{info.rates.map((rates) => (*/}
                {/*    <TextFieldProduct*/}
                {/*        key={rates}*/}
                {/*        styleComponent="editProduct"*/}
                {/*        value="5"*/}
                {/*        label="Rating"*/}
                {/*        rating={true}*/}
                {/*        rate={rates.rate}*/}
                {/*    />*/}
                {/*))}*/}
                {User.position === "Associate" ? null : (
                  <TextFieldProduct
                    styleComponent="editProduct"
                    value="5"
                    label="Rating"
                    rating={true}
                    rate={info.rate_of_product}
                  />
                )}
              </div>
            </div>
            <div className="description">
              <p style={{ width: "400px", wordWrap: "break-word" }}>
                <label style={{ fontWeight: "600" }}>Description: </label>
                {descriptionProduct}
              </p>
            </div>

            {errorMessageProduct?.errorMessage ? (
              <p style={{ color: "red" }}>{errorMessageProduct.errorMessage}</p>
            ) : null}
          </div>
        ) : (
          <div style={{ width: "80%" }}>
            {/*<p className="exit" onClick={() => closeEditProduct()}>*/}
            {/*  X*/}
            {/*</p>*/}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <form></form>
              <TextFieldProduct
                stateFocus={true}
                value={nameProduct}
                label="Product name"
                styleComponent={"editProductActive"}
                disabled={false}
                changeValue={changeName}
              />
             
              <div style={{ width: "100%", textAlign: "start" }}>
                <MultiSelect
                  changeCategories={changeCategoriesMulti}
                  checkCategories={multiCategories}
                  state={stateDiscard}
                  arrayCategories={arrayCategories}
                  categoriesItem={categoriesItem}
                  setValidCategories={setValidCategories}
                  validCategories={validCategories}
                />
              </div>
              <TextFieldProduct
                value={numberProduct}
                label="Serial number"
                styleComponent={"editProductActive"}
                disabled={false}
                changeValue={changeNumber}
              />
              <label style={{ textAlign: "start", marginTop: "10px" }}>
                Description
              </label>
              <textarea
                style={{
                  marginTop: "10px",
                  border: "0px",
                  borderBottom: "1px grey solid",
                  resize: "none",
                }}
                value={descriptionProduct}
                onChange={(e) => changeDescription(e)}
              ></textarea>              
            </div>
            <div className="editBlockFlex">
              <div style={{ marginRight: "50px" }}>
                <TextFieldProduct
                  value={quantityProduct}
                  label="Quantity available"
                  styleComponent={"editProductActive"}
                  disabled={true}
                  changeValue={changeQuantity}
                />

                <TextFieldProduct
                  value={sizeProduct}
                  label="Package size"
                  styleComponent={"editProductActive"}
                  disabled={false}
                  changeValue={changeSize}
                />
              </div>
              <div>
                <TextFieldProduct
                  value={priceProduct}
                  label="Cost per item"
                  styleComponent={"editProductActive"}
                  disabled={false}
                  changeValue={changePrice}
                />                

                <SelectTypeOption
                  title={"editProduct"}
                  typeProduct={typeProduct}
                  changePackageType={changeType}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {image64Valid ? (
        <p style={{ color: "red" }}>Too big file size, image size should be less than 5MB</p>
      ) : null}

      {validateSize ? (
        <p style={{ color: "red", marginLeft: "50px" }}>size small</p>
      ) : null}
      {validName ? (
        <p style={{ color: "red", marginLeft: "50px" }}>
          Letters, spaces, apostrophes, and dashes are allowed or product name
          more than 30 symbols
        </p>
      ) : null}
      {/*{validNumber ? <p style={{color:'red',marginLeft:'50px'}}>Only digits are allowed</p> : null}*/}
      {/*{validType?<p>bad</p>:null}*/}
      {validCost ? (
        <p style={{ color: "red", marginLeft: "50px" }}>
          Only digits are allowed.
        </p>
      ) : null}
      {validDescription ? (
        <p style={{ color: "red", marginLeft: "50px" }}>
          Description must be shorter than or equal to 100 characters
        </p>
      ) : null}
      {validQuantity ? <p>Only digits are allowed.</p> : null}
      <div>
        {editProduct ? (
          <div className="block-sale">
            {quantityProduct === 0 ? (
              <input
                disabled={true}
                className="check-box"
                type="checkbox"
                checked={true}
                onChange={() => saleChange()}
              />
            ) : (
              <input
                disabled={quantityProduct === 0 ? true : false}
                className="check-box"
                type="checkbox"
                checked={oldStatusSale === true ? false : true}
                onChange={() => saleChange()}
              />
            )}
            <label style={{ margin: 0, padding: 0, color: "grey" }}>
              Product is not available for sale
            </label>
          </div>
        ) : null}
      </div>
      {User.position === "Associate" ? null : (
        <div className="buttonStyle">
          {!editProduct ? (
            <>
              <Button
                value="Add new batch"
                typeAction="saveEditActiveProduct"
                typeNoActive="saveEditActiveProductAddBatch"
                click={addNewBatchModal}
              />

              {quantityProduct === 0 ? (
                <Button
                  disabled={true}
                  value="Delete batch"
                  typeAction="disabledBatch"
                  typeNoActive="deleteBatchProduct"
                  click={DeleteBatchModal}
                />
              ) : (
                <Button
                  disabled={false}
                  value="Delete batch"
                  typeAction="saveEditActiveProduct"
                  typeNoActive="deleteBatchProduct"
                  click={DeleteBatchModal}
                />
              )}              
            </>
          ) : (
            <>
              <div>
                <Button
                  disabled={false}
                  typeAction={"profileButtonVisible"}
                  typeNoActive={"profileButtonVisible"}
                  type={"discardChangeActive"}
                  value={"Discard changes"}
                  click={discardEditProduct}
                />
              </div>
              {disabledSave ? (
                <Button
                  disabled={true}
                  // typeAction={"profileButtonVisible"}
                  typeAction={"saveProductNoActiveS"}
                  typeNoActive={"test"}
                  type={"saveChangeActive"}
                  value={"Save changes"}
                  click={saveChanges}
                />
              ) : (
                <Button
                  disabled={false}
                  // typeAction={"profileButtonVisible"}
                  typeAction={"saveChange"}
                  typeNoActive={"test"}
                  type={"saveChangeActive"}
                  value={"Save changes"}
                  click={saveChanges}
                />
              )}
            </>
          )}
        </div>
      )}

      {/*{info.rates.map((rates) => (*/}
      {/*    <label key={rates.id} className="titleRating">Product Rating({rates.rate})</label>*/}
      {/*))}*/}
      <div className="titleRating">
        Product Ratings <Ratings styleRating="big" status="oneStar" rate={1} />
        <label>
          {info.rate_of_product}
          <label style={{ color: "#ADADADFF" }}>({info.rates.length})</label>
        </label>
      </div>
      {!info.rate_of_product ? (
        <p>There are no ratings and reviews for this product</p>
      ) : null}

      {info.rates.map((item, index) => (
        <React.Fragment key={index}>
          <CommentUser rates={item} />
        </React.Fragment>
      ))}
    </div>
  );
};
export default ModalInfoProduct;
