import "./styles.scss";
import { useEffect, useState } from "react";

const Ratings = ({ styleRating, rate, status }) => {
  useEffect(() => {
    setRate(rate);
  }, []);

  const [rates, setRate] = useState(2);
  if (status === "oneStar") {
    return (
      <div className={styleRating}>
        <span className={rate === 1 ? "active" : null}></span>
      </div>
    );
  }
  return (
    <div className={styleRating}>
      <span className={rates > 0 ? "active" : null}></span>
      <span className={rates >= 2 ? "active" : null}></span>
      <span className={rates >= 3 ? "active" : null}></span>
      <span className={rates >= 4 ? "active" : null}></span>
      <span className={rates >= 5 ? "active" : null}></span>
    </div>
  );
};
export default Ratings;
