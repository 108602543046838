import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { LogOutExit } from "../../redux/auth/actions";
import { clearPhone, logoutsUser } from "../../redux/user/actions";
import logoutImg from "../../assets/profile/logout.png";
import editImg from "../../assets/profile/edit.png";
import { useState } from "react";
import { clearOrders } from "../../redux/orders/actions";

const UserInfo = ({ edit, editProfile, discardModal }) => {
  const dispatch = useDispatch();

  const UserProfile = useSelector((state) => state.profile.userProfiles);
  const idUser = useSelector((state) => state.auth.user);

  const [visible, setVisible] = useState(false);
  const logOutAction = () => {
    dispatch(LogOutExit(idUser.userId));
    dispatch(logoutsUser());
    dispatch(clearPhone());
    dispatch(clearOrders());
  };
  const visiblChange = () => {
    setVisible(true);
  };
  const visibleFals = () => {
    setVisible(false);
  };

  return (
    <div className="userInfo">
      {visible ? (
        <div style={{ width: "344px" }} className="modalVisibleLogout">
          <span>Confirm the action</span>
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
            Are you sure you want to log out?{" "}
          </span>
          <div style={{ marginTop: "20px" }}>
            <p
              onClick={() => visibleFals()}
              className="linkStylesDiscord"
              style={{ cursor: "pointer" }}
            >
              Stay
            </p>

            <p
              onClick={() => logOutAction()}
              className="linkStylesDiscord"
              style={{ cursor: "pointer" }}
            >
              Log out
            </p>
          </div>
        </div>
      ) : null}

      <div className="namePosition">
        <p>{UserProfile.fullName}</p>
        <p style={{ color: "#ACACAC", marginLeft: "16px" }}>
          {UserProfile.position}
        </p>
      </div>

      <div className="buttonEditLogout">
        {!editProfile ? (
          <>
            {UserProfile.position === "Associate" ? null : (
              <p onClick={() => edit()} className="edit">
                <img src={editImg} alt="" className="editIMG" />
                Edit
              </p>
            )}

            <p
              onClick={() => visiblChange()}
              className={
                UserProfile.position === "Associate"
                  ? "logoutAssociate"
                  : "logout"
              }
              style={{ cursor: "pointer" }}
            >
              <img src={logoutImg} alt="" className="logoutIMG" />
              Log out
            </p>
          </>
        ) : (
          <p
            onClick={() => discardModal()}
            className="discardStyle"
            style={{ cursor: "pointer" }}
          >
            X Discard
          </p>
        )}
      </div>
    </div>
  );
};
export default UserInfo;
