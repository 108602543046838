import BackToLogin from "../../components/back-to-login/index";
import { useEffect, useState } from "react";
import { AuthContainer } from "../../components/auth-container";
import AuthTitle from "../../components/auth-title";
import TextField from "../../components/text-field";
import BackIcon from "../../components/icon/back-icon";
import Button from "../../components/new-button";
import { numberPhone, resetPhone } from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalWindow from "../../components/modalWindow/modalWindow";
import { Box, Modal } from "@mui/material";
import { isPhoneNumber } from "class-validator";

const ResetPassword = () => {
  const [visible, setVisible] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("+");
  const { phone } = useSelector((state) => state.auth);
  const [error, setErrorMessage] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const changePhone = (e) => {
    const inputNumber = e.target.value;
    setPhoneNumber(inputNumber);
    const result = isPhoneNumber(inputNumber);

    if (result === true) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  useEffect(() => {
    if (phone.userId) {
      setErrorMessage(false);
      setVisible(false);
      navigate("/resetPassword/code");
    } else {
      setErrorMessage(true);
      setVisible(false);
    }
  }, [phone]);

  const clickPhone = () => {
    dispatch(numberPhone({ phone: phoneNumber }));
    setVisible(true);
  };

  const backPage = () => {
    dispatch(resetPhone());
    navigate("/");
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <AuthContainer>
      <Modal open={visible}>
        <Box sx={style}>
          <ModalWindow visible={visible} title=" " loadText="Redirecting..." />
        </Box>
      </Modal>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <BackIcon backPage={backPage} />
        <AuthTitle title="Forgot password" />
      </div>

      <TextField
        value={phoneNumber}
        changeValue={changePhone}
        label="Phone Number"
        styleInput={"text-field"}
        labelStyle={"labelLogin"}
        disabled={true}
      />

      {error ? (
        <label style={{ color: "red" }}>{phone.errorMessage}</label>
      ) : (
        ""
      )}

      <BackToLogin backPage={backPage} />
      <Button
        value={"Proceed"}
        disabled={buttonDisabled}
        click={clickPhone}
        typeAction={"buttonLogIn"}
        typeNoActive={"activeButtonLogIn"}
      />
    </AuthContainer>
  );
};
export default ResetPassword;
