import React from "react";
import "./styles.scss";

export const AuthContainer = ({ children }) => {
  return (
    <div className="authContainer">
      <div className="loginBlock">
        <div>
          <p>
            HAV A NICE DAY <span style={{ color: "white" }}>ADMIN</span>
          </p>
          {children}
        </div>
      </div>
    </div>
  );
};
