export const GET_ASSOCIATES = "GET_ASSOCIATES";
export const GET_PROFILE_ASSOCIATES = "GET_PROFILE_ASSOCIATES";
export const ACTIVE_TAB = "ACTIVE_TAB";

export const CREATE_ASSOCIATES = "CREATE_ASSOCIATES";
export const SORT_ASSOCIATES = "SORT_ASSOCIATES";
export const ERRORS = "ERRORS";



