import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./validation/validation.constants.js";
import { getAssociation, postNewUser } from "../../redux/associates/action";
import { generateRandomString } from "../../utils/generate-random-string";
import Form from "../../components/form";
import { TextField, InputAdornment, IconButton, Stack } from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Refresh as RefreshIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import {
  ActionButton,
  SubMenuButton,
  SubmitButton,
} from "../../shared/styles.styled";
import "./styles.scss";

const AddNewAssociate = ({ newAssociates, setNewAssociates, token }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const generatePassword = () => {
    const generatedPasswordLength = 9;
    const generatedPassword = generateRandomString(generatedPasswordLength);

    reset((previousValues) => ({
      ...previousValues,
      password: generatedPassword,
    }));
  };

  const createUser = (data) => {
    dispatch(postNewUser(token, data));
    setNewAssociates(false);
    if (errors.errorMessage !== []) {
      setNewAssociates(true);
    } else {
      setNewAssociates(false);
    }

    setTimeout(() => {
      setNewAssociates(false);
      dispatch(getAssociation(token));
    }, 2000);
  };

  const onSubmit = async (value) => {
    createUser(value);
  };

  const {
    handleSubmit,
    control,
    // watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "+",
      fullName: "",
      email: "",
      password: "",
      position: "Associate",
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="modal_associates">
      <div className="title_header_block">
        <p className="title_header">Add new associate</p>
        <ActionButton
          sx={{ color: "#ACACAC", position: "absolute", right: "25px" }}
          onClick={() => setNewAssociates(false)}
        >
          <CloseIcon />
        </ActionButton>
      </div>
      {errors.errorMessage ? (
        <p style={{ color: "red" }}>{errors.errorMessage}</p>
      ) : null}

      <Form class="form" onSubmit={onSubmit} handleSubmit={handleSubmit}>
        <Controller
          render={({ field }) => (
            <TextField
              autoFocus
              helperText={errors.phone ? `${errors.phone.message}` : ""}
              autoComplete="off"
              variant="standard"
              value={""}
              errors={!!errors.phone}
              label={"Phone number"}
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          )}
          name="phone"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField
              helperText={errors.email ? `${errors.email.message}` : ""}
              autoComplete="off"
              variant="standard"
              value={""}
              errors={!!errors.email}
              label={"Email"}
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          )}
          name="email"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField
              helperText={errors.fullName ? `${errors.fullName.message}` : ""}
              autoComplete="off"
              variant="standard"
              value={""}
              errors={!!errors.fullName}
              label={"Name"}
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          )}
          name="fullName"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField
              helperText={errors.password ? `${errors.password.message}` : ""}
              autoComplete="off"
              variant="standard"
              value={""}
              type={showPassword ? "text" : "password"}
              errors={!!errors.password}
              label={"Password"}
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          name="password"
          control={control}
        />
        <SubMenuButton
          sx={{ color: "#25282A", justifyContent: "start" }}
          startIcon={<RefreshIcon sx={{ transform: "scaleX(-1)" }} />}
          onClick={() => generatePassword()}
        >
          Generate new password
        </SubMenuButton>
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "31px",
          }}
          direction="row"
        >
          <ActionButton
            underline="none"
            sx={{ color: "#25282A", width: "163px", height: "37px" }}
            onClick={() => setNewAssociates()}
          >
            Cancel
          </ActionButton>
          <SubmitButton
            sx={{ width: "163px", height: "37px", marginTop: "0px" }}
            data-testid="submit-btn"
            variant="contained"
            color="primary"
            type="submit"
          >
            Add
          </SubmitButton>
        </Stack>
      </Form>
    </div>
  );
};
export default AddNewAssociate;
