export const BACKGROUND_STATE = "BACKGROUND_STATE";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_CATEGORIES = "GET_PRODUCTS_CATEGORIES";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const UP_DATE_PRODUCTS = "UP_DATE_PRODUCTS";
export const CREATE_NEW_PRODUCTS = "CREATE_NEW_PRODUCTS";
export const CREATE_NEW_CATEGORIES = "CREATE_NEW_CATEGORIES";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const ACTIVE_PAGE = "ACTIVE_PAGE";
export const DELETE_CATEGORIES = "DELETE_CATEGORIES";

export const GET_DEPRECATED = "GET_DEPRECATED";
export const CHANGE_CATEGORIES = "CHANGE_CATEGORIES";
export const CLEAR_PRODUCTS_CATEGORIES = "CLEAR_PRODUCTS_CATEGORIES";

export const SET_IS_FETCHING = "SET_IS_FETCHING";
export const SET_FETCH_ERROR = "SET_FETCH_ERROR";
export const SET_FETCH_SUCCESS = "SET_FETCH_SUCCESS";
