import Title from "../../components/title";
import "./styles.scss";
import { useEffect, useState } from "react";
import ModalDiscard from "../../components/modalWindow/modal-discard";
import TextField from "../../components/text-field";
import Button from "../../components/new-button";
import { useDispatch, useSelector } from "react-redux";

import {
  error_phone,
  updatePhoneUser,
  updatePhoneUserNewPhone,
  updateProfileUser,
} from "../../redux/user/actions";
import UserInfo from "../../components/userInfo";
import { postLogout } from "../../redux/auth/actions";
import { isEmail, isPhoneNumber } from "class-validator";

const ManageProfile = () => {
  const UserProfile = useSelector((state) => state.profile.userProfiles);
  const [editProfile, setEditProfile] = useState(false);
  const [visible, setVisible] = useState(false);
  const [phoneValue, setPhoneValue] = useState(UserProfile.phone);
  const [emailValue, setEmailValue] = useState(UserProfile.email);
  const [positionValue, setPositionValue] = useState(UserProfile.position);
  const [changeActive, setChangeActive] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [positionValid, setPosition] = useState(false);
  const [disabledCode, setDisabledCode] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPosition, setErrorPosition] = useState(false);
  const [correctCode, setCorrectCode] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const resultPhone = isPhoneNumber(phoneValue);
    if (resultPhone === true) {
      setErrorPhone(false);
      setPhoneValid(true);
    } else {
      setPhoneValid(false);
      setErrorPhone(true);
    }

    const resultEmail = isEmail(emailValue);
    if (resultEmail === true) {
      setEmailValid(true);
      setErrorEmail(false);
    } else {
      setErrorEmail(true);
      setEmailValid(false);
    }
    if (positionValue?.length > 4) {
      setErrorPosition(false);
      setPosition(true);
    } else {
      setErrorPosition(true);
      setPosition(false);
    }
  }, [emailValue, phoneValue, positionValue, phoneValid]);

  const changePhoneValue = (e) => {
    setPhoneValue(e.target.value);
  };
  const changeEmailValue = (e) => {
    setEmailValue(e.target.value);
  };
  const discardModal = () => {
    setVisible(true);
  };

  const stay = () => {
    if (visible === false) {
      setVisible(true);
    } else setVisible(false);
  };

  const discard = () => {
    setEditProfile(false);
    setVisible(false);
    setPhoneValue(UserProfile.phone);
    setEmailValue(UserProfile.email);
    setPositionValue(UserProfile.position);
    setDisabledCode(false);
  };

  const changeProfile = () => {
    if (phoneValue === UserProfile.phone) {
      const arr = {
        userId: UserProfile.userId,
        fullName: UserProfile.fullName,
        email: emailValue,
        phone: phoneValue,
        position: positionValue,
      };
      dispatch(updatePhoneUser(arr, UserProfile.accessToken));
      setChangeActive(false);
      setEditProfile(false);
      setDisabledCode(false);
    } else {
      const NewPhone = {
        userId: UserProfile.userId,
        fullName: UserProfile.fullName,
        email: emailValue,
        phone: phoneValue,
        position: positionValue,
      };
      dispatch(updatePhoneUserNewPhone(NewPhone, UserProfile.accessToken));
      setDisabledCode(true);
    }
  };

  const edit = (e) => {
    setEditProfile(true);
  };

  const discardAction = () => {
    setPhoneValue(UserProfile.phone);
    setEmailValue(UserProfile.email);
    setPositionValue(UserProfile.position);
  };

  useEffect(() => {
    if (
      phoneValue !== UserProfile.phone ||
      emailValue !== UserProfile.email ||
      positionValue !== UserProfile.position
    ) {
      if (
        editProfile === true &&
        phoneValid === true &&
        emailValid === true &&
        positionValid === true
      ) {
        setChangeActive(true);
      } else setChangeActive(false);
    }
  }, [
    phoneValue,
    emailValue,
    positionValue,
    phoneValid,
    emailValid,
    positionValid,
  ]);

  const idUser = useSelector((state) => state.auth.user);

  useEffect(() => {}, [emailValue, positionValue, phoneValue]);
  const [code, setCode] = useState("");
  const changeCode = (e) => {
    setCode(e.target.value);
  };

  const changePhone = () => {
    dispatch(error_phone({}));
    const arr = {
      userId: UserProfile.userId,
      email: emailValue,
      fullName: UserProfile.fullName,
      phone: phoneValue,
      position: positionValue,
      passCode: code,
    };
    dispatch(
      updateProfileUser(
        arr,
        UserProfile.accessToken,
        setCorrectCode,
        setDisabledCode,
        setEditProfile,
        setCode,
      )
    );
  };
  const logOutAction = () => {
    dispatch(postLogout(idUser.userId, idUser.accessToken));
    localStorage.clear();
    window.location.reload();
  };
  return (
    <div className="manageProfile">
      <Title title="PROFILE" />
      <UserInfo
        logoutModal={logOutAction}
        edit={edit}
        editProfile={editProfile}
        discardModal={discardModal}
      />

      <div className="styleBlackInfo" style={{ height: "400px" }}>
        <div>
          <TextField
            changeValue={changePhoneValue}
            disabled={editProfile}
            styleInput={!editProfile ? "inputProfile" : "inputEdit"}
            labelStyle={!editProfile ? "labelTitle" : "labelEdit"}
            blockStyle={!editProfile ? "inputLabel" : "inputLabelEdit"}
            label="Phone:"
            value={phoneValue}
          />

          {disabledCode ? (
            <>
              <div className="blockEnterCode">
                <TextField
                  value={code}
                  changeValue={changeCode}
                  disabled={true}
                />
                <Button
                  value="Enter code"
                  click={changePhone}
                  typeNoActive="enterCode"
                />
              </div>
              {correctCode ? (
                <p style={{ color: "red" }}>
                  Invalid verification code provided
                </p>
              ) : null}
            </>
          ) : null}
          {errorPhone ? (
            <p style={{ fontSize: "14px", color: "red" }}>Invalid phone</p>
          ) : null}
          <TextField
            changeValue={changeEmailValue}
            value={emailValue}
            disabled={editProfile}
            styleInput={!editProfile ? "inputProfile" : "inputEdit"}
            labelStyle={!editProfile ? "labelTitle" : "labelEdit"}
            blockStyle={!editProfile ? "inputLabel" : "inputLabelEdit"}
            label="Email:"
          />
          {errorEmail ? (
            <p style={{ fontSize: "14px", color: "red" }}>Invalid email</p>
          ) : null}
          {errorPosition ? (
            <p style={{ fontSize: "14px", color: "red" }}>Invalid position</p>
          ) : null}
        </div>

        <div className="modalDiscard">
          {visible ? (
            <ModalDiscard
              discard={discard}
              stay={stay}
              title="Discard changes?"
              question="Unsaved progress will be lost?"
              answerOne="Stay"
              answerTwo="Discard"
              styleVisible={"modalVisibleDiscord"}
            />
          ) : null}
        </div>
      </div>
      <div className="buttonClass">
        {editProfile ? (
          <>
            <Button
              disabled={false}
              typeAction={"profileButtonVisible"}
              typeNoActive={"profileButtonVisible"}
              // type={!changeActive ? "discardChange" : "discardChangeActive"}
              type={"discardChangeActive"}
              value={"Discard changes"}
              click={discardAction}
            />
            <Button
              click={changeProfile}
              disabled={!changeActive}
              typeAction={"profileButtonVisible"}
              typeNoActive={"profileActive"}
              type={!changeActive ? "saveChange" : "saveChangeActive"}
              value={"Save changes"}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default ManageProfile;
