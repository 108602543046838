import './style.scss'


const BackIcon = ({backPage}) => {
 return(

<span className='backIconImg' onClick={()=>backPage()}></span>

 )
}
export default BackIcon;
