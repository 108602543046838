import "./styles.scss";
import {  useEffect  } from "react";
import Upload from '../../../src/assets/products/upload.png'

const PhotoProduct = ({
  title,
  onChangeImage,
  setDisabled,
  disabled,
  state,
}) => {
  // const changePhoto = (event) => {
  //   // const files = e.target.files;
  //   // const file = files[0];
  //   // var image = document.getElementById("output");
  //   // image.src = URL.createObjectURL(event.target.files[0]);
  // };
  // const [disabled,setDisabled]=useState(true)
  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  }, []);

  return (
    <div>
      <p className="titlePhoto">{title}</p>
      {/*<input*/}
      {/*    disabled={disabled}*/}
      {/*    type="file"*/}
      {/*    onChange={onChangeImage}*/}
      {/*   className='custom-file-inputNew'*/}
      {/*/>*/}
      <img
        id="output"
        alt=""
        style={
          state
            ? {
                width: "222px",
                height: "150px",
                position: "absolute",
                display: "flex",
                marginLeft: "61px",
                objectFit: "contain",
              }
            : {
                width: "222px",
                height: "150px",
                position: "absolute",
                display: "flex",
                marginLeft: "61px",
                background: "white",
                objectFit: "contain",
              }
        }
      />
      {/*<div className="photoArea">*/}
          <label htmlFor="" className="photoArea">
              <img src={Upload} alt=""/>
        <input
          style={{ zIndex: 1,position:"absolute",opacity:0 }}
          disabled={disabled}
          type="file"
          onChange={onChangeImage}
          className="custom-file-input"
          accept=".jpg,.jpeg,.png"
        />
          </label>
        {/*<input  type="file"   onChange={onChangeImage}  />*/}
      {/*</div>*/}
    </div>
  );
};
export default PhotoProduct;
