import "./styles.scss";
import DownIcon from "../../../assets/dowIIcon.png";
import UpIcon from "../../../assets/downIcon.png";

const Detailed = ({
  showDetailed,
  openDetailed,
  id,
  minute,
  showMinute,
  showMinutes,
}) => {
  if (minute) {
    return (
      <div onClick={() => showMinutes(id)}>
        {showMinute ? (
          <img src={UpIcon} alt="UpIcon" />
        ) : (
          <img src={DownIcon} alt="downIcon" />
        )}
      </div>
    );
  }
  return (
    <div onClick={() => openDetailed(id)} style={{ cursor: "pointer" }}>
      {showDetailed ? (
        <img src={UpIcon} alt="UpIcon" />
      ) : (
        <img src={DownIcon} alt="downIcon" />
      )}
    </div>
  );
};
export default Detailed;
