import ManageProfile from "../../pages/manage-profile";
import Orders from "../../pages/orders";
import Products from "../../pages/products";
import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";
import ProfileIcon from "../../assets/profile/profile.png";
import OrderIcon from "../../assets/profile/Orders.png";
import ProductIcon from "../../assets/profile/product.png";
import Associates from "../../pages/associates";
import { useDispatch, useSelector } from "react-redux";
import { postTabActive } from "../../redux/associates/action";

const ManageContainer = () => {
  const UserProfile = useSelector((state) => state.profile.userProfiles);
  const tabActive = useSelector((state) => state.userAssociations.tabActive);
  const dispatch = useDispatch();
  const tabs = [
    {
      title: "Profile",
      content: <ManageProfile />,
      icon: ProfileIcon,
      id: 1,
      visible: true,
    },
    {
      title: "Orders",
      content: <Orders />,
      icon: OrderIcon,
      id: 2,
      visible: true,
    },
    {
      title: "Products",
      content: <Products />,
      icon: ProductIcon,
      id: 3,
      visible: true,
    },
    {
      title: "Associates",
      content: <Associates />,
      icon: ProfileIcon,
      id: 4,
      visible: UserProfile.position === "admin",
    },
  ];

  return (
    <Tabs
      defaultFocus={true}
      className="heder-tab"
      defaultIndex={tabActive}
      onSelect={(index) => dispatch(postTabActive(index))}
      selectedclassname="tabLinkFocus"
    >
      <TabList className="body-button">
        {tabs.map((tab) => {
          return (
            tab?.visible && (
              <Tab
                key={tab.title}
                className="tabLink"
                selectedClassName="tabLinkFocus"
              >
                <div
                  style={{
                    paddingLeft: "45px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      style={{
                        paddingRight: "15px",
                        width: "30px",
                        height: "30px",
                      }}
                      src={tab.icon}
                      alt="icon"
                    />
                  </div>
                  <span>{tab.title}</span>
                </div>
              </Tab>
            )
          );
        })}
      </TabList>
      {tabs.map((item) => (
        <TabPanel key={item.id} style={{ width: "100%" }}>
          {item.content}
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default ManageContainer;
