import "./style.scss";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const TitleManagement = styled(Typography)(({ theme }) => ({
  fontSize: "28px",
  fontWeight: "400",
  fontFamily: "Norwester",
  color: "#25282a",
  display: "flex",
  fontStyle: "normal",
  lineHeight: "34px",
  gap: "3px",

  "& span": {
    color: "#CACACA",
  },
}));

const Title = ({ title, number }) => {
  return (
    <>
      <TitleManagement>
        {title}
        <span> ({number})</span>
      </TitleManagement>
    </>
  );
};
export default Title;
