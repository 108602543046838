import "./styles.scss";
import { useEffect, useState } from "react";

const SelectTypeOption = ({ changePackageType, title, typeProduct }) => {
  const [options, setOption] = useState([
    { state: "Bottled" },
    { state: "Cup" },
  ]);
  const [defaultType, setDefaultType] = useState("");
  useEffect(() => {
    if (title === "editProduct") {
      let defaultTypes = options.find((item) => item.state === typeProduct);
      setDefaultType(defaultTypes.state);
    }
  }, [typeProduct]);
  if (title === "editProduct") {
    return (
      <div className="editProductBlockSelect">
        <label>Package type</label>
        <select
            style={{cursor:'pointer'}}
            className="categories_edit"
          name=""
          id=""
          onChange={(e) => changePackageType(e)}
        >
          {options.map((item,index) => {
            if (defaultType === item.state) {
              return (
                <option selected value={defaultType} key={index}>
                  {defaultType}
                </option>
              );
            } else return <option  key={index} value={item.state}>{item.state}</option>;
          })}
        </select>
      </div>
    );
  }
  if (title === "addProduct")
    return (
      <div className="blockSelect" style={{ marginTop: "32px" }}>
        <label style={{ fontSize: "14px", fontFamily: "Open Sans" }}>
          Package type
        </label>
        <select
          style={{ paddingTop: "15px",cursor:'pointer' }}
          className="categories"
          name=""
          id=""
          onChange={(e) => changePackageType(e)}
        >
          {options.map((item,index) => (
            <option key={index} value={item.state}>{item.state}</option>
          ))}
        </select>
      </div>
    );
};
export default SelectTypeOption;
