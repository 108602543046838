import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { sortUser } from "../../../redux/associates/action";

import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import { RadioGroup, FormControlLabel, Typography, Stack } from "@mui/material";
import {
  ActionButton,
  SortLabelTypography,
  SortModalBox,
} from "../../../shared/styles.styled";

const ModalBanSort = ({ sortAssociate, setSortAssociate, token }) => {
  const dispatch = useDispatch();
  const [sortValue, setSortValue] = useState("active");

  const handleChange = (event) => {
    setSortValue(event.target.value);
  };

  useEffect(() => {
    if (sortValue === "active") {
      dispatch(sortUser(token, true));
    }
    if (sortValue === "banned") {
      dispatch(sortUser(token, false));
    }
  }, [sortValue]);

  return (
    <SortModalBox>
      <Stack
        sx={{ alignItems: "center", justifyContent: "space-between" }}
        direction="row"
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            color: "#414141",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Open Sans",
          }}
        >
          Sort
        </Typography>
        <ActionButton
          sx={{ color: "#ACACAC" }}
          onClick={() => setSortAssociate(false)}
        >
          <CloseIcon />
        </ActionButton>
      </Stack>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={sortValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value="banned"
          control={<Radio color="default" />}
          label={
            <SortLabelTypography
              sx={{ color: "#e9424d", background: "rgba(255, 103, 113, 0.15)" }}
            >
              Banned
            </SortLabelTypography>
          }
        />
        <FormControlLabel
          value="active"
          control={<Radio color="default" />}
          label={
            <SortLabelTypography
              sx={{ color: "#0eb115", background: "rgba(16, 200, 23, 0.15)" }}
            >
              Active
            </SortLabelTypography>
          }
        />
      </RadioGroup>
    </SortModalBox>
  );
};
export default ModalBanSort;
