import React, { useState } from "react";

import "./styles.scss";

const InputPassword = ({ passwordValue, changePassword, label }) => {
  const [show, setShow] = useState(false);

  const showPassword = () => {
    if (!show) {
      setShow(true);
    } else setShow(false);
  };
  return (
    <div className="inputPassword">
      <label>{label}</label>
      <input
        value={passwordValue}
        onChange={(event) => changePassword(event)}
        type={!show ? "password" : "text"}
        placeholder="Password"
        autoComplete="current-password"
        // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
        // required
      />
      <span
        onClick={() => showPassword()}
        className={!show ? "showPasswordIcon" : "hidePasswordIcon"}
      />
    </div>
  );
};
export default InputPassword;
