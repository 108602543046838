import "./styles.scss";
import Ratings from "../ratings";

const TextFieldProduct = ({
  value,
  label,
  styleComponent,
  disabled,
  rating,
  rate,
  changeValue,
  stateFocus,
    multiCategories
}) => {
  if(multiCategories===undefined)
  {
  return (

    <div className={styleComponent}>

      <label>{label}:</label>
      {rating ? (
        <Ratings rate={rate} styleRating="small" />
      ) : (
        <input
          autoFocus={stateFocus}
          disabled={disabled}
          type="text"
          value={value}
          onChange={(e) => changeValue(e)}
        />
      )}

      {/*<input disabled={disabled} type="text" value={value} />*/}
    </div>
  )}
  else
  {
    return (
       <>
         <label style={{fontWeight:600}}>{label}:</label>
         <p style={{margin:0,padding:0}}>
         {multiCategories.map(item=>
           // <p>{item.name}</p>
             item.name

       ).join(',')}</p></>
    )

  }
};
export default TextFieldProduct;
