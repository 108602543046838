import React, { useRef } from "react";
import "./styles.scss";

export const VerifyCodeFields = ({ onClick, code, setCode }) => {

  const inputs = useRef([]);
  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    onClick(newCode);
    if (slot !== code.length - 1) {
      inputs.current[slot + 1].focus();
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {code.map((num, index) => (
        <input
          className="inputField"
          ref={(ref) => inputs.current.push(ref)}
          key={index}
          value={num}
          autoFocus={!code[0]?.length && index === 0}
          onChange={(e) => processInput(e, index)}
          inputMode="numeric"
          onKeyUp={(e) => onKeyUp(e, index)}
          maxLength={1}
        />
      ))}
    </div>
  );
};
