import "./styles.scss";
import Status from "../../status";
import SelectPreparation from "../../select-preparation";
import Detailed from "../../icon/down-icon";
import DetailedArea from "../../detailed-area";
import ReactPaginate from "react-paginate";
import BackPagePagination from "../../../assets/backPagination.png";
import NextPaginationPage from "../../../assets/nextPage.png";
import { OrderStatusEnum } from "../../../shared/enums/order.enum.ts";

const { PREPARING, READY } = OrderStatusEnum;

const TableActiveOrders = ({
  titles,
  lengthOrderActive,
  titleTable,
  activeOrders,
  pageActive,
  pagePrevious,
  setDefaultPage,
  showDetailed,
  openDetailed,
  defaultPage,
  currentPageActive,
  pageChange,
}) => {
  return (
    <>
      <label className="titleTableActive">
        {titles}
        <label className="amountStyle">
          ({lengthOrderActive === null ? "0" : lengthOrderActive})
        </label>
      </label>
      <table className="tableBlock">
        <thead>
          <tr>
            {titleTable.map((item, index) => (
              <th key={index} className="orderTitle">
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        {activeOrders.orders?.map((order) => {
          if ([PREPARING, READY].includes(order.status)) {
            return (
              <tbody key={order.id}>
                <tr className="infoTable" key={order.id}>
                  <td>{order.id}</td>
                  <td>${order.cost}</td>
                  <td>
                    {" "}
                    {order.user.fullName} {order.user.phone}
                  </td>
                  <td>
                    <Status
                      key={order.id}
                      customerLocation={order.customer_location}
                    />
                  </td>
                  <td className="preparation">
                    <SelectPreparation
                      key={order.id}
                      preparation={order.preparation}
                      orderId={order.id}
                      status={order.status}
                      placed={order.placed}
                      page={pageActive}
                      setDefaultPage={setDefaultPage}
                    />
                  </td>
                  <td>
                    <span
                      className={order.status === OrderStatusEnum.PREPARING && order.preparing_alert_flag ? "red-alert" : ""}
                    >
                      {order.placed}
                    </span>
                  </td>
                  <td>{order.should_be_ready}</td>
                  <td>
                    <Status
                      status={order.status}
                      idOrder={order.id}
                      pageActive={pageActive}
                      pagePrevious={pagePrevious}                      
                    />
                  </td>
                  <td>
                    <Detailed
                      id={order.id}
                      showDetailed={showDetailed[order.id]}
                      openDetailed={openDetailed}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    {order.products.map((product, index) => (
                      <div key={product.name + index}>
                        <DetailedArea
                          key={product.name + index}
                          name={product.name}
                          amount={product.amount}
                          packages={product.packing_size}
                          price={product.price}
                          id={order.id}
                          showDetailed={showDetailed[order.id]}
                        />
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            );
          }
        })}
      </table>
      <div className="paginationBlock">
        <ReactPaginate
          previousLabel={
            <img src={BackPagePagination} style={{ width: "10px" }} alt="" />
          }
          nextLabel={<img src={NextPaginationPage} style={{ width: "10px" }} alt="" />}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          // initialPage={1}
          initialPage={defaultPage}
          pageCount={currentPageActive}
          onPageChange={pageChange}
          containerClassName={"pagination"}
          pageClassName={"pagination__btn"}
          pageLinkClassName={"pagination__content"}
          previousClassName={"pagination__btn"}
          previousLinkClassName={"pagination__content"}
          nextClassName={"pagination__btn"}
          nextLinkClassName={"pagination__content"}
          breakClassName={"pagination__btn"}
          breakLinkClassName={"pagination__content"}
          activeClassName={"pagination__active"}
          disabledClassName={"pagination__disabled"}
        />
      </div>
    </>
  );
};
export default TableActiveOrders;
