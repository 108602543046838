import React from "react";

import "./styles.scss";

const TextField = ({
  value,
  changeValue,
  label,
  styleInput,
  labelStyle,
  disabled,
  blockStyle,
  validate,
}) => {
  return (
    <>
      <div style={{ width: "100%" }} className={blockStyle}>
        <label className={labelStyle}>{label}</label>
        <input
          value={value}
          disabled={!disabled}
          onChange={(event) => changeValue(event)}
          className={styleInput}
          type="text"
          placeholder={label}
          autoComplete="on"
        />
      </div>
    </>
  );
};
export default TextField;
