import { useEffect, useState } from "react";
import { getActive, upDatePreparation } from "../../redux/orders/actions";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { OrderStatusEnum } from "../../shared/enums/order.enum.ts";

const { READY } = OrderStatusEnum;

const SelectPreparation = ({
  preparation,
  orderId,
  status,
  placed,
  page,
  setDefaultPage,
}) => {
  const dispatch = useDispatch();
  const [reloadPage, setReloadPage] = useState(false);
  const UserProfile = useSelector((state) => state.profile.userProfiles);

  const [minutes, setMinutes] = useState([
    { minutes: "5", default: true },
    { minutes: "10", default: false },
    { minutes: "15", default: false },
    { minutes: "20", default: false },
    { minutes: "25", default: false },
    { minutes: "30", default: false },
    { minutes: "35", default: false },
    { minutes: "40", default: false },
    { minutes: "45", default: false },
    { minutes: "50", default: false },
    { minutes: "55", default: false },
  ]);
  useEffect(() => {
    if (preparation === "5") {
      setMinutes([
        { minutes: "5", default: true },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "10") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: true },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "15") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: true },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "20") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: true },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "25") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: true },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "30") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: true },
        { minutes: "35", default: false },
        { minutes: "40", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "35") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: true },
        { minutes: "40", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "40") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40", default: true },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "45") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40", default: false },
        { minutes: "45", default: true },
        { minutes: "50", default: false },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "50") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40 ", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: true },
        { minutes: "55", default: false },
      ]);
    }
    if (preparation === "55") {
      setMinutes([
        { minutes: "5", default: false },
        { minutes: "10", default: false },
        { minutes: "15", default: false },
        { minutes: "20", default: false },
        { minutes: "25", default: false },
        { minutes: "30", default: false },
        { minutes: "35", default: false },
        { minutes: "40", default: false },
        { minutes: "45", default: false },
        { minutes: "50", default: false },
        { minutes: "55", default: true },
      ]);
    }
  }, [reloadPage, preparation]);
  const checkMinutes = (minutes) => {
    setDefaultPage(page);
    // if (reloadPage === false) {
    //   setReloadPage(true);
    // } else setReloadPage(false);
    setReloadPage(!reloadPage);
    let minute = {
      preparationTime: minutes,
      placed: placed,
    };
    dispatch(
      upDatePreparation(
        minute,
        UserProfile.userId,
        UserProfile.accessToken,
        orderId
      )
    );
    setTimeout(() => {
      dispatch(
        getActive(UserProfile.userId, UserProfile.accessToken, page, 15)
      );
    }, 500);
  };

  return (
    <select
      style={{ cursor: "pointer" }}
      disabled={status === READY ? true : false}
      className="selectPreparation"
      name=""
      id=""
      onChange={(event) => checkMinutes(event.target.value)}
    >
      {minutes.map((obj) => {
        if (obj.default) {
          return (
            // <option key={obj.minutes} selected value={obj.minutes}>
            <option key={obj.minutes} value={obj.minutes}>
              {obj.minutes}
            </option>
          );
        }
        return (
          <option key={obj.minutes} value={obj.minutes}>
            {obj.minutes}
          </option>
        );
      })}
    </select>
  );
};
export default SelectPreparation;
