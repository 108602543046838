import "./styles.scss";
// import BanImg from "../../../assets/products/garbage.png";
// import UnBanImg from "../../../assets/profile.png";
// import { useEffect } from "react";
// import Button from "@mui/material/Button";
import {
  AssociateBanButton,
  AssociateUnbanButton,
} from "../../../shared/styles.styled";
import {
  DeleteOutline as DeleteOutlineIcon,
  PersonOutline as PersonOutlineIcon,
} from "@mui/icons-material";

const TableAssociates = ({
  users,
  changeAssociate,
  changeAssociateStatus,
  editProfile,
  checked,
  clearCheck,
}) => {
  const nameRow = [
    { name: "Select all", id: 1 },
    { name: "User", id: 2 },
    { name: "Status", id: 3 },
    { name: "Phone number", id: 4 },
    { name: "Email", id: 5 },
    { name: "Status", id: 7 },
  ];

  return (
    <table className="table-associates">
      <thead>
        <tr>
          {nameRow.map((row) => (
            <th className="table_asso_th_tr" key={row.id}>
              {row.name}
            </th>
          ))}
        </tr>
      </thead>

      {users?.map((item) => (
        <tbody key={item.userId} className={"table_associates_body"}>
          <tr>
            <td>
              <input
                type="checkbox"
                checked={clearCheck ? false : checked[item.id]}
                onChange={(event) =>
                  changeAssociate(
                    item.fullName,
                    item.userId,
                    event,
                    item.isUserBanned
                  )
                }
              />
            </td>

            <td
              onClick={() => editProfile(item.userId)}
              style={{ cursor: "pointer" }}
            >
              {item.fullName}
            </td>
            <td
              style={!item.isUserBanned ? { color: "black" } : { color: "red" }}
            >
              {!item.isUserBanned ? "Active" : "Banned"}
            </td>
            <td>{item.phone}</td>
            <td style={{ zIndex: 5 }}>{item.email}</td>
            <td
              style={{ cursor: "pointer" }}
              className={
                !item.isUserBanned
                  ? "associates_ban_style"
                  : "associates_Unban_style"
              }
              onClick={() =>
                changeAssociateStatus(item.isUserBanned, item.userId)
              }
            >
              {!item.isUserBanned ? (
                <AssociateBanButton
                  variant="contained"
                  startIcon={<DeleteOutlineIcon />}
                >
                  Ban
                </AssociateBanButton>
              ) : (
                <AssociateUnbanButton
                  variant="contained"
                  startIcon={<PersonOutlineIcon />}
                >
                  Unban
                </AssociateUnbanButton>
              )}
            </td>
          </tr>
        </tbody>
      ))}
    </table>
  );
};
export default TableAssociates;
