import "./styles.scss";
import { useEffect, useState } from "react";
import {
  getActive,
  getPrevious,
  statusUpdate,
} from "../../redux/orders/actions";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES, CustomerLocationToColorMapping, StatusToColorMapping } from "../../shared/enums/order.enum.ts";

const Status = ({ status, customerLocation, idOrder, pageActive, pagePrevious }) => {
  const dispatch = useDispatch();
  const UserProfile = useSelector((state) => state.profile.userProfiles);
  const [statusColor, setStatusColor] = useState("");
  const [options, setOptions] = useState([]);

  function createOptions(activeStatus) {
    return STATUSES.map((status) => ({
      status,
      default: activeStatus === status,
    }));
  }  

  function setOptionsAndColor(status) {
    setOptions(createOptions(status));        
    setStatusColor(StatusToColorMapping[status]);
  }

  useEffect(() => {
    if (status) {
      setOptionsAndColor(status);
    } else {
      setStatusColor(CustomerLocationToColorMapping[customerLocation]);
    }
  }, [status, customerLocation]);

  const checkStatus = (value) => {
    let data = {
      orderStatus: value,
    };
    setStatusColor(StatusToColorMapping[value]);

    dispatch(
      statusUpdate(data, UserProfile.userId, UserProfile.accessToken, idOrder)
    );

    setTimeout(() => {
      dispatch(getActive(UserProfile.userId, UserProfile.accessToken, pageActive, 15));
      dispatch(getPrevious(UserProfile.userId, UserProfile.accessToken, pagePrevious, 15));
    }, 500);
  };
  

  if (STATUSES.includes(status)) {
    return (
      <select
        style={{ cursor: "pointer" }}
        className={statusColor}
        onChange={(event) => checkStatus(event.target.value)}
      >
        {options.map((obj) => {
          if (obj.default) {
            return (             
              <option key={obj.status} selected value={obj.status}>
                {obj.status}
              </option>
            );
          }
          return (
            <option key={obj.status} value={obj.status}>
              {obj.status}
            </option>
          );
        })}
      </select>
    );
  }

  if (customerLocation) {
    return (
      <div className={statusColor} value={customerLocation}>
        {customerLocation}
      </div>
    );
  }

  return (
    <>
      <div className={statusColor} value={status}>
        {status}
      </div>
    </>
  );
};
export default Status;
