import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import "./styles.scss";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function MultiSelect({
  changeCategories,
  checkCategories,
  state,
  categoriesItem,
  setValidCategories,
}) {
  const [changeCategoriesArray, setChangeCategoriesArray] = useState(
    checkCategories?.map((item) => item.name)
  );
  const categories = useSelector((state) => state.product.responseCategories);

  const [option, setOption] = useState(categories);
  const [personName, setPersonName] = React.useState([]);
  const [personNameCategoryModal, setPersonNameCategoryModal] = React.useState(
    changeCategoriesArray
  );
  useEffect(() => {
    setPersonNameCategoryModal(changeCategoriesArray);
  }, [state]);
  useEffect(() => {
    if (categoriesItem !== undefined) {
      if (personNameCategoryModal.length !== categoriesItem.length) {
        if (personNameCategoryModal.length === categoriesItem.length) {
          setValidCategories(false);
        } else {
          setValidCategories(true);
        }
      }
    }
  }, [personNameCategoryModal]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeCategory = (event) => {
    const {
      target: { value },
    } = event;
    setPersonNameCategoryModal(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  if (changeCategoriesArray === undefined) {
    return (
      <div style={{ maxWidth: "350px" }}>
        <FormControl
          variant="standard"
          sx={{ width: "100%", background: "white" }}
        >
          {/*<InputLabel id="demo-multiple-checkbox-label">Category</InputLabel>*/}
          <label style={{ marginRight: "500px", fontSize: 14 }}>Category</label>

          <Select
            displayEmpty
            // labelId="demo-multiple-checkbox-label"
            // id="demo-multiple-checkbox"
            multiple
            value={personName}
            onChange={(event) => handleChange(event)}
            // input={<OutlinedInput label="Tag" />}
            // renderValue={(selected) => selected.join(', ')}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <p
                    style={{
                      color: "grey",
                      margin: 0,
                      padding: 0,
                      marginRight: "500px",
                    }}
                  >
                    Category
                  </p>
                );
              }

              return selected.join(", ");
            }}
            MenuProps={MenuProps}
          >
            {option.category.map((categories) => {
              return (
                <MenuItem
                  key={categories.id}
                  onClick={(e) =>
                    changeCategories(e.currentTarget.dataset.myValue)
                  }
                  data-my-value={categories.id}
                  value={categories.name}
                >
                  <Checkbox
                    id={categories.id}
                    name="3"
                    checked={personName.indexOf(categories.name) > -1}
                  />
                  <ListItemText name="3" primary={categories.name} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: "10px" }}>
        <FormControl
          variant="standard"
          sx={{ width: "100%", background: "white" }}
        >
          <InputLabel
            id="demo-multiple-checkbox-label"
            style={{ color: "black", fontSize: "18px" }}
          >
            Category
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personNameCategoryModal}
            onChange={(event) => handleChangeCategory(event)}
            // input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {option.category.map((categories) => {
              return (
                <MenuItem
                  key={categories.id}
                  onClick={(e) =>
                    changeCategories(
                      e.currentTarget.dataset.myValue,
                      personNameCategoryModal
                    )
                  }
                  data-my-value={categories.id}
                  value={categories.name}
                >
                  <Checkbox
                    id={categories.id}
                    name="3"
                    checked={
                      personNameCategoryModal.indexOf(categories.name) >
                      -1
                    }
                  />
                  <ListItemText name="3" primary={categories.name} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }
}
