import * as types from "./types";

// export const orders = (userId, token,page,perPage) => {
//   console.log(page,perPage);
//   return (dispatch) => {
//     try {
//       http://HND-Dev-ALB-1477530394.us-east-1.elb.amazonaws.com/orders/active-orders/:userId?page=1&offset=15
//       // fetch(`${BASE_URL}orders/orders/${userId}?page=${page}&offset=${perPage}`, {
//           fetch(`${BASE_URL}orders/active-orders/${userId}?page=${page}&offset=${perPage}`,{
//         method: "GET",
//
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       })
//         .then((res) => res.json())
//         .then((data) => {
//           if (data.httpStatus >= 400) {
//             console.log(data, "error");
//           } else {
//             dispatch(getOrders(data));
//           }
//         });
//     } catch (e) {}
//   };
// };
export const getActive = (userId, token, page, perPage) => {
  return (dispatch) => {
    try {
      fetch(
        `${process.env.REACT_APP_API_URL}orders/active-orders/${userId}?page=${page}&offset=${perPage}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(getActiveOrders(data));
          }
        });
    } catch (e) {}
  };
};
export const getPrevious = (userId, token, page, perPage) => {
  return (dispatch) => {
    try {
      fetch(
        `${process.env.REACT_APP_API_URL}orders/previous-orders/${userId}?page=${page}&offset=${perPage}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(getPreviousOrders(data));
          }
        });
    } catch (e) {}
  };
};
export const statusUpdate = (statusValue, userId, token, orderId) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}orders/order-status/${userId}/${orderId}`, {
        method: "PATCH",
        body: JSON.stringify(statusValue),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            dispatch(responseStatus(data));
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(responseStatus(data));
          }
        });
    } catch (e) {}
  };
};
export const upDatePreparation = (minutes, userId, token, orderId) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}orders/order-preparing-time/${userId}/${orderId}`, {
        method: "PATCH",
        body: JSON.stringify(minutes),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
          }
        });
    } catch (e) {}
  };
};

export const getOrders = (orders) => ({
  type: types.GET_ORDERS,
  payload: orders,
});
export const getActiveOrders = (orders) => ({
  type: types.GET_ACTIVE_ORDERS,
  payload: orders,
});
export const getPreviousOrders = (orders) => ({
  type: types.GET_PREVIOUS_ORDERS,
  payload: orders,
});
export const clearOrders = (clear) => ({
  type: types.CLEAR_ORDERS,
  payload: clear,
});
export const upDateStatus = (status) => ({
  type: types.UP_DATE_STATUS_ORDER,
  payload: status,
});
export const responseStatus = (response) => ({
  type: types.RESPONSE_UP_DATE_STATUS_ORDER,
  payload: response,
});
export const changePreparation = (minute) => ({
  type: types.changePreparation,
  payload: minute,
});
