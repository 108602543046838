import * as types from "./type";
// import { postLogout } from "../auth/actions";

export const getAssociation = (token) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/associate-users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(association(data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const postNewUser = (token, data, setError) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/create-associate-user`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            dispatch(postError(data));
            setTimeout(() => {
              dispatch(postError([]));
            }, 2000);
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const sortUser = (token, status) => {
  return (dispatch) => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/sort-by-status?sortBy=${status}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            dispatch(association(data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const ban = (token, data, url) => {
  return () => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            getAssociation(token);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const deleteUser = (token, data) => {
  return () => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/delete-associate-user`, {
        method: "DELETE",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            getAssociation(token);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const updateUser = (token, data) => {
  return () => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}user/update-associate-user`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.httpStatus >= 400) {
            if (data.httpStatus === 401) {
              localStorage.clear();
              window.location.reload();
            }
          } else {
            getAssociation(token);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const association = (associationResponse) => ({
  type: types.GET_ASSOCIATES,
  payload: associationResponse,
});
export const sort = (associationResponse) => ({
  type: types.GET_ASSOCIATES,
  payload: associationResponse,
});
export const postError = (data) => ({
  type: types.ERRORS,
  payload: data,
});
export const postTabActive = (data) => ({
  type: types.ACTIVE_TAB,
  payload: data,
});
