import "./styles.scss";
import WarningIcon from "../../assets/warning.png";

const CheckQuantity = () => {
  return (
    <div className="check-quantity">
      <div className="check-quantity-header">
        <img
          src={WarningIcon}
          alt=""
          style={{
            width: "20px",
            height: "20px",
            marginTop: "20px",
            marginRight: "5px",
          }}
        />
        <p>Check the quantity</p>
      </div>
      <p className="check-quantity-text">
        Some products are less than 10. Check the quantity and add a new batch.
      </p>
    </div>
  );
};
export default CheckQuantity;
