import "./style.scss";

const ModalQuestion = ({
  visible,
  title,
  question,
  answerOne,
  answerTwo,
  backPage,
  stay,
  backLogin,
}) => {
  return (
    <div
      style={{ width: "344px" }}
      className={!visible ? "modalHide" : "modalVisible"}
    >
      <span>{title}</span>
      <span style={{ fontSize: "14px", fontWeight: "400" }}>{question}</span>
      <div style={{ marginTop: "20px" }}>
        <p
          style={{
            fontSize: "17px",
            fontFamily: "OpenSans",
            fontWeight: 700,
            cursor: "pointer",
          }}
          onClick={() => stay()}
        >
          {answerOne}
        </p>
        <p
          style={{
            fontSize: "17px",
            fontFamily: "OpenSans",
            color: "red",
            fontWeight: 700,
            cursor: "pointer",
          }}
          onClick={() => backLogin()}
        >
          {answerTwo}
        </p>
      </div>
    </div>
  );
};
export default ModalQuestion;
