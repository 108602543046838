export enum OrderStatusEnum {
    PREPARING = "Preparing",
    READY = 'Ready',
    CANCELLED = 'Cancelled',
    COMPLETED = 'Completed',
    NO_SHOW = 'No Show',
};

export enum CustomerLocationEnum {
    OUT_OF_RANGE = "Out Of Range",
    ON_THE_WAY = 'On The Way',
    STANDBY = 'Standby',
    LEFT_THE_CAFE = 'Left The Cafe'
};

export const STATUSES = Object.values(OrderStatusEnum);

const greenButton = "statusGreen";
const blueButton = "statusBlue";
const redButton = "statusRed";
const greyButton = "statusGrey";
const greenStrongButton = "greenStrong";
const yellowButton = "yellowButton";
const violetButton = "statusViolet";

export const StatusToColorMapping = {
    [OrderStatusEnum.PREPARING]: yellowButton,
    [OrderStatusEnum.READY]: greenButton,
    [OrderStatusEnum.CANCELLED]: redButton,
    [OrderStatusEnum.COMPLETED]: blueButton,
    [OrderStatusEnum.NO_SHOW]: greyButton,
  };
export const CustomerLocationToColorMapping = {
    [CustomerLocationEnum.OUT_OF_RANGE]: violetButton,
    [CustomerLocationEnum.ON_THE_WAY]: yellowButton,
    [CustomerLocationEnum.STANDBY]: greenStrongButton,
    [CustomerLocationEnum.LEFT_THE_CAFE]: blueButton,
  };