// import Tabs from "../../components/tabs";
import Table from "../../components/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActive, getPrevious } from "../../redux/orders/actions";
import { getCategories } from "../../redux/products/action";
import "./styles.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { postLogout } from "../../redux/auth/actions";
// import { logoutsUser } from "../../redux/user/actions";
import ModalDiscard from "../../components/modalWindow/modal-discard";
import IconTabCategories from "../../assets/package 2.png";
import IconTabProduct from "../../assets/ActiveOrdersIcon.png";
import React from "react";
const Orders = () => {
  const dispatch = useDispatch();
  const UserProfile = useSelector((state) => state.profile.userProfiles);

  const titleOrdersActive = [
    { title: "Order number" },
    { title: "Cost" },
    { title: "Customer Info" },
    { title: "Customer Location" },
    { title: "Preparation" },
    { title: "Placed" },
    { title: "Should be ready" },
    { title: "Order Status" },
    { title: " " },
  ];
  const titleOrdersPrevious = [
    { title: "Order number" },
    { title: "Cost" },
    { title: "Customer Info" },
    { title: "Customer Location" },
    { title: "Placed" },
    { title: "Completed at" },
    { title: "Order Status" },
  ];

  const itemNumberActive = 15;
  const [pageActive, setPageActive] = useState(1);
  const itemNumberPrevious = 15;
  const [pagePrevious, setPagePrevious] = useState(1);
  const activeOrders = useSelector((state) => state.rootOrders.activeOrders);
  const previousOrder = useSelector((state) => state.rootOrders.previousOrder);
  useEffect(() => {
    dispatch(getCategories(UserProfile.accessToken, UserProfile.userId));
  }, []);
  useEffect(() => {
    dispatch(
      getActive(
        UserProfile.userId,
        UserProfile.accessToken,
        pageActive,
        itemNumberActive
      )
    );
  }, [pageActive]);
  useEffect(() => {
    dispatch(
      getPrevious(
        UserProfile.userId,
        UserProfile.accessToken,
        pagePrevious,
        itemNumberPrevious
      )
    );
  }, [pagePrevious]);

  const tab = [
    {
      title: "Active Orders",
      content: (
        <Table
          titleTable={titleOrdersActive}
          activeOrders={activeOrders}
          titles={"Active Orders"}
          amountActive={activeOrders.activeOrdersCount}
          setPageActive={setPageActive}
          pageActive={pageActive}
          pagePrevious={pagePrevious}
          itemNumberPrevious={itemNumberPrevious}
        />
      ),
      amount: activeOrders.activeOrdersCount,
      icon: IconTabProduct,
    },
    {
      title: "Previous orders",
      content: (
        <Table
          pageActive={pageActive}
          pagePrevious={pagePrevious}
          setPagePrevious={setPagePrevious}
          previousOrder={previousOrder}
          titles={"Previous"}
          titleTable={titleOrdersPrevious}
          amountPrevious={previousOrder.previousOrdersCount}
        />
      ),
      amount: previousOrder.previousOrdersCount,
      icon: IconTabCategories,
    },
  ];

  const idUser = useSelector((state) => state.auth.user);

  const [visible, setVisible] = useState(false);
  const logOut = () => {
    setVisible(true);
  };
  const stay = () => {
    setVisible(false);
  };
  const logOutAction = () => {
    dispatch(postLogout(idUser.userId, idUser.accessToken));

    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    let interval = "";
    if (idUser.accessToken.length > 0) {
      interval = setInterval(() => {
        dispatch(
          getActive(
            UserProfile.userId,
            UserProfile.accessToken,
            pageActive,
            itemNumberActive
          )
        );
        dispatch(
          getPrevious(
            UserProfile.userId,
            UserProfile.accessToken,
            pagePrevious,
            itemNumberPrevious
          )
        );
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [pageActive]);

  return (
    <div>
      {visible ? (
        <ModalDiscard
          discard={logOutAction}
          stay={stay}
          title="Confirm the action"
          question="Are you sure you want to log out?"
          answerOne="Stay"
          answerTwo="Log out"
          styleVisible="modalVisibleLogout"
        />
      ) : (
        ""
      )}

      <Tabs>
        <div style={{ display: "flex", alignItems: "end" }}>
          <TabList className="headerProductsLink">
            {tab.map((nameTab, index) => (
              <React.Fragment key={nameTab.title}>
                <Tab
                  className="tabLinkProducts"
                  selectedClassName="tabLinkProductsOrderFocus"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "25px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "start",
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                      }}
                    >
                      {nameTab.title}
                      <label>
                        {nameTab.amount === null ? "0" : nameTab.amount}
                      </label>
                    </div>
                    <img src={nameTab.icon} alt="" />
                  </div>
                </Tab>
              </React.Fragment>
            ))}
          </TabList>
          <div style={{ marginBottom: "10px" }}>
            <input type="button" className="logOut" onClick={() => logOut()} />
          </div>
        </div>
        {tab.map((item, index) => (
          <div key={item.title + index}>
            <TabPanel className="contentOrders">{item.content}</TabPanel>
          </div>
        ))}
      </Tabs>
    </div>
  );
};

export default Orders;
